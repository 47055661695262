import { Placement, Portion } from '../types';
import { metafieldsHaveSodiumWarning } from './menuUtilities';
export const transformYumModifier = (modifier) => {
    const { blackout: outOfStock = false, images, metafields, privateMetafields } = modifier;
    // check if this modifier has a sodium warning private metafield
    const sodiumWarning = metafieldsHaveSodiumWarning(privateMetafields);
    const ccModifier = Object.assign(Object.assign({ displayOrder: 0, id: modifier.modifierCode, metafields, name: modifier.name, outOfStock, placement: Placement.WHOLE, portion: Portion.REGULAR, privateMetafields }, (sodiumWarning !== undefined && { sodiumWarning })), { type: 'MODIFIER' });
    // add images to the product
    images.forEach((image) => {
        if (image.key) {
            ccModifier[image.key] = image.url;
        }
    });
    return ccModifier;
};
export default transformYumModifier;
