import computeProductPrice from '../computeProductPrice';
export const computePizzaPrice = ({ selectedSize, selectedCrust, selectedCheese, selectedSauce, selectedToppings, }) => {
    const selectedPizzaBuilderOptions = [
        selectedSize,
        selectedCrust,
        selectedCheese,
        selectedSauce,
        ...selectedToppings,
    ];
    const selectedOptions = selectedPizzaBuilderOptions.reduce((acc, item) => {
        if (item) {
            acc.push(item);
        }
        return acc;
    }, []);
    return computeProductPrice(selectedOptions);
};
export default computePizzaPrice;
