import { createApi } from '@reduxjs/toolkit/query/react';
import axiosGraphQLQuery from '../../utils/axiosGraphQLQuery';
/**
 * Creates the base `orderApi` slice.
 *
 * See the [RTK Query docs](https://redux-toolkit.js.org/rtk-query/api/createApi) for information on `createApi`.
 */
export const menuApi = createApi({
    baseQuery: axiosGraphQLQuery(),
    endpoints: () => ({}),
    reducerPath: 'rtkq/menu',
    tagTypes: ['Menu'],
});
