import productDescriptionPhd from './productDescriptionPhd';
import transformQuantities from './transformQuantities';
const transformPhdCartModifierToCartModifier = (modifier) => {
    const { displayName, name, price, quantities, type } = modifier;
    const { placement, portion } = transformQuantities(quantities !== null && quantities !== void 0 ? quantities : [1]);
    if (!!quantities && type === 'MODIFIER') {
        return {
            id: name !== null && name !== void 0 ? name : '',
            modifiers: [],
            name: displayName !== null && displayName !== void 0 ? displayName : '',
            placement,
            portion,
            price: price || 0,
            quantities,
            type: type,
        };
    }
    return Object.assign(Object.assign({ id: name !== null && name !== void 0 ? name : '', modifiers: [], name: displayName !== null && displayName !== void 0 ? displayName : '', price: price || 0 }, (quantities !== undefined && { quantities })), { type: type });
};
const transformPhdCartItemToCartItem = (cartItem) => {
    var _a;
    const { cartItemId, displayName = '', modifiers: steps, name, price, quantity, specialInstructions, type, } = cartItem;
    // | DISCOUNT
    // --| STEP
    // ----| PRODUCT
    // ------| SIZE|GROUP
    // --------| MODIFIER
    const modifiers = (_a = steps === null || steps === void 0 ? void 0 : steps.map((step) => {
        var _a;
        const { modifiers: productModifiers } = step;
        const stepBase = transformPhdCartModifierToCartModifier(step);
        const products = (_a = productModifiers === null || productModifiers === void 0 ? void 0 : productModifiers.reduce((productAcc, product) => {
            var _a;
            const { modifiers: modifierGroups } = product;
            const groupBase = transformPhdCartModifierToCartModifier(product);
            const groups = (_a = modifierGroups === null || modifierGroups === void 0 ? void 0 : modifierGroups.reduce((groupAcc, group) => {
                var _a;
                const { modifiers: groupModifiers } = group;
                const modifierBase = transformPhdCartModifierToCartModifier(group);
                const tMods = (_a = groupModifiers === null || groupModifiers === void 0 ? void 0 : groupModifiers.reduce((modifierAcc, modifier) => {
                    var _a;
                    const { modifiers: subModifiers } = modifier;
                    const base = transformPhdCartModifierToCartModifier(modifier); // one lever deeper here?
                    const subMods = (_a = subModifiers === null || subModifiers === void 0 ? void 0 : subModifiers.reduce((subModifierAcc, subModifier) => {
                        const subModifierBase = transformPhdCartModifierToCartModifier(subModifier);
                        subModifierAcc.push(subModifierBase);
                        return subModifierAcc;
                    }, [])) !== null && _a !== void 0 ? _a : [];
                    modifierAcc.push(Object.assign(Object.assign({}, base), { modifiers: subMods }));
                    return modifierAcc;
                }, [])) !== null && _a !== void 0 ? _a : [];
                groupAcc.push(Object.assign(Object.assign({}, modifierBase), { modifiers: tMods }));
                return groupAcc;
            }, [])) !== null && _a !== void 0 ? _a : [];
            const description = productDescriptionPhd(product);
            productAcc.push(Object.assign(Object.assign({}, groupBase), { description, modifiers: groups }));
            return productAcc;
        }, [])) !== null && _a !== void 0 ? _a : [];
        return Object.assign(Object.assign({}, stepBase), { modifiers: products });
    })) !== null && _a !== void 0 ? _a : [];
    const description = productDescriptionPhd(cartItem);
    return Object.assign(Object.assign(Object.assign(Object.assign({ cartItemId: cartItemId !== null && cartItemId !== void 0 ? cartItemId : '', description, id: name, isLineup: false }, (modifiers.length > 0 && { modifiers })), { name: displayName, price: price !== null && price !== void 0 ? price : 0, quantity: quantity !== null && quantity !== void 0 ? quantity : 1 }), (specialInstructions !== undefined && { specialInstructions })), { type: type });
};
export default transformPhdCartItemToCartItem;
