var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import { menuApi } from './MenuApi';
import { menuApiContentGatewayHub } from './MenuApiContentGatewayHub';
import GET_PIZZA_BUILDER from './queries/getPizzaBuilder';
import { filterVariantsSlotsModifiersByAccessPointWeb, transformPizzaBuilder, transformYumOptionTypesToModifierArray, transformYumPizzaProductToPizzaBuilder, transformYumProductPizzasAndMelts, transformYumSlotsToModifierArray, } from '../../utils';
export const menuApiPizzaBuilder = menuApi.injectEndpoints({
    endpoints: (build) => ({
        getPizzaBuilder: build.query({
            queryFn: (options, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                const { dealRecipe, itemId, storeNumber, promotionDefinition, removeBundleVariants = true } = options;
                const { coreConfig: { isPreviewMode, isYumEcomm: useContentGateway }, } = getState();
                let response;
                if (useContentGateway) {
                    response = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate(storeNumber));
                    if (response.data) {
                        const { modifiers, optionTypes, optionValues, products, slots } = response.data;
                        // filter out slots and variants by the access-point-Web private metafield
                        const { products: productsFilteredByPrivateMetafield, slots: slotsFilteredByPrivateMetafield, modifiers: modifiersFilteredByPrivateMetafield, } = filterVariantsSlotsModifiersByAccessPointWeb(products, slots, modifiers, isPreviewMode);
                        const allOptions = transformYumOptionTypesToModifierArray(optionTypes, optionValues);
                        const allSlots = transformYumSlotsToModifierArray(slotsFilteredByPrivateMetafield, modifiersFilteredByPrivateMetafield);
                        const [pizza] = productsFilteredByPrivateMetafield.filter((product) => product.productCode === itemId);
                        if (!!dealRecipe) {
                            response = { data: transformYumPizzaProductToPizzaBuilder(dealRecipe) };
                        }
                        else if (!!pizza) {
                            const pizzaProduct = transformYumProductPizzasAndMelts(pizza, allOptions, allSlots, removeBundleVariants, {}, promotionDefinition);
                            const pizzaBuilder = transformYumPizzaProductToPizzaBuilder(pizzaProduct);
                            response = { data: pizzaBuilder };
                        }
                        else {
                            response = { error: `could not find pizza with id: ${itemId}` };
                        }
                    }
                }
                else {
                    response = yield fetchWithBQ({
                        data: {
                            operationName: 'getPizzaBuilder',
                            query: GET_PIZZA_BUILDER,
                            variables: {
                                itemID: `${itemId}`,
                                storeID: `stores/${storeNumber}`,
                            },
                        },
                        method: 'post',
                    });
                    if (response.data) {
                        const pizzaBuilder = transformPizzaBuilder(response.data);
                        response = { data: pizzaBuilder };
                    }
                }
                return (response === null || response === void 0 ? void 0 : response.data) ? { data: response.data } : { error: response.error };
            }),
        }),
    }),
});
export const { useGetPizzaBuilderQuery } = menuApiPizzaBuilder;
