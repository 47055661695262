import { gql } from 'graphql-request';
const LinkFragment = gql `
    fragment LinkFragment on LinkV3 {
        path
        type
        displayText
        title
        external
    }
`;
export default LinkFragment;
