var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { getStore } from '../ReduxStore';
const url = 'https://log-api.newrelic.com/log/v1';
const headers = {
    ['Api-Key']: '5e5e1d083b13f9a00dfbb7ddfefe9062aaeadab2',
};
/**
 * Error logging function to report network errors to New Relic on the Client Core
 *
 * @param message response returned from HTTP request error block
 */
const logError = (message) => __awaiter(void 0, void 0, void 0, function* () {
    const store = getStore();
    const { coreConfig } = store.getState();
    const { conversationId } = coreConfig;
    yield axios.post(url, {
        conversationId,
        logtype: 'Client Core Error',
        message,
    }, {
        headers,
    });
});
export default logError;
