var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import qs from 'qs';
import { Environments } from '../config';
import { axiosErrorHandler, baseHeaders } from './axiosBase';
const axiosBaseQueryYumAuth = () => ({ url, method, data, params, headers = {} }, ReduxStore) => __awaiter(void 0, void 0, void 0, function* () {
    const state = ReduxStore.getState();
    const { coreConfig: { authChannel, axiosTimeout, phdApiEnvironment, debugMode }, } = state;
    const baseUrl = Environments[phdApiEnvironment].yumAuthUrl;
    const defaultHeaders = Object.assign(Object.assign({}, baseHeaders), { ['content-type']: 'application/x-www-form-urlencoded' });
    try {
        if (debugMode) {
            console.log({
                data: qs.stringify(data),
                headers: Object.assign(Object.assign({}, defaultHeaders), headers),
                method,
                params,
                url: baseUrl + url,
            });
        }
        const result = yield axios({
            data: qs.stringify(data),
            headers: Object.assign(Object.assign({}, defaultHeaders), headers),
            method,
            params,
            timeout: axiosTimeout,
            url: baseUrl + url,
            withCredentials: authChannel === 'WEB2',
        });
        return { data: result.data };
    }
    catch (axiosError) {
        const err = axiosError;
        return axiosErrorHandler(err);
    }
});
export default axiosBaseQueryYumAuth;
