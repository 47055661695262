// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const axiosErrorHandler = (error) => {
    var _a, _b, _c, _d;
    // PHDAPI reports errors inconsistently.
    // Some endpoints report errors as { code: number; message: string }
    // Others report errors as { error_code: string; message: string }
    // We need to account for both. Client Core will report errors as
    // { errorCode: string, message: string } so we need to transform whatever
    // we get from the backend to the Client Core interface.
    let apiError;
    if ((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) {
        const phdError = error.response.data;
        const errorCode = phdError.error_code ? phdError.error_code : `${phdError.code}`;
        const message = (_c = (_b = phdError.details) === null || _b === void 0 ? void 0 : _b[0].error_description) !== null && _c !== void 0 ? _c : phdError.message;
        apiError = {
            errorCode,
            message,
        };
    }
    else {
        // If we reach this else block, some other error occurred.
        // It is unlikely the request even made it to the backend.
        apiError = error.message;
    }
    return {
        error: {
            data: apiError,
            status: (_d = error.response) === null || _d === void 0 ? void 0 : _d.status,
        },
    };
};
export const baseHeaders = {
    ['client-middleware']: 'client-core',
    ['content-type']: 'application/json',
};
