import { gql } from 'graphql-request';
import AvailabilityFragment from '../fragments/availability';
import ItemFragment from '../fragments/item';
import ModifierItemFragment from '../fragments/modifierItem';
import NutritionFragment from '../fragments/nutrition';
const GET_UPSELL = gql `
    query getUpsell($storeID: String, $environmentId: String!) {
        Homepage(envID: $environmentId, storeID: $storeID) {
            upsells {
                page {
                    item {
                        ...ItemFragment
                        allergenDisclaimer
                        hidden
                        legalText
                        modifiers {
                            ...ModifierItemFragment
                            modifiers {
                                ...ModifierItemFragment
                                modifiers {
                                    ...ModifierItemFragment
                                    nutrition {
                                        ...NutritionFragment
                                    }
                                }
                                nutrition {
                                    ...NutritionFragment
                                }
                            }
                            nutrition {
                                ...NutritionFragment
                            }
                        }
                        availability {
                            ...AvailabilityFragment
                        }
                        nutrition {
                            ...NutritionFragment
                        }
                    }
                }
            }
        }
    }
    ${AvailabilityFragment}
    ${ItemFragment}
    ${ModifierItemFragment}
    ${NutritionFragment}
`;
export default GET_UPSELL;
