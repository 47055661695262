import { transformPizzaBuilderStateToCartItem } from './transformPizzaBuilderToCartItem';
import transformWingBuilderToCartItem from './transformWingBuilderToCartItem';
export const transformDealBuilderToCartItem = (dealBuilder) => {
    const { builder, quantity, stepSelections, steps } = dealBuilder;
    const cartItem = {
        displayName: builder === null || builder === void 0 ? void 0 : builder.name,
        modifiers: [],
        name: builder === null || builder === void 0 ? void 0 : builder.id,
        quantity,
        type: builder.type || 'DISCOUNT',
        verifiedAgeGate: true,
    };
    const initialItems = [];
    const recipes = steps.reduce((acc, step, index) => {
        const item = {
            description: step.description,
            displayName: step.name,
            modifiers: [],
            name: step.id,
            type: step.type || 'STEP',
        };
        const selection = stepSelections[index];
        switch (selection.type) {
            case 'MELT':
            case 'PIZZA': {
                const pizzaItem = transformPizzaBuilderStateToCartItem(selection);
                item.modifiers = [pizzaItem.items[0]];
                acc.push(item);
                break;
            }
            case 'WINGS': {
                const wingItem = transformWingBuilderToCartItem(selection);
                item.modifiers = [wingItem];
                acc.push(item);
                break;
            }
            default: {
                const { name, selectedOptions, id, type } = selection;
                const productCartItem = {
                    displayName: name,
                    modifiers: selectedOptions.map((option) => {
                        return {
                            displayName: option.name,
                            name: option.id,
                            quantities: [1],
                            type: option.type,
                        };
                    }),
                    name: id,
                    quantity: 1,
                    type: type || 'PRODUCT',
                };
                item.modifiers = [productCartItem];
                acc.push(item);
                break;
            }
        }
        return acc;
    }, initialItems);
    cartItem.modifiers = recipes;
    return cartItem;
};
export default transformDealBuilderToCartItem;
