import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQueryV1 from '../../utils/axiosBaseQueryV1';
import axiosBaseQueryV2 from '../../utils/axiosBaseQueryV2';
/**
 * Creates the base `orderApi` slice.
 *
 * See the [RTK Query docs](https://redux-toolkit.js.org/rtk-query/api/createApi) for information on `createApi`.
 */
export const orderApi = createApi({
    baseQuery: axiosBaseQueryV2(),
    endpoints: () => ({}),
    reducerPath: 'rtkq/order',
    tagTypes: ['Cart'],
});
export const orderSessionApi = createApi({
    baseQuery: axiosBaseQueryV1(),
    endpoints: () => ({}),
    reducerPath: 'rtkq/order/session',
    tagTypes: ['Cart'],
});
