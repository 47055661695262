var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { orderApi } from './OrderApi';
import { snakeToCamel } from '../../utils';
/**
 * Defines the endpoints related to customer account. We use the
 * [injectEndpoints](https://redux-toolkit.js.org/rtk-query/usage/code-splitting) feature of RTK Query's `createApi`.
 */
export const orderApiOrder = orderApi.injectEndpoints({
    endpoints: (build) => {
        return {
            checkPhGiftCardBalance: build.mutation({
                query: (cardData) => {
                    return {
                        data: cardData,
                        method: 'post',
                        url: `/giftcard/balance`,
                    };
                },
                transformResponse: (response) => {
                    const data = snakeToCamel(response);
                    return data;
                },
            }),
            curbsideCheckin: build.query({
                query: (orderId) => ({
                    method: 'get',
                    url: `/orders/${orderId}/checkin`,
                }),
            }),
            orderStatus: build.query({
                queryFn: (payload, { getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    const { order: { orderId }, } = getState();
                    const id = payload || orderId;
                    const response = yield fetchWithBQ({
                        method: 'get',
                        url: `/orders/${id}/status`,
                    });
                    const phdOrderStatus = response.data;
                    const orderStatus = snakeToCamel(phdOrderStatus);
                    return response.data
                        ? {
                            data: orderStatus,
                        }
                        : {
                            error: response.error,
                        };
                }),
            }),
            removePayment: build.mutation({
                query: () => {
                    return {
                        method: 'post',
                        url: `/orders/payments/remove`,
                    };
                },
            }),
        };
    },
});
export const { useCheckPhGiftCardBalanceMutation, useCurbsideCheckinQuery, useOrderStatusQuery, useRemovePaymentMutation, } = orderApiOrder;
