import { gql } from 'graphql-request';
import NutritionFragment from '../fragments/nutrition';
import ProductFragment from '../fragments/product';
const productQueryTemplate = (index, itemId) => `
    item${index} : item(itemID: "${itemId}") {
        ...ProductFragment
        isCYO
        modifiers {
            displayOrder: priority
            id
            name
            nutrition {
                ...NutritionFragment
            }
            type
            modifiers {
                ...ProductFragment
                modifiers {
                    displayOrder: priority
                    id
                    name
                    type
                    ...ProductFragment
                    nutrition {
                        ...NutritionFragment
                    }
                }
                nutrition {
                    ...NutritionFragment
                }
            }
        }
        nutrition {
            ...NutritionFragment
        }
        subcategories {
            subcategoryID
            subcategoryName
        }
    }
`;
const generateProductList = (items) => {
    let productList = '';
    items.forEach(({ id }, index) => {
        productList += productQueryTemplate(index, id);
    });
    return productList;
};
export const generateProductsByIdQuery = (recipes) => {
    const productList = generateProductList(recipes);
    return gql `
        query getProductsById($storeID: String!) {
            store(storeID: $storeID) {
                menu {
                    ${productList}
                }
            }
        }
        ${NutritionFragment}
        ${ProductFragment}
    `;
};
export const generateProductsByIdListQuery = (ids) => {
    const productList = ids.reduce((acc, id, index) => {
        acc += productQueryTemplate(index, id);
        return acc;
    }, '');
    return gql `
        query getProductsById($storeID: String!) {
            store(storeID: $storeID) {
                menu {
                    ${productList}
                }
            }
        }
        ${NutritionFragment}
        ${ProductFragment}
    `;
};
export default generateProductsByIdQuery;
