import { gql } from 'graphql-request';
const AvailabilityFragment = gql `
    fragment AvailabilityFragment on Availability {
        days
        startTime
        endTime
        occasion: orderMethod
        status
    }
`;
export default AvailabilityFragment;
