import { SizeKeys } from '../types';
import transformYumOptionValues from './transformYumOptionValues';
export const transformYumOptionTypesToModifierArray = (optionTypes, optionValues) => {
    const transformedOptions = optionTypes.map((option, index) => {
        // get all of the optionValueCodes for the values in this option
        const { values } = option;
        // filter out the values for this option from all option values
        // and sort them in the same order as defined on the option
        const optionTypeValues = optionValues
            .filter((value) => values.includes(value.optionValueCode))
            .sort((a, b) => values.indexOf(a.optionValueCode) - values.indexOf(b.optionValueCode));
        // transform the Yum option values to CC modifiers and add in the optionTypeCode
        const transformedOptionValues = transformYumOptionValues(optionTypeValues);
        const modifiers = transformedOptionValues.map((modifier) => {
            // if the options represents the size of a product, we need to set the type to SIZE
            const isSize = SizeKeys.has(option.optionTypeCode);
            return Object.assign(Object.assign({}, modifier), { optionTypeCode: option.optionTypeCode, type: (isSize ? 'SIZE' : 'MODIFIER') });
        });
        // return the option as a CC modifier group
        return {
            displayOrder: index,
            id: option.optionTypeCode,
            modifiers,
            name: option.name,
            optionTypeCode: option.optionTypeCode,
            type: 'GROUP',
        };
    });
    return transformedOptions;
};
export default transformYumOptionTypesToModifierArray;
