import { gql } from 'graphql-request';
const ModifierItemNutritionFragment = gql `
    fragment ModifierItemNutritionFragment on Item {
        displayOrder: priority
        id
        name
        outOfStock
    }
`;
export default ModifierItemNutritionFragment;
