// Below are the types and interfaces for defining Yum promotions
export var CartPromotedItemType;
(function (CartPromotedItemType) {
    CartPromotedItemType["CartPromotedItemProduct"] = "CartPromotedItemProduct";
    CartPromotedItemType["CartPromotedItemProductVariant"] = "CartPromotedItemProductVariant";
    CartPromotedItemType["CartPromotedItemBundle"] = "CartPromotedItemBundle";
    CartPromotedItemType["CartPromotedItemTag"] = "CartPromotedItemTag";
})(CartPromotedItemType || (CartPromotedItemType = {}));
export var Combinability;
(function (Combinability) {
    Combinability["GLOBALLY_COMBINABLE"] = "GLOBALLY_COMBINABLE";
    Combinability["NOT_COMBINABLE"] = "NOT_COMBINABLE";
})(Combinability || (Combinability = {}));
export var DiscountDistribution;
(function (DiscountDistribution) {
    DiscountDistribution["AGGREGATE"] = "AGGREGATE";
    DiscountDistribution["DISTRIBUTE"] = "DISTRIBUTE";
})(DiscountDistribution || (DiscountDistribution = {}));
export var EqualOrLesserPriceConfig;
(function (EqualOrLesserPriceConfig) {
    EqualOrLesserPriceConfig["EQUAL_PRICED_ITEMS"] = "EQUAL_PRICED_ITEMS";
    EqualOrLesserPriceConfig["LESSER_PRICED_ITEMS"] = "LESSER_PRICED_ITEMS";
    EqualOrLesserPriceConfig["EQUAL_OR_LESSER_PRICED_ITEMS"] = "EQUAL_OR_LESSER_PRICED_ITEMS";
})(EqualOrLesserPriceConfig || (EqualOrLesserPriceConfig = {}));
export var ManagerApprovalType;
(function (ManagerApprovalType) {
    ManagerApprovalType["PIN"] = "PIN";
    ManagerApprovalType["SIGNATURE"] = "SIGNATURE";
})(ManagerApprovalType || (ManagerApprovalType = {}));
export var PromotedBundleLineItemPriceTarget;
(function (PromotedBundleLineItemPriceTarget) {
    PromotedBundleLineItemPriceTarget["PRICE"] = "PRICE";
    PromotedBundleLineItemPriceTarget["BUNDLE_PRICE"] = "BUNDLE_PRICE";
    PromotedBundleLineItemPriceTarget["BUNDLE_PRICE_WITH_CHOICE_BASE_PRICE"] = "BUNDLE_PRICE_WITH_CHOICE_BASE_PRICE";
})(PromotedBundleLineItemPriceTarget || (PromotedBundleLineItemPriceTarget = {}));
export var PromotedItemType;
(function (PromotedItemType) {
    PromotedItemType["PromotedItemBundle"] = "PromotedItemBundle";
    PromotedItemType["PromotedItemProduct"] = "PromotedItemProduct";
    PromotedItemType["PromotedItemProductVariant"] = "PromotedItemProductVariant";
    PromotedItemType["PromotedItemTag"] = "PromotedItemTag";
})(PromotedItemType || (PromotedItemType = {}));
export var PromotedProductLineItemPriceTarget;
(function (PromotedProductLineItemPriceTarget) {
    PromotedProductLineItemPriceTarget["BASE_PRICE"] = "BASE_PRICE";
    PromotedProductLineItemPriceTarget["BASE_PRICE_WITH_MODIFIERS"] = "BASE_PRICE_WITH_MODIFIERS";
})(PromotedProductLineItemPriceTarget || (PromotedProductLineItemPriceTarget = {}));
export var PromotionEffectType;
(function (PromotionEffectType) {
    PromotionEffectType["BelowLineItemPromotionEffect"] = "BelowLineItemPromotionEffect";
    PromotionEffectType["ItemLevelPromotionEffect"] = "ItemLevelPromotionEffect";
    PromotionEffectType["OrderLevelPromotionEffect"] = "OrderLevelPromotionEffect";
})(PromotionEffectType || (PromotionEffectType = {}));
export var PromotionPromoTagTarget;
(function (PromotionPromoTagTarget) {
    PromotionPromoTagTarget["PRODUCT"] = "PRODUCT";
    PromotionPromoTagTarget["PRODUCT_VARIANT"] = "PRODUCT_VARIANT";
    PromotionPromoTagTarget["BUNDLE"] = "BUNDLE";
})(PromotionPromoTagTarget || (PromotionPromoTagTarget = {}));
export var PromotionRequirementType;
(function (PromotionRequirementType) {
    PromotionRequirementType["ItemPresenceRequirement"] = "ItemPresenceRequirement";
    PromotionRequirementType["ItemExclusionRequirement"] = "ItemExclusionRequirement";
    PromotionRequirementType["SubtotalRequirement"] = "SubtotalRequirement";
    PromotionRequirementType["ItemQuantityRequirement"] = "ItemQuantityRequirement";
    PromotionRequirementType["DiningOccasionRequirement"] = "DiningOccasionRequirement";
    PromotionRequirementType["TimeFrameRequirement"] = "TimeFrameRequirement";
    PromotionRequirementType["MaxTotalUsesRequirement"] = "MaxTotalUsesRequirement";
    PromotionRequirementType["UserStatusRequirement"] = "UserStatusRequirement";
    PromotionRequirementType["MaxTotalUsesPerRedemptionCodeRequirement"] = "MaxTotalUsesPerRedemptionCodeRequirement";
    PromotionRequirementType["MaxTotalUsesPerCustomerRequirement"] = "MaxTotalUsesPerCustomerRequirement";
    PromotionRequirementType["MaxTotalUsesPerCustomerPerDayRequirement"] = "MaxTotalUsesPerCustomerPerDayRequirement";
    PromotionRequirementType["ManagerApprovalRequirement"] = "ManagerApprovalRequirement";
    PromotionRequirementType["SalesChannelRequirement"] = "SalesChannelRequirement";
})(PromotionRequirementType || (PromotionRequirementType = {}));
export var PromotionValidationTime;
(function (PromotionValidationTime) {
    PromotionValidationTime["CURRENT_TIME"] = "CURRENT_TIME";
    PromotionValidationTime["REQUESTED_TIME"] = "REQUESTED_TIME";
})(PromotionValidationTime || (PromotionValidationTime = {}));
