import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQueryV1 from '../../utils/axiosBaseQueryV1';
import axiosBaseQueryV2 from '../../utils/axiosBaseQueryV2';
/**
 * Creates the base `storeApi` slice.
 *
 * See the [RTK Query docs](https://redux-toolkit.js.org/rtk-query/api/createApi) for information on `createApi`.
 */
export const storeApi = createApi({
    baseQuery: axiosBaseQueryV2(),
    endpoints: () => ({}),
    reducerPath: 'rtkq/store',
});
export const storeLocalizationApi = createApi({
    baseQuery: axiosBaseQueryV1(),
    endpoints: () => ({}),
    reducerPath: 'rtkq/storeLoc',
});
