import { EmptyRules, OptionKeys, SlotKeys } from '../types';
export const transformYumPizzaProductToPizzaBuilder = (product) => {
    const { availability = [], description = '', glutenFree, id, name, price = 0 } = product;
    // determine if the product is a melt
    const optionIds = product.options.map((option) => option.id);
    const isMelt = optionIds.includes(OptionKeys.meltCrust) || optionIds.includes(OptionKeys.meltSize);
    // clone item from response
    const initialPizzaBuilder = {
        availability,
        cheeses: [],
        description,
        finishers: [],
        glutenFree: glutenFree !== null && glutenFree !== void 0 ? glutenFree : false,
        id,
        internalName: '',
        name,
        preferences: [],
        price,
        rules: EmptyRules,
        sauces: [],
        sizesAndCrusts: [],
        toppings: [],
        type: isMelt ? 'MELT' : 'PIZZA',
    };
    // restructure product data to match Pizza Builder contract
    const pizzaBuilder = product.options.reduce((acc, option) => {
        var _a, _b;
        const optionId = option.id.toLowerCase();
        const options = (_a = option.modifiers) !== null && _a !== void 0 ? _a : [];
        if (optionId === OptionKeys.pizzaSize || optionId === OptionKeys.meltSize) {
            acc.sizesAndCrusts = options;
        }
        else if (optionId === SlotKeys.pizzaCheese || optionId === SlotKeys.meltCheese) {
            acc.cheeses = options;
        }
        else if (optionId === SlotKeys.pizzaToppings) {
            (_b = acc.toppings) === null || _b === void 0 ? void 0 : _b.push(option);
        }
        else if (optionId === SlotKeys.pizzaSauce || optionId === SlotKeys.meltSauce) {
            acc.sauces = options;
        }
        else if (optionId === SlotKeys.pizzaPreferences) {
            acc.preferences = options;
        }
        else if (optionId === SlotKeys.pizzaSeasoning) {
            acc.finishers = options;
        }
        return acc;
    }, initialPizzaBuilder);
    return pizzaBuilder;
};
export default transformYumPizzaProductToPizzaBuilder;
