import { removeNulls } from './filterUtilities';
import { sortByDisplayOrder } from './sortUtilities';
import { parseGqlId } from './stringUtilities';
const transformGqlDealItem = (deals) => {
    const initialDeals = [];
    const items = deals.reduce((acc, item) => {
        const noNullItem = removeNulls(item);
        const deal = Object.assign(Object.assign({}, noNullItem), { displayOrder: noNullItem.displayOrder || 0, internalName: noNullItem.internalName || '', isBundle: true, isPromotion: false, options: noNullItem.modifiers || [], qoId: parseGqlId(noNullItem.id), selectedOptions: [], steps: [] });
        delete deal.modifiers;
        acc.push(deal);
        return acc.sort(sortByDisplayOrder);
    }, initialDeals);
    return items;
};
export default transformGqlDealItem;
