import { gql } from 'graphql-request';
import ItemFragment from './item';
import ModifierItemFragment from './modifierItem';
const CategoryItemFragment = gql `
    fragment CategoryItemFragment on Item {
        ...ItemFragment
        modifiers {
            displayOrder: priority
            id
            internalName
            name
            type
            modifiers {
                ...ModifierItemFragment
                modifiers {
                    ...ModifierItemFragment
                }
            }
        }
    }
    ${ItemFragment}
    ${ModifierItemFragment}
`;
export default CategoryItemFragment;
