var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OrderActions } from '../../slices/OrderSlice';
import { storeApi } from './StoreApi';
import { snakeToCamel, transformPhdCartToCart } from '../../utils';
const { setCart, setCartId, setStore } = OrderActions;
const noStoreSelected = { error: 'User has not localized to a store' };
const noExistingCart = { error: 'User has no existing cart' };
const noMatchingDeliveryStore = { error: 'Can not change to delivery with this store' };
const noDeliveryStore = { error: 'No delivery store found' };
const noAddressIncluded = { error: 'Address for delivery is missing or incomplete' };
export const storeApiOccasion = storeApi.injectEndpoints({
    endpoints: (build) => {
        return {
            changeOccasionWithCart: build.mutation({
                queryFn: (newOccasion, queryApi, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    var _a;
                    let token = '';
                    const { dispatch, getState } = queryApi;
                    const { order: { cartId, store }, } = getState();
                    if (!store) {
                        return noStoreSelected;
                    }
                    const { storeId: initialStore } = store;
                    if (!cartId) {
                        return noExistingCart;
                    }
                    if (newOccasion.occasionId === 'DELIVERY') {
                        if (!(newOccasion === null || newOccasion === void 0 ? void 0 : newOccasion.address1) ||
                            !(newOccasion === null || newOccasion === void 0 ? void 0 : newOccasion.city) ||
                            !(newOccasion === null || newOccasion === void 0 ? void 0 : newOccasion.state) ||
                            !(newOccasion === null || newOccasion === void 0 ? void 0 : newOccasion.postalCode)) {
                            return noAddressIncluded;
                        }
                        const deliveryStoreSearchResponse = yield fetchWithBQ({
                            data: {
                                address1: newOccasion === null || newOccasion === void 0 ? void 0 : newOccasion.address1,
                                city: newOccasion === null || newOccasion === void 0 ? void 0 : newOccasion.city,
                                occasion_id: newOccasion === null || newOccasion === void 0 ? void 0 : newOccasion.occasionId,
                                postal_code: newOccasion === null || newOccasion === void 0 ? void 0 : newOccasion.postalCode,
                                state: newOccasion === null || newOccasion === void 0 ? void 0 : newOccasion.state,
                            },
                            method: 'post',
                            url: '/stores/search',
                        });
                        const deliveryStoreSearchError = deliveryStoreSearchResponse.error;
                        if (deliveryStoreSearchError && ((_a = deliveryStoreSearchError.data) === null || _a === void 0 ? void 0 : _a.errorCode) === '0703') {
                            return noDeliveryStore;
                        }
                        if (deliveryStoreSearchError && deliveryStoreSearchError.data.errorCode !== '0703') {
                            return { error: deliveryStoreSearchResponse };
                        }
                        const [typedDeliveryStoreSearchResponse] = deliveryStoreSearchResponse.data;
                        if (typedDeliveryStoreSearchResponse.store_id !== initialStore) {
                            return noMatchingDeliveryStore;
                        }
                        dispatch(setStore(snakeToCamel(typedDeliveryStoreSearchResponse)));
                        token = typedDeliveryStoreSearchResponse.token;
                    }
                    else {
                        const { customer: { searchAddress }, } = getState();
                        const storeSearchResponse = yield fetchWithBQ({
                            data: {
                                address1: searchAddress === null || searchAddress === void 0 ? void 0 : searchAddress.address1,
                                city: searchAddress === null || searchAddress === void 0 ? void 0 : searchAddress.city,
                                occasion_id: newOccasion.occasionId,
                                postal_code: searchAddress === null || searchAddress === void 0 ? void 0 : searchAddress.postalCode,
                                state: searchAddress === null || searchAddress === void 0 ? void 0 : searchAddress.state,
                            },
                            method: 'post',
                            url: '/stores/search',
                        });
                        const typedStoreSearchResponse = storeSearchResponse.data;
                        const [matchingStore] = typedStoreSearchResponse.filter(({ store_id }) => store_id === initialStore);
                        dispatch(setStore(snakeToCamel(matchingStore)));
                        token = matchingStore.token;
                    }
                    const getCartResponse = yield fetchWithBQ({
                        method: 'get',
                        url: `/carts/${cartId}`,
                    });
                    const { items } = getCartResponse.data;
                    const createCartResponse = yield fetchWithBQ({
                        data: {
                            items,
                        },
                        headers: {
                            Localization: token,
                        },
                        method: 'post',
                        url: '/carts',
                    });
                    const typedCreateCartResponse = createCartResponse.data;
                    const cart = transformPhdCartToCart(typedCreateCartResponse);
                    dispatch(setCartId(cart.cartId));
                    dispatch(setCart(cart));
                    return {
                        data: cart,
                    };
                }),
            }),
        };
    },
});
export const { useChangeOccasionWithCartMutation } = storeApiOccasion;
