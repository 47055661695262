import { Placement, Portion } from '../types';
const portions = {
    [Portion.EXTRA]: 2,
    [Portion.REGULAR]: 1,
};
const transformPlacementPortionToQuantities = (placement, portion) => {
    const placements = {
        [Placement.LEFT]: [portions[portion], 0],
        [Placement.RIGHT]: [0, portions[portion]],
        [Placement.WHOLE]: [portions[portion], portions[portion]],
    };
    const quantities = placements[placement];
    return quantities;
};
export default transformPlacementPortionToQuantities;
