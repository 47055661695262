export const computeBuilderQuantity = (oldItems = [], newItems = []) => {
    // check the old quantities of each line item with the new line items, and if one of them is different
    // the we need to re-write the deal history with the new updated quantity.
    const oldQuantities = new Map();
    const newQuantities = new Map();
    oldItems.forEach((item) => { var _a; return oldQuantities.set(item.cartItemId, (_a = item.quantity) !== null && _a !== void 0 ? _a : 1); });
    newItems.forEach((item) => { var _a; return newQuantities.set(item.cartItemId, (_a = item.quantity) !== null && _a !== void 0 ? _a : 1); });
    let cartItemId = '';
    let quantity = 1;
    for (const [key, value] of newQuantities) {
        if (oldQuantities.get(key) !== value) {
            cartItemId = key;
            quantity = value;
        }
    }
    return { cartItemId, quantity };
};
export default computeBuilderQuantity;
