import { DipProductCodes, OptionKeys, PromotedItemType, PromotionAction, PromotionEffectType, PromotionRequirementType, } from '../types';
export const isDip = (productCode) => {
    return DipProductCodes.has(productCode);
};
export const isMelt = (optionIds) => {
    return optionIds.includes(OptionKeys.meltCrust) || optionIds.includes(OptionKeys.meltSize);
};
export const isPizza = (optionIds) => {
    return optionIds.includes(OptionKeys.pizzaCrust) || optionIds.includes(OptionKeys.pizzaSize);
};
export const isWings = (optionIds) => {
    return optionIds.includes(OptionKeys.wingFlavor) || optionIds.includes(OptionKeys.wingSize);
};
export const metafieldsHaveSodiumWarning = (metafields) => {
    const sodiumMetafield = metafields.find(({ key }) => key === 'sodiumWarning');
    if (!!sodiumMetafield) {
        const sodiumWarning = (sodiumMetafield === null || sodiumMetafield === void 0 ? void 0 : sodiumMetafield.value) === 'Y' ? true : false;
        return sodiumWarning;
    }
    return undefined;
};
export const productType = (optionIds) => {
    const melt = isMelt(optionIds);
    const pizza = isPizza(optionIds);
    const wings = isWings(optionIds);
    const type = melt ? 'MELT' : pizza ? 'PIZZA' : wings ? 'WINGS' : 'PRODUCT';
    return type;
};
export const includeBundleForPromotionDefinition = (bundleCode, promoInput) => {
    switch (promoInput.action) {
        case PromotionAction.EFFECTS: {
            return (promoInput.definition.effects
                .filter((e) => e.type === PromotionEffectType.ItemLevelPromotionEffect)
                .filter((e) => e.items.some((item) => item.type === PromotedItemType.PromotedItemBundle && item.bundleCode === bundleCode)).length > 0);
        }
        case PromotionAction.REQUIREMENTS: {
            return (promoInput.definition.requirements
                .filter((r) => r.type === PromotionRequirementType.ItemPresenceRequirement)
                .filter((r) => r.items.some((item) => item.type === PromotedItemType.PromotedItemBundle && item.bundleCode === bundleCode)).length > 0);
        }
    }
};
export const includeProductForPromotionDefinition = (productCode, promoInput) => {
    switch (promoInput.action) {
        case PromotionAction.EFFECTS: {
            return (promoInput.definition.effects
                .filter((e) => e.type === PromotionEffectType.ItemLevelPromotionEffect)
                .filter((e) => e.items.some((item) => {
                switch (item.type) {
                    case PromotedItemType.PromotedItemProduct:
                    case PromotedItemType.PromotedItemProductVariant:
                        return item.productCode === productCode;
                    default:
                        return false;
                }
            })).length > 0);
        }
        case PromotionAction.REQUIREMENTS: {
            return (promoInput.definition.requirements
                .filter((r) => r.type === PromotionRequirementType.ItemPresenceRequirement)
                .filter((r) => r.items.some((item) => {
                switch (item.type) {
                    case PromotedItemType.PromotedItemProduct:
                    case PromotedItemType.PromotedItemProductVariant:
                        return item.productCode === productCode;
                    default:
                        return false;
                }
            })).length > 0);
        }
    }
};
export const includeVariantForPromotedItemProduct = (code, promoInput, variant) => {
    let effectsOrRequirements = [];
    switch (promoInput.action) {
        case PromotionAction.EFFECTS: {
            effectsOrRequirements = promoInput.definition.effects
                .filter((e) => e.type === PromotionEffectType.ItemLevelPromotionEffect)
                .filter((e) => e.items.some((item) => item.type === PromotedItemType.PromotedItemProduct))
                .map((e) => {
                const effect = e;
                const { excludedOptionFilters, items, optionFilters, type } = effect;
                return { excludedOptionFilters, items, optionFilters, type };
            });
            break;
        }
        case PromotionAction.REQUIREMENTS: {
            effectsOrRequirements = promoInput.definition.requirements
                .filter((r) => r.type === PromotionRequirementType.ItemPresenceRequirement)
                .filter((r) => r.items.some((item) => item.type === PromotedItemType.PromotedItemProduct))
                .map((r) => {
                const requirement = r;
                const { excludedOptionFilters, items, optionFilters, type } = requirement;
                return { excludedOptionFilters, items, optionFilters, type };
            });
            break;
        }
    }
    const includeMap = new Map();
    const excludeMap = new Map();
    effectsOrRequirements
        .filter((e) => {
        switch (e.type) {
            case PromotionEffectType.ItemLevelPromotionEffect:
                const { items: effectItems } = e;
                const effectProductCodes = effectItems.map((item) => {
                    const promotedProduct = item;
                    return promotedProduct.productCode;
                });
                return effectProductCodes.includes(code);
            case PromotionRequirementType.ItemPresenceRequirement:
                const { items: requirementItems } = e;
                const requirementProductCodes = requirementItems.map((item) => {
                    const promotedProduct = item;
                    return promotedProduct.productCode;
                });
                return requirementProductCodes.includes(code);
            default:
                return false;
        }
    })
        .map((e) => {
        const effect = e;
        const { excludedOptionFilters, optionFilters } = effect;
        return { excludedOptionFilters, optionFilters };
    })
        .forEach((item) => {
        const { excludedOptionFilters, optionFilters } = item;
        if (excludedOptionFilters) {
            excludedOptionFilters.forEach((e) => {
                var _a;
                const exclusions = (_a = excludeMap.get(e.optionTypeCode)) !== null && _a !== void 0 ? _a : new Set();
                e.optionValueCodes.forEach((c) => exclusions.add(c));
                excludeMap.set(e.optionTypeCode, exclusions);
            });
        }
        if (optionFilters) {
            optionFilters.forEach((i) => {
                var _a;
                const inclusions = (_a = includeMap.get(i.optionTypeCode)) !== null && _a !== void 0 ? _a : new Set();
                i.optionValueCodes.forEach((c) => inclusions.add(c));
                includeMap.set(i.optionTypeCode, inclusions);
            });
        }
    });
    const { selectedOptionValues } = variant;
    const includeMapSize = includeMap.size;
    const excludeMapSize = excludeMap.size;
    const includeSaysYes = includeMapSize > 1
        ? selectedOptionValues.every(({ optionTypeCode, optionValueCode }) => {
            var _a;
            return (_a = includeMap.get(optionTypeCode)) === null || _a === void 0 ? void 0 : _a.has(optionValueCode);
        })
        : selectedOptionValues.some(({ optionTypeCode, optionValueCode }) => {
            var _a;
            return (_a = includeMap.get(optionTypeCode)) === null || _a === void 0 ? void 0 : _a.has(optionValueCode);
        });
    const excludeSaysNo = selectedOptionValues.some(({ optionTypeCode, optionValueCode }) => {
        var _a;
        return (_a = excludeMap.get(optionTypeCode)) === null || _a === void 0 ? void 0 : _a.has(optionValueCode);
    });
    if (includeMapSize === 0 && excludeMapSize === 0) {
        return true;
    }
    else if (includeMapSize === 0 && !excludeSaysNo) {
        return true;
    }
    return excludeSaysNo ? false : includeSaysYes;
};
export const includeVariantForPromotedItemProductVariant = (productCode, promoInput, variantCode) => {
    let effectsOrRequirements = [];
    switch (promoInput.action) {
        case PromotionAction.EFFECTS: {
            effectsOrRequirements = promoInput.definition.effects
                .filter((e) => e.type === PromotionEffectType.ItemLevelPromotionEffect)
                .filter((e) => e.items.some((item) => item.type === PromotedItemType.PromotedItemProductVariant))
                .filter((e) => e.items.some((item) => item.productCode === productCode && item.productVariantCode === variantCode))
                .map((e) => {
                const effect = e;
                const { excludedOptionFilters, items, optionFilters, type } = effect;
                return { excludedOptionFilters, items, optionFilters, type };
            });
            break;
        }
        case PromotionAction.REQUIREMENTS: {
            effectsOrRequirements = promoInput.definition.requirements
                .filter((r) => r.type === PromotionRequirementType.ItemPresenceRequirement)
                .filter((r) => r.items.some((item) => item.type === PromotedItemType.PromotedItemProductVariant))
                .filter((r) => r.items.some((item) => item.productCode === productCode && item.productVariantCode === variantCode))
                .map((r) => {
                const requirement = r;
                const { excludedOptionFilters, items, optionFilters, type } = requirement;
                return { excludedOptionFilters, items, optionFilters, type };
            });
            break;
        }
    }
    return effectsOrRequirements.length > 0;
};
export const promotedItemHasVariantCodes = (promoInput) => {
    if (promoInput.action === PromotionAction.REQUIREMENTS) {
        const requirementsHaveVariantCodes = promoInput.definition.requirements
            .filter((requirement) => {
            return requirement.type === PromotionRequirementType.ItemPresenceRequirement;
        })
            .flatMap((requirement) => {
            return requirement.items;
        })
            .some((item) => item.type === PromotedItemType.PromotedItemProductVariant);
        return requirementsHaveVariantCodes;
    }
    else if (promoInput.action === PromotionAction.EFFECTS) {
        const effectsHaveVariantCodes = promoInput.definition.effects
            .filter((effect) => {
            return effect.type === PromotionEffectType.ItemLevelPromotionEffect;
        })
            .flatMap((effect) => {
            return effect.items;
        })
            .some((item) => item.type === PromotedItemType.PromotedItemProductVariant);
        return effectsHaveVariantCodes;
    }
    else {
        return false;
    }
};
export const includeVariantForPromotionDefinition = (productCode, promoInput, variant) => {
    const includeForProduct = includeVariantForPromotedItemProduct(productCode, promoInput, variant);
    const hasVariantCode = includeVariantForPromotedItemProductVariant(productCode, promoInput, variant.variantCode);
    const promotionHasVariantCodes = promotedItemHasVariantCodes(promoInput);
    return promotionHasVariantCodes ? hasVariantCode : includeForProduct;
};
