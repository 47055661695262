import { gql } from 'graphql-request';
import AvailabilityFragment from '../fragments/availability';
import PizzaItemFragment from '../fragments/pizzaItem';
import RulesFragment from '../fragments/rules';
const GET_PIZZA_BUILDER = gql `
    query getPizzaBuilder($storeID: String!, $itemID: ID!) {
        menu: store(storeID: $storeID) {
            pizza: menu {
                item(itemID: $itemID) {
                    type
                    ...PizzaItemFragment
                    availability {
                        ...AvailabilityFragment
                    }
                    isCYO
                    modifiers {
                        ...PizzaItemFragment
                        modifiers {
                            ...PizzaItemFragment
                            modifiers {
                                ...PizzaItemFragment
                                ...RulesFragment
                            }
                        }
                    }
                }
            }
        }
    }
    ${AvailabilityFragment}
    ${PizzaItemFragment}
    ${RulesFragment}
`;
export default GET_PIZZA_BUILDER;
