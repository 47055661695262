import { removeNulls } from './filterUtilities';
import { sortByDisplayOrder } from './sortUtilities';
const transformCategories = (categories) => {
    // clone deep to preserve nested values
    const newCategories = JSON.parse(JSON.stringify(categories));
    const reducedProducts = newCategories
        .reduce((acc, category) => {
        var _a, _b;
        category.products = (_a = category.products) === null || _a === void 0 ? void 0 : _a.filter((product) => product.item.exists);
        const productsLength = ((_b = category.products) === null || _b === void 0 ? void 0 : _b.length) || 0;
        if (productsLength > 0) {
            delete category.products;
            acc = [...acc, category];
        }
        return acc;
    }, [])
        .sort(sortByDisplayOrder);
    return removeNulls(reducedProducts);
};
export default transformCategories;
