var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { orderApiSession } from './OrderApiSession';
import { paymentApi } from './PaymentApi';
import { camelToSnake, snakeToCamel } from '../../utils';
import { OrderActions } from '../../slices/OrderSlice';
import { transformOrderResponse, transformPlaceOrderPayloadToSubmitCustomerOrderInput, } from '../../utils/transformPlaceOrderPayloadToSubmitCustomerOrderInput';
const { clearCart, setOrderId } = OrderActions;
export const paymentApiTokenization = paymentApi.injectEndpoints({
    endpoints: (build) => {
        return {
            addPayment: build.mutation({
                queryFn: (payment, _queryApi, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    let data = camelToSnake(payment);
                    switch (payment.type) {
                        case 'creditcard': {
                            // if the payment type is creditcard, we need to tokenize the payment first
                            const { paymentInfo } = payment;
                            // tokenize the credit card information
                            const { data: tokenData, error } = yield fetchWithBQ({
                                data: paymentInfo,
                                method: 'post',
                                url: `/tokenize`,
                            });
                            // if we got an error while tokenining, return
                            if (error) {
                                return { error };
                            }
                            // set token in add payment request body
                            const { token } = tokenData;
                            data = Object.assign(Object.assign({}, data), { metadata: Object.assign(Object.assign({}, data.metadata), { gateway: 'VERIFONE', token: token !== null && token !== void 0 ? token : '' }) });
                            break;
                        }
                        case 'giftcard': {
                            // if the payment type is giftcard, we do not need to do anything
                            break;
                        }
                    }
                    // submit the payment
                    const { data: paymentData, error } = yield fetchWithBQ({
                        data,
                        method: 'post',
                        url: `/customer/payments/tokenized`,
                    });
                    if (error) {
                        return { error };
                    }
                    const paymentResponse = paymentData;
                    const response = snakeToCamel(paymentResponse);
                    return { data: response };
                }),
            }),
            checkGiftCardBalance: build.mutation({
                query: (cardData) => {
                    return {
                        data: cardData,
                        method: 'post',
                        url: `/giftcard/balance/bhn`,
                    };
                },
            }),
            editPayment: build.mutation({
                query: (payment) => {
                    const data = camelToSnake(payment);
                    const paymentId = payment.paymentId;
                    return {
                        data,
                        method: 'put',
                        url: `/customer/payments/tokenized/${paymentId}`,
                    };
                },
                transformResponse: (response) => {
                    const data = snakeToCamel(response);
                    return data;
                },
            }),
            // name changed from placeOrder 9/21/22 - see axiosBaseQueryV1 for details
            placeCustomerOrder: build.mutation({
                onQueryStarted: (_, { queryFulfilled, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setOrderId(data === null || data === void 0 ? void 0 : data.orderId));
                        dispatch(clearCart());
                    }
                    catch (err) {
                        console.log(err);
                    }
                }),
                queryFn: (order, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    const { coreConfig: { isYumEcomm, xOriginatingAppId }, order: { cart }, } = getState();
                    if (!cart) {
                        const error = new Error('cart does not exist in redux');
                        return { error };
                    }
                    const { deliveryAddress, recipient, tip = 0 } = cart;
                    const { email, firstName, lastName, phoneNumber: phone } = Object.assign({}, recipient);
                    const customer = {
                        coppaAgreement: true,
                        deliveryAddress,
                        email,
                        firstName,
                        lastName,
                        phone,
                        phoneExtension: '',
                        verifiedAgeGate: true,
                    };
                    const dataPhd = camelToSnake(Object.assign(Object.assign({}, order), { customer, gratuity: tip }));
                    const data = isYumEcomm ? transformPlaceOrderPayloadToSubmitCustomerOrderInput(order) : dataPhd;
                    const url = isYumEcomm ? `/orders` : `/orders/tokenized`;
                    const originatingAppId = xOriginatingAppId && { 'x-originating-app-id': xOriginatingAppId };
                    const { data: orderData, error } = yield fetchWithBQ(Object.assign(Object.assign({ data, isYum: isYumEcomm }, (isYumEcomm && originatingAppId && { headers: originatingAppId })), { method: 'post', url }));
                    if (error) {
                        return { error };
                    }
                    // clear the session if we are not on V3
                    if (!isYumEcomm) {
                        yield dispatch(orderApiSession.endpoints.clearSession.initiate());
                    }
                    const orderResponse = orderData;
                    const transformResponse = isYumEcomm
                        ? transformOrderResponse(orderResponse, order.emailOptin)
                        : snakeToCamel(orderResponse);
                    return { data: transformResponse };
                }),
            }),
            tokenizePayment: build.mutation({
                query: (tokenBody) => {
                    const data = camelToSnake(tokenBody);
                    return {
                        data,
                        method: 'post',
                        url: `/tokenize`,
                    };
                },
                transformResponse: (response) => {
                    const data = snakeToCamel(response);
                    return data;
                },
            }),
        };
    },
});
export const { useAddPaymentMutation, useCheckGiftCardBalanceMutation, useEditPaymentMutation, usePlaceCustomerOrderMutation, useTokenizePaymentMutation, } = paymentApiTokenization;
