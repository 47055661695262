import { homepageApi } from './HomepageApi';
import GET_FEATURED_PRODUCTS from './queries/getFeaturedProducts';
import transformFeaturedProducts from '../../utils/transformFeaturedProducts';
import { OccasionV3 } from '../../types/menu/consolidatedGqlTypes';
export const homepageApiFeaturedProducts = homepageApi.injectEndpoints({
    endpoints: (build) => ({
        getFeaturedProducts: build.query({
            query: (featuredProductsOptions) => ({
                data: {
                    operationname: 'getFeaturedProducts',
                    query: GET_FEATURED_PRODUCTS,
                    variables: {
                        environmentId: featuredProductsOptions.environmentId || 'prod',
                        occasions: featuredProductsOptions.occasions || OccasionV3.UnknownOccasion,
                        storeID: featuredProductsOptions.storeNumber || '002',
                        time: featuredProductsOptions.time || new Date().getTime().toString(),
                    },
                },
                method: 'post',
            }),
            transformResponse: (response) => {
                const data = transformFeaturedProducts(response);
                return data;
            },
        }),
    }),
});
export const { useGetFeaturedProductsQuery } = homepageApiFeaturedProducts;
