import { Placement } from '../types';
import { parseGqlId } from './stringUtilities';
export const isHalfTopping = (placement) => {
    return placement === Placement.LEFT || placement === Placement.RIGHT;
};
export const cheeseSauceCalories = (modifier, crustId, sizeId) => {
    var _a;
    // Get the nutrition for the crustId and sizeId
    const nutrition = (_a = modifier === null || modifier === void 0 ? void 0 : modifier.nutrition) === null || _a === void 0 ? void 0 : _a.find((nut) => { var _a, _b; return ((_a = nut.qualifiers) === null || _a === void 0 ? void 0 : _a.includes(crustId)) && ((_b = nut.qualifiers) === null || _b === void 0 ? void 0 : _b.includes(sizeId)) && nut.portion === modifier.portion; });
    return Number(nutrition === null || nutrition === void 0 ? void 0 : nutrition.calories) || 0;
};
export const toppingCalories = (toppings, crustId, sizeId) => {
    // Map over the toppings
    // 1. Check if the topping is only on half of the pizza
    // 2. Get the nutrition for the given size, crust and protion (EXTRA or LIGHT)
    // 3. Compute the calories dividing it in half if is a half topping
    // 4. Sum up all of the topping calories
    const totalCalories = toppings
        .map((topping) => {
        var _a;
        const placement = topping.placement || Placement.WHOLE;
        const divisor = isHalfTopping(placement) ? 2 : 1;
        const nutrition = (_a = topping.nutrition) === null || _a === void 0 ? void 0 : _a.find((nut) => {
            var _a, _b;
            return nut.portion === topping.portion &&
                ((_a = nut.qualifiers) === null || _a === void 0 ? void 0 : _a.includes(crustId)) &&
                ((_b = nut.qualifiers) === null || _b === void 0 ? void 0 : _b.includes(sizeId));
        });
        return Math.ceil((Number(nutrition === null || nutrition === void 0 ? void 0 : nutrition.calories) || 0) / divisor);
    })
        .reduce((total, calories) => total + calories, 0);
    return totalCalories;
};
export const computeNutrition = (size, crust, cheese, sauce, toppings) => {
    var _a;
    // Get the size and crust IDs
    const sizeId = parseGqlId(size === null || size === void 0 ? void 0 : size.id);
    const crustId = parseGqlId(crust === null || crust === void 0 ? void 0 : crust.id);
    // Get the base nutrition which is on the crust
    const crustNutrition = (_a = crust === null || crust === void 0 ? void 0 : crust.nutrition) === null || _a === void 0 ? void 0 : _a[0];
    // Get the calorie totals for each pizza component
    const crustCalories = Number(crustNutrition === null || crustNutrition === void 0 ? void 0 : crustNutrition.calories) || 0;
    const cheeseCalories = cheeseSauceCalories(cheese, crustId, sizeId);
    const sauceCalories = cheeseSauceCalories(sauce, crustId, sizeId);
    const toppingsCalories = toppingCalories(toppings, crustId, sizeId);
    // Sum up all of the calorie components to get the total
    const totalCalories = crustCalories + cheeseCalories + sauceCalories + toppingsCalories;
    // Build the nutrition string
    const unit = (crustNutrition === null || crustNutrition === void 0 ? void 0 : crustNutrition.unit) || 'slice';
    return !!crustNutrition
        ? `${totalCalories} cal/${unit}, ${crustNutrition.servings} ${unit}s`.toLocaleLowerCase()
        : '';
};
export default computeNutrition;
