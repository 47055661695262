import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { CoreConfigActions, fetchCoreConfig } from './slices/CoreConfigSlice';
import ReduxStore from './ReduxStore';
const { setAuthChannel, setDebugMode, setEnableCoreConfig, setIsYumEcomm, setProductionEnv, setProxyProductionEnv, setProxyStagingEnv, setSalesChannel, setStagingEnv, } = CoreConfigActions;
const envs = {
    production: setProductionEnv(),
    proxyProduction: setProxyProductionEnv(),
    proxyStaging: setProxyStagingEnv(),
    staging: setStagingEnv(),
};
const CoreProvider = ({ authChannel, children, debugMode, enableCoreConfig = true, env, isYumEcomm = false, salesChannel, }) => {
    const { dispatch } = ReduxStore;
    useEffect(() => {
        if (ReduxStore) {
            dispatch(envs[env]);
            dispatch(setAuthChannel(authChannel));
            dispatch(setSalesChannel(salesChannel));
            dispatch(setEnableCoreConfig(enableCoreConfig));
            dispatch(setIsYumEcomm(isYumEcomm));
            if (enableCoreConfig) {
                dispatch(fetchCoreConfig(env));
            }
            if (debugMode) {
                dispatch(setDebugMode(debugMode));
            }
        }
    }, []);
    return React.createElement(Provider, { store: ReduxStore }, children);
};
export default CoreProvider;
