import computeNutrition from './computeNutrition';
import extractModifierIds from './extractModifierIds';
const transformCartItemToPizzaBuilder = (cartItem, pizzaBuilderData) => {
    var _a;
    const { modifiers = [] } = cartItem;
    // Get an object for all of the pizza builder modifiers indexed by id
    const modifiersById = extractModifierIds(pizzaBuilderData);
    const modifierNames = {};
    modifiers.forEach((modifier) => {
        var _a;
        const name = modifier.type === 'SIZE' ? 'sizesAndCrusts' : modifier.name.toLowerCase();
        modifierNames[name] = [];
        const { id: modifierId } = modifier;
        if (modifiersById[modifierId]) {
            modifierNames[name].push(modifiersById[modifierId]);
        }
        (_a = modifier.modifiers) === null || _a === void 0 ? void 0 : _a.forEach((modifierTwo) => {
            var _a;
            const id = modifierTwo.id;
            const matchIds = Object.keys(modifiersById).filter((storedId) => {
                return storedId === id;
            });
            if (!matchIds.length) {
                (_a = modifierTwo.modifiers) === null || _a === void 0 ? void 0 : _a.forEach((modifierThree) => {
                    const subModifierId = modifierThree.id;
                    modifierNames[name].push(modifiersById[subModifierId]);
                });
            }
            matchIds.forEach((matchedId) => {
                modifierNames[name].push(modifiersById[matchedId]);
            });
        });
    });
    const { cheeses: selectedCheeses, meats = [], sauces: selectedSauces, sizesAndCrusts: selectedSizesAndCrusts, veggies = [], } = modifierNames;
    const [selectedCheese] = selectedCheeses.filter((cheese) => !cheese.outOfStock);
    const [selectedSauce] = selectedSauces.filter((sauce) => !sauce.outOfStock);
    const selectedCrust = selectedSizesAndCrusts.filter((crust) => !crust.outOfStock).find((size) => size.type === 'MODIFIER') || null;
    const selectedSize = selectedSizesAndCrusts.filter((size) => !size.outOfStock).find((size) => size.type === 'SIZE') || null;
    const filteredOutOfStockMeats = meats.filter((meat) => !meat.outOfStock);
    const filteredOutOfStockVeggies = veggies.filter((veggie) => !veggie.outOfStock);
    const selectedToppings = [...filteredOutOfStockMeats, ...filteredOutOfStockVeggies];
    const { cheeses, glutenFree, price, sizesAndCrusts, sauces, toppings } = pizzaBuilderData;
    const { quantity = 1, specialInstructions } = cartItem;
    return {
        builder: pizzaBuilderData,
        cheeses,
        glutenFree: glutenFree,
        maxToppingsAllowed: (_a = selectedCrust === null || selectedCrust === void 0 ? void 0 : selectedCrust.maxAllowed) !== null && _a !== void 0 ? _a : null,
        nutrition: computeNutrition(selectedSize, selectedCrust, selectedCheese, selectedSauce, selectedToppings),
        price,
        quantity,
        sauces,
        selectedCheese: selectedCheese || null,
        selectedCrust,
        selectedSauce: selectedSauce || null,
        selectedSize,
        selectedToppings,
        sizesAndCrusts,
        specialInstructions: specialInstructions || '',
        toppings,
        type: 'PIZZA',
    };
};
export default transformCartItemToPizzaBuilder;
