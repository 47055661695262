var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { orderApi } from './OrderApi';
import { CartWarningType, CurrencyCode, PromotionNotAppliedReasonType, } from '../../types';
import { OrderActions } from '../../slices/OrderSlice';
import { camelToSnake, snakeToCamel, transformCartItemInputToPhdCartItem, transformCartItemInputToYumCartLineItemInput, transformYumCartToCart, transformPhdCartToCart, transformCartItemUpdateToYumCartLineItemUpdate, transformCartItemUpdateToPhdCartItem, } from '../../utils';
import { addFavoriteToCart, addHistoricalOrderToCart, createCart, localizeToStore } from '../../utils/orderQueries';
import { menuApiContentGatewayHub } from '../menu/MenuApiContentGatewayHub';
const { removeDealFromDealHistory, removePizzaFromPizzaHistory, removeWingFromWingHistory, setCart, setCartId, setPromotions, } = OrderActions;
export const orderApiCart = orderApi.injectEndpoints({
    endpoints: (build) => {
        return {
            addBuilderItemToCart: build.mutation({
                invalidatesTags: ['Cart'],
                onQueryStarted: (_, { dispatch, queryFulfilled }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setCart(data));
                    }
                    catch (error) {
                        console.log(error);
                    }
                }),
                queryFn: (payload, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    var _a, _b, _c;
                    const { coreConfig: { isYumEcomm }, order: { cartId = '', store }, } = getState();
                    const yumPayload = payload.isDeal ? payload.cartItem.item : { products: [payload.cartItem.item] };
                    const data = isYumEcomm ? yumPayload : camelToSnake(payload.cartItem);
                    const id = (_a = payload.cartId) !== null && _a !== void 0 ? _a : cartId;
                    const url = isYumEcomm ? `/carts/items` : `/carts/${id}/items`;
                    const { data: cartData, error } = yield fetchWithBQ({
                        data,
                        headers: isYumEcomm ? {} : { Localization: (_b = store === null || store === void 0 ? void 0 : store.token) !== null && _b !== void 0 ? _b : '' },
                        isYum: isYumEcomm,
                        method: 'post',
                        url,
                    });
                    if (error) {
                        return { error };
                    }
                    let cart;
                    if (isYumEcomm) {
                        const menuResponse = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate((_c = store === null || store === void 0 ? void 0 : store.storeId) !== null && _c !== void 0 ? _c : ''));
                        if (menuResponse.error || !menuResponse.data) {
                            return { error };
                        }
                        const menu = menuResponse.data;
                        cart = transformYumCartToCart(cartData, menu);
                    }
                    else {
                        cart = transformPhdCartToCart(cartData);
                    }
                    return { data: cart };
                }),
            }),
            addCartItem: build.mutation({
                invalidatesTags: ['Cart'],
                onQueryStarted: (_, { queryFulfilled, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setCart(data));
                    }
                    catch (err) {
                        console.log(err);
                    }
                }),
                queryFn: (payload, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    var _d, _e;
                    const { coreConfig: { isYumEcomm }, order: { cartId: reduxCartId, promotionDefinitions, store }, } = getState();
                    const { cartId: payloadCartId, item } = payload;
                    const cartId = (_d = payloadCartId !== null && payloadCartId !== void 0 ? payloadCartId : reduxCartId) !== null && _d !== void 0 ? _d : '';
                    const data = isYumEcomm
                        ? transformCartItemInputToYumCartLineItemInput(item)
                        : { item: transformCartItemInputToPhdCartItem(item) };
                    const localizationToken = (store === null || store === void 0 ? void 0 : store.token) || '';
                    const v3url = `/carts/items`;
                    const url = isYumEcomm ? v3url : `/carts/${cartId}/items`;
                    const { data: cartData, error } = yield fetchWithBQ({
                        data,
                        headers: {
                            Localization: localizationToken,
                        },
                        isYum: isYumEcomm,
                        method: 'post',
                        url,
                    });
                    if (error) {
                        return { error };
                    }
                    let cart;
                    if (isYumEcomm) {
                        const menuResponse = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate((_e = store === null || store === void 0 ? void 0 : store.storeId) !== null && _e !== void 0 ? _e : ''));
                        if (menuResponse.error || !menuResponse.data) {
                            return { error };
                        }
                        const menu = menuResponse.data;
                        cart = transformYumCartToCart(cartData, menu, promotionDefinitions);
                    }
                    else {
                        cart = transformPhdCartToCart(cartData);
                    }
                    return { data: cart };
                }),
            }),
            addFavoriteOrder: build.mutation({
                invalidatesTags: ['Cart'],
                queryFn: (favorite, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    // get all list of favorites
                    const getFavoriteData = yield fetchWithBQ({
                        method: 'get',
                        url: `/favorites`,
                    });
                    const favoriteResponse = getFavoriteData.data;
                    const allFavorites = snakeToCamel(favoriteResponse);
                    if (!allFavorites.count) {
                        return { error: 'No favorites found.' };
                    }
                    // get data from redux
                    const { order: { cart, cartId, store }, } = getState();
                    const cartOccasionId = cart === null || cart === void 0 ? void 0 : cart.occasionId;
                    const localizedStoreNumber = store === null || store === void 0 ? void 0 : store.storeNumber;
                    // get the selected favorite from the list of all favorites
                    const selectedFavorite = allFavorites.favorites.find((fav) => fav.favoriteId === favorite.favoriteId);
                    if (!selectedFavorite) {
                        return { error: 'Favorite not found.' };
                    }
                    const favoriteStoreNumber = selectedFavorite === null || selectedFavorite === void 0 ? void 0 : selectedFavorite.storeNumber;
                    const favoriteOccasion = (selectedFavorite === null || selectedFavorite === void 0 ? void 0 : selectedFavorite.occasionId) || '';
                    // get the favorite store associated with the selected favorite
                    const favoriteStore = allFavorites.stores.find((s) => s.storeNumber === favoriteStoreNumber);
                    if (!favoriteStore) {
                        return { error: 'No store found for this favorite.' };
                    }
                    // build payload object to send for new localization
                    const locizationPayload = {
                        address1: favoriteStore === null || favoriteStore === void 0 ? void 0 : favoriteStore.address1,
                        city: favoriteStore === null || favoriteStore === void 0 ? void 0 : favoriteStore.city,
                        geocodeValidate: true,
                        limit: 1,
                        occasionId: favoriteOccasion,
                        postalCode: favoriteStore === null || favoriteStore === void 0 ? void 0 : favoriteStore.postalCode,
                        state: favoriteStore === null || favoriteStore === void 0 ? void 0 : favoriteStore.state,
                    };
                    // if no cart, localize to favorite store, create cart and add favorite
                    if (!cartId) {
                        const localizedStore = yield localizeToStore(fetchWithBQ, locizationPayload);
                        const newCart = yield createCart(fetchWithBQ, localizedStore, dispatch);
                        const { data, error } = yield addFavoriteToCart(fetchWithBQ, favorite, newCart.cartId);
                        if (error) {
                            return { error };
                        }
                        const cartData = transformPhdCartToCart(data);
                        return {
                            data: cartData,
                        };
                    }
                    else {
                        const id = favoriteStoreNumber !== localizedStoreNumber || favoriteOccasion !== cartOccasionId
                            ? cartId
                            : favorite.cartId;
                        const { data, error } = yield addFavoriteToCart(fetchWithBQ, favorite, id);
                        if (error) {
                            return { error };
                        }
                        const cartData = transformPhdCartToCart(data);
                        return {
                            data: cartData,
                        };
                    }
                }),
            }),
            addHistoricalOrder: build.mutation({
                invalidatesTags: ['Cart'],
                queryFn: (historicalOrder, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    // get all list of orders
                    const getOrderData = yield fetchWithBQ({
                        method: 'get',
                        url: `/orders`,
                    });
                    const favoriteResponse = getOrderData.data;
                    const allOrders = snakeToCamel(favoriteResponse);
                    if (!allOrders.count) {
                        return { error: 'No previous orders found.' };
                    }
                    // get data from redux
                    const { order: { cart, cartId, store }, } = getState();
                    const cartOccasionId = cart === null || cart === void 0 ? void 0 : cart.occasionId;
                    const localizedStoreNumber = store === null || store === void 0 ? void 0 : store.storeNumber;
                    // get the selected order from the list of all favorites
                    const selectedOrder = allOrders.orders.find((order) => order.orderId === historicalOrder.orderId);
                    if (!selectedOrder) {
                        return { error: 'Order not found.' };
                    }
                    const orderStoreNumber = selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.storeNumber;
                    const orderOccasion = (selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.occasionId) || '';
                    // get the order store associated with the selected order
                    const orderStore = allOrders.stores.find((s) => s.storeNumber === orderStoreNumber);
                    if (!orderStore) {
                        return { error: 'Store not found for this order.' };
                    }
                    // // build payload object to send for new localization
                    const locizationPayload = {
                        address1: orderStore === null || orderStore === void 0 ? void 0 : orderStore.address1,
                        city: orderStore === null || orderStore === void 0 ? void 0 : orderStore.city,
                        geocodeValidate: true,
                        limit: 1,
                        occasionId: orderOccasion,
                        postalCode: orderStore === null || orderStore === void 0 ? void 0 : orderStore.postalCode,
                        state: orderStore === null || orderStore === void 0 ? void 0 : orderStore.state,
                    };
                    // if no cart, localize to order store, create cart and add order
                    if (!cartId) {
                        const localizedStore = yield localizeToStore(fetchWithBQ, locizationPayload);
                        const newCart = yield createCart(fetchWithBQ, localizedStore, dispatch);
                        const { data, error } = yield addHistoricalOrderToCart(fetchWithBQ, historicalOrder, newCart.cartId);
                        if (error) {
                            return { error };
                        }
                        const cartData = transformPhdCartToCart(data);
                        return {
                            data: cartData,
                        };
                    }
                    else {
                        const id = orderStoreNumber !== localizedStoreNumber || orderOccasion !== cartOccasionId
                            ? cartId
                            : historicalOrder.cartId;
                        const { data, error } = yield addHistoricalOrderToCart(fetchWithBQ, historicalOrder, id);
                        if (error) {
                            return { error };
                        }
                        const cartData = transformPhdCartToCart(data);
                        return {
                            data: cartData,
                        };
                    }
                }),
            }),
            applyLoyaltyOffer: build.mutation({
                invalidatesTags: ['Cart'],
                onQueryStarted: (_, { queryFulfilled, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setCart(data));
                    }
                    catch (err) {
                        console.log(err);
                    }
                }),
                queryFn: (payload, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    var _f;
                    const { coreConfig: { isYumEcomm }, order: { cart: reduxCart, store }, } = getState();
                    if (!isYumEcomm) {
                        if (!reduxCart) {
                            return { error: 'No cart found' };
                        }
                        const cart = Object.assign({}, reduxCart);
                        return { data: cart };
                    }
                    const { data, error } = yield fetchWithBQ({
                        data: payload,
                        headers: {},
                        isYum: true,
                        method: 'post',
                        url: '/carts/loyalty',
                    });
                    if (error) {
                        return { error };
                    }
                    const menuResponse = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate((_f = store === null || store === void 0 ? void 0 : store.storeId) !== null && _f !== void 0 ? _f : ''));
                    if (menuResponse.error || !menuResponse.data) {
                        return { error };
                    }
                    const cartResponse = data;
                    const { cart: { warnings }, } = cartResponse;
                    const warning = warnings === null || warnings === void 0 ? void 0 : warnings.find((w) => {
                        switch (w.__typename) {
                            case CartWarningType.LoyaltyOfferNotApplied:
                                const { loyaltyOffer } = w;
                                return payload.loyaltyOfferId === loyaltyOffer.loyaltyOfferId;
                            default:
                                return false;
                        }
                    });
                    if (warning) {
                        const promotionWarining = warning;
                        yield dispatch(orderApiCart.endpoints.getPromotion.initiate(promotionWarining.promotionId));
                    }
                    const { order: { promotionDefinitions }, } = getState();
                    const menu = menuResponse.data;
                    const cart = transformYumCartToCart(cartResponse, menu, promotionDefinitions);
                    return { data: cart };
                }),
            }),
            applyPromoCode: build.mutation({
                onQueryStarted: (_, { queryFulfilled, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setCart(data));
                    }
                    catch (err) {
                        console.log(err);
                    }
                }),
                queryFn: (promoCode, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    var _g;
                    const { coreConfig: { isYumEcomm }, order: { cart, store }, } = getState();
                    const url = `/carts/promocode/${promoCode}`;
                    let data;
                    if (isYumEcomm) {
                        const { data: response, error } = yield fetchWithBQ({
                            isYum: isYumEcomm,
                            method: 'post',
                            url,
                        });
                        if (error) {
                            return { error };
                        }
                        const menuResponse = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate((_g = store === null || store === void 0 ? void 0 : store.storeId) !== null && _g !== void 0 ? _g : ''));
                        if (menuResponse.error || !menuResponse.data) {
                            return { error };
                        }
                        const cartResponse = response;
                        const { cart: { warnings }, } = cartResponse;
                        const warning = warnings === null || warnings === void 0 ? void 0 : warnings.find((w) => {
                            switch (w.__typename) {
                                case CartWarningType.PromotionNotApplied:
                                    const { reasons } = w;
                                    const typeNames = reasons.map((r) => r.__typename);
                                    const isNotPromotionCodeNotFound = !typeNames.includes(PromotionNotAppliedReasonType.RedemptionCodeNotFound);
                                    return w.code === promoCode && isNotPromotionCodeNotFound;
                                default:
                                    return false;
                            }
                        });
                        if (warning) {
                            const promotionWarining = warning;
                            yield dispatch(orderApiCart.endpoints.getPromotion.initiate(promotionWarining.promotionId));
                        }
                        const { order: { promotionDefinitions }, } = getState();
                        const menu = menuResponse.data;
                        const yumCart = transformYumCartToCart(cartResponse, menu, promotionDefinitions);
                        data = Object.assign({}, yumCart);
                    }
                    else {
                        data = cart;
                    }
                    return { data };
                }),
            }),
            createCustomerCart: build.mutation({
                onQueryStarted: (_, { queryFulfilled, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setCartId(data.cartId));
                        dispatch(setCart(data));
                    }
                    catch (err) {
                        console.log(err);
                    }
                }),
                queryFn: (payload, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    var _h, _j;
                    const { coreConfig: { isYumEcomm }, order: { store }, } = getState();
                    const localizationToken = (store === null || store === void 0 ? void 0 : store.token) || '';
                    let data;
                    if (isYumEcomm) {
                        data = payload;
                    }
                    else {
                        const cartItemInputs = payload;
                        const items = cartItemInputs.map((input) => transformCartItemInputToPhdCartItem(input));
                        data = { items };
                    }
                    const { data: cartData, error } = yield fetchWithBQ({
                        data,
                        headers: isYumEcomm ? {} : { Localization: localizationToken },
                        isYum: isYumEcomm,
                        method: 'post',
                        url: '/carts',
                    });
                    if (error) {
                        return { error };
                    }
                    let cart;
                    if (isYumEcomm) {
                        const menuResponse = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate((_h = store === null || store === void 0 ? void 0 : store.storeId) !== null && _h !== void 0 ? _h : ''));
                        if (menuResponse.error || !menuResponse.data) {
                            return { error };
                        }
                        const menu = menuResponse.data;
                        cart = transformYumCartToCart(cartData, menu);
                    }
                    else {
                        cart = transformPhdCartToCart(cartData);
                        // If we are on v2 and the response does not have the items array, add in an empty array
                        cart = Object.assign(Object.assign({}, cart), { items: (_j = cart.items) !== null && _j !== void 0 ? _j : [] });
                    }
                    return { data: cart };
                }),
            }),
            deleteCartItem: build.mutation({
                invalidatesTags: ['Cart'],
                onQueryStarted: (payload, { queryFulfilled, dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
                    const { order: { pizzaHistory, dealHistory, wingHistory }, } = getState();
                    const isPizza = Object.keys(pizzaHistory).includes(payload.cartItemId);
                    const isDeal = Object.keys(dealHistory).includes(payload.cartItemId);
                    const isWing = Object.keys(wingHistory).includes(payload.cartItemId);
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setCart(data));
                        if (isPizza) {
                            dispatch(removePizzaFromPizzaHistory(payload.cartItemId));
                        }
                        else if (isDeal) {
                            dispatch(removeDealFromDealHistory(payload.cartItemId));
                        }
                        else if (isWing) {
                            dispatch(removeWingFromWingHistory(payload.cartItemId));
                        }
                    }
                    catch (err) {
                        console.log(err);
                    }
                }),
                queryFn: (payload, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    var _k, _l, _m;
                    const { coreConfig: { isYumEcomm }, order: { cartId: reduxCartId, store }, } = getState();
                    const { cartId: payloadCartId, cartItemId } = payload;
                    const cartId = payloadCartId !== null && payloadCartId !== void 0 ? payloadCartId : reduxCartId;
                    if (!isYumEcomm && !cartId) {
                        return { error: 'no cart ID received' };
                    }
                    const v3Path = '/carts/items';
                    const v2Path = `/carts/${cartId}/items/${cartItemId}`;
                    const url = isYumEcomm ? v3Path : v2Path;
                    const { data, error } = yield fetchWithBQ({
                        headers: isYumEcomm ? {} : { Localization: (_k = store === null || store === void 0 ? void 0 : store.token) !== null && _k !== void 0 ? _k : '' },
                        isYum: isYumEcomm,
                        method: 'delete',
                        params: { ids: cartItemId },
                        url,
                    });
                    if (error) {
                        return { error };
                    }
                    let cart;
                    if (isYumEcomm) {
                        const menuResponse = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate((_l = store === null || store === void 0 ? void 0 : store.storeId) !== null && _l !== void 0 ? _l : ''));
                        if (menuResponse.error || !menuResponse.data) {
                            return { error };
                        }
                        const { order: { promotionDefinitions }, } = getState();
                        const menu = menuResponse.data;
                        cart = transformYumCartToCart(data, menu, promotionDefinitions);
                    }
                    else {
                        cart = transformPhdCartToCart(data);
                        // If we are on v2 and the response does not have the items array, add in an empty array
                        cart = Object.assign(Object.assign({}, cart), { items: (_m = cart.items) !== null && _m !== void 0 ? _m : [] });
                    }
                    return { data: cart };
                }),
            }),
            getCart: build.query({
                onQueryStarted: (_, { queryFulfilled, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setCart(data));
                    }
                    catch (err) {
                        console.log(err);
                    }
                }),
                providesTags: ['Cart'],
                queryFn: (cartId, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    var _o, _p;
                    const { coreConfig: { isYumEcomm }, order: { cartId: reduxCartId, promotionDefinitions: currectPromos = [], store }, } = getState();
                    const url = isYumEcomm ? '/carts' : cartId ? `/carts/${cartId}` : `/carts/${reduxCartId}`;
                    const { data, error } = yield fetchWithBQ({
                        isYum: isYumEcomm,
                        method: 'get',
                        url,
                    });
                    if (error) {
                        return { error };
                    }
                    let cart;
                    if (isYumEcomm) {
                        const menuResponse = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate((_o = store === null || store === void 0 ? void 0 : store.storeId) !== null && _o !== void 0 ? _o : ''));
                        if (menuResponse.error || !menuResponse.data) {
                            return { error };
                        }
                        // We need to look at the warnings on the cart and see if we have any LoyaltyOfferNotApplied
                        // or PromotionNotApplied and get the associated promotion definition
                        const { cart: yumCart } = data;
                        const { warnings = [] } = yumCart;
                        const promotionIdsToBeFetched = new Set();
                        warnings.forEach((warning) => {
                            switch (warning.__typename) {
                                case CartWarningType.LoyaltyOfferNotApplied:
                                    promotionIdsToBeFetched.add(warning.promotionId);
                                    break;
                                case CartWarningType.PromotionNotApplied:
                                    if (!warning.reasons.some((reason) => reason.__typename ===
                                        PromotionNotAppliedReasonType.RedemptionCodeNotFound)) {
                                        promotionIdsToBeFetched.add(warning.promotionId);
                                    }
                                    break;
                                default:
                                    break;
                            }
                        });
                        // now request the promotion definition if it is not aready in the current promotionDefinitions
                        const promotionIdsAlreadyFetched = new Set(currectPromos === null || currectPromos === void 0 ? void 0 : currectPromos.map((promo) => promo.promotionId));
                        const definitionsToFetch = Array.from(promotionIdsToBeFetched);
                        for (let i = 0; i < definitionsToFetch.length; i++) {
                            const promotionId = definitionsToFetch[i];
                            if (!promotionIdsAlreadyFetched.has(promotionId)) {
                                yield dispatch(orderApiCart.endpoints.getPromotion.initiate(promotionId));
                            }
                        }
                        // get the promotionDefinitions from redux so they can be added to the cart
                        const { order: { promotionDefinitions }, } = getState();
                        const menu = menuResponse.data;
                        cart = transformYumCartToCart(data, menu, promotionDefinitions);
                    }
                    else {
                        cart = transformPhdCartToCart(data);
                        // If we are on v2 and the response does not have the items array, add in an empty array
                        cart = Object.assign(Object.assign({}, cart), { items: (_p = cart.items) !== null && _p !== void 0 ? _p : [] });
                    }
                    return { data: cart };
                }),
            }),
            getPromotion: build.query({
                onQueryStarted: (_, { queryFulfilled, dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const { order: { promotionDefinitions = [] }, } = getState();
                        const { data } = yield queryFulfilled;
                        const definitions = promotionDefinitions.filter((p) => p.promotionId !== data.promotionId);
                        dispatch(setPromotions([...definitions, data]));
                    }
                    catch (err) {
                        console.log(err);
                    }
                }),
                queryFn: (promotionId, _api, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    const { data, error } = yield fetchWithBQ({
                        isYum: true,
                        method: 'get',
                        url: `/promotions/${promotionId}`,
                    });
                    if (error) {
                        return { error };
                    }
                    return { data: data };
                }),
            }),
            priceCart: build.mutation({
                queryFn: (payload, { getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    var _q;
                    const { order: { cartId: reduxCartId }, } = getState();
                    const isCartPayload = 'items' in payload;
                    const cartId = (_q = payload.cartId) !== null && _q !== void 0 ? _q : reduxCartId;
                    let data;
                    if (isCartPayload) {
                        data = camelToSnake(payload);
                    }
                    else {
                        const items = payload.inputs.map((item) => transformCartItemInputToPhdCartItem(item));
                        data = { items };
                    }
                    const { data: priceData, error } = yield fetchWithBQ({
                        data,
                        method: 'post',
                        url: `/carts/${cartId}/price`,
                    });
                    if (error) {
                        return { error };
                    }
                    const price = priceData;
                    return {
                        data: price,
                    };
                }),
            }),
            reassignCustomerCart: build.mutation({
                queryFn: (payload, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    var _r, _s;
                    const { order: { store }, } = getState();
                    const localizationToken = (_r = store === null || store === void 0 ? void 0 : store.token) !== null && _r !== void 0 ? _r : '';
                    const cart = yield fetchWithBQ({
                        data: payload,
                        headers: {
                            Localization: localizationToken,
                        },
                        isYum: true,
                        method: 'put',
                        url: '/carts/reassign',
                    });
                    if (cart.error) {
                        return { error: cart.error };
                    }
                    const menuResponse = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate((_s = store === null || store === void 0 ? void 0 : store.storeId) !== null && _s !== void 0 ? _s : ''));
                    if (!menuResponse.data) {
                        return { error: { message: 'Failed to fetch store menu' } };
                    }
                    const menu = menuResponse.data;
                    const ccCart = transformYumCartToCart(cart.data, menu);
                    dispatch(OrderActions.setCartId(ccCart.cartId || ''));
                    return { data: snakeToCamel(ccCart) };
                }),
            }),
            removePromoCode: build.mutation({
                onQueryStarted: (_, { queryFulfilled, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setCart(data));
                    }
                    catch (err) {
                        console.log(err);
                    }
                }),
                queryFn: (promoCode, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    var _t, _u;
                    const { coreConfig: { isYumEcomm }, order: { cart, store }, } = getState();
                    const url = `/carts/promocode/${promoCode}`;
                    let data;
                    if (isYumEcomm) {
                        const { data: response, error } = yield fetchWithBQ({
                            isYum: isYumEcomm,
                            method: 'delete',
                            url,
                        });
                        if (error) {
                            return { error };
                        }
                        const menuResponse = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate((_t = store === null || store === void 0 ? void 0 : store.storeId) !== null && _t !== void 0 ? _t : ''));
                        if (menuResponse.error || !menuResponse.data) {
                            return { error };
                        }
                        const { order: { promotionDefinitions }, } = getState();
                        const promotions = (_u = promotionDefinitions === null || promotionDefinitions === void 0 ? void 0 : promotionDefinitions.filter((p) => {
                            const codes = p.privateMetafields
                                .filter((m) => m.key === 'coupon-code')
                                .map((m) => m.value);
                            return !codes.includes(promoCode);
                        })) !== null && _u !== void 0 ? _u : [];
                        if (promotions.length > 0) {
                            dispatch(setPromotions(promotions));
                        }
                        const menu = menuResponse.data;
                        data = transformYumCartToCart(response, menu, promotions);
                    }
                    else {
                        data = cart;
                    }
                    return { data };
                }),
            }),
            resetCustomerCart: build.mutation({
                invalidatesTags: ['Cart'],
                onQueryStarted: (_, { queryFulfilled, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setCart(data));
                    }
                    catch (err) {
                        console.log(err);
                    }
                }),
                queryFn: (_payload, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    var _v, _w;
                    const { coreConfig: { isYumEcomm }, order: { cartId = '', store }, } = getState();
                    const { data, error } = yield fetchWithBQ({
                        data: isYumEcomm ? undefined : { items: [] },
                        headers: isYumEcomm ? {} : { Localization: (_v = store === null || store === void 0 ? void 0 : store.token) !== null && _v !== void 0 ? _v : '' },
                        isYum: isYumEcomm,
                        method: isYumEcomm ? 'post' : 'put',
                        url: isYumEcomm ? '/carts/clear' : `/carts/${cartId}`,
                    });
                    if (error) {
                        return { error };
                    }
                    let cart;
                    if (isYumEcomm) {
                        const menuResponse = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate((_w = store === null || store === void 0 ? void 0 : store.storeId) !== null && _w !== void 0 ? _w : ''));
                        if (menuResponse.error || !menuResponse.data) {
                            return { error };
                        }
                        const menu = menuResponse.data;
                        cart = transformYumCartToCart(data, menu);
                    }
                    else {
                        cart = transformPhdCartToCart(data);
                    }
                    return { data: cart };
                }),
            }),
            updateCart: build.mutation({
                invalidatesTags: ['Cart'],
                onQueryStarted: (_, { queryFulfilled, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setCart(data));
                    }
                    catch (err) {
                        console.log(err);
                    }
                }),
                queryFn: (payload, { getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    var _x, _y;
                    const { order: { cartId: reduxCartId, store }, } = getState();
                    const cartId = (_x = payload.cartId) !== null && _x !== void 0 ? _x : reduxCartId;
                    const localizationToken = (store === null || store === void 0 ? void 0 : store.token) || '';
                    const items = payload.inputs.map((item) => transformCartItemInputToPhdCartItem(item));
                    const data = { items };
                    const { data: cartData, error } = yield fetchWithBQ({
                        data,
                        headers: {
                            Localization: localizationToken,
                        },
                        method: 'put',
                        url: `/carts/${cartId}`,
                    });
                    if (error) {
                        return { error };
                    }
                    let cart = transformPhdCartToCart(cartData);
                    // if the response does not have the items array, add in an empty array
                    cart = Object.assign(Object.assign({}, cart), { items: (_y = cart.items) !== null && _y !== void 0 ? _y : [] });
                    return { data: cart };
                }),
            }),
            updateCartItem: build.mutation({
                invalidatesTags: ['Cart'],
                onQueryStarted: (_, { queryFulfilled, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setCart(data));
                    }
                    catch (err) {
                        console.log(err);
                    }
                }),
                queryFn: (payload, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    var _z, _0, _1, _2, _3, _4, _5;
                    const { coreConfig: { isYumEcomm }, order: { cartId: reduxCartId, promotionDefinitions, store }, } = getState();
                    const isCartItemPayload = 'cartItem' in payload;
                    const isCartLineItemUpdate = 'raiseOnConflict' in payload;
                    const localizationToken = (store === null || store === void 0 ? void 0 : store.token) || '';
                    let cartId = '';
                    let cartItemId = '';
                    let data;
                    if (isCartItemPayload) {
                        cartId = (_0 = (_z = payload.cartId) !== null && _z !== void 0 ? _z : reduxCartId) !== null && _0 !== void 0 ? _0 : '';
                        data = camelToSnake(payload.cartItem);
                        cartItemId = (_1 = payload.itemId) !== null && _1 !== void 0 ? _1 : '';
                    }
                    else if (isCartLineItemUpdate) {
                        data = payload;
                    }
                    else {
                        const { item } = payload;
                        data = isYumEcomm
                            ? transformCartItemUpdateToYumCartLineItemUpdate(item)
                            : { item: transformCartItemUpdateToPhdCartItem(item) };
                        cartItemId = (_2 = item.cartItemId) !== null && _2 !== void 0 ? _2 : '';
                        cartId = (_4 = (_3 = payload.cartId) !== null && _3 !== void 0 ? _3 : reduxCartId) !== null && _4 !== void 0 ? _4 : '';
                    }
                    const url = isYumEcomm ? '/carts/items' : `/carts/${cartId}/items/${cartItemId}`;
                    const { data: cartData, error } = yield fetchWithBQ({
                        data,
                        headers: isYumEcomm ? {} : { Localization: localizationToken },
                        isYum: isYumEcomm,
                        method: 'put',
                        url,
                    });
                    if (error) {
                        return { error };
                    }
                    let cart;
                    if (isYumEcomm) {
                        const menuResponse = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate((_5 = store === null || store === void 0 ? void 0 : store.storeId) !== null && _5 !== void 0 ? _5 : ''));
                        if (menuResponse.error || !menuResponse.data) {
                            return { error };
                        }
                        const menu = menuResponse.data;
                        cart = transformYumCartToCart(cartData, menu, promotionDefinitions);
                    }
                    else {
                        cart = transformPhdCartToCart(cartData);
                    }
                    return { data: cart };
                }),
            }),
            updateCartRecipient: build.mutation({
                invalidatesTags: ['Cart'],
                onQueryStarted: (_, { queryFulfilled, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setCart(data));
                    }
                    catch (err) {
                        console.log(err);
                    }
                }),
                queryFn: (payload, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    var _6;
                    const { coreConfig: { isYumEcomm }, order: { cart: reduxCart, promotionDefinitions, store }, } = getState();
                    if (!isYumEcomm) {
                        if (reduxCart) {
                            const cart = Object.assign(Object.assign({}, reduxCart), { recipient: payload });
                            return { data: cart };
                        }
                        else {
                            return { error: 'No cart found.' };
                        }
                    }
                    const { data, error } = yield fetchWithBQ({
                        data: payload,
                        isYum: true,
                        method: 'put',
                        url: '/carts/recipient',
                    });
                    if (error) {
                        return { error };
                    }
                    let cart;
                    // it looks like it already broke out of this function if it isn't yum ecom.
                    if (isYumEcomm) {
                        const menuResponse = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate((_6 = store === null || store === void 0 ? void 0 : store.storeId) !== null && _6 !== void 0 ? _6 : ''));
                        if (menuResponse.error || !menuResponse.data) {
                            return { error };
                        }
                        const menu = menuResponse.data;
                        cart = transformYumCartToCart(data, menu, promotionDefinitions);
                    }
                    else {
                        cart = reduxCart;
                    }
                    return { data: cart };
                }),
            }),
            updateTip: build.mutation({
                onQueryStarted: (_, { queryFulfilled, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setCart(data));
                    }
                    catch (err) {
                        console.log(err);
                    }
                }),
                queryFn: (payload, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    var _7;
                    const { coreConfig: { isYumEcomm }, order: { cart: reduxCart, promotionDefinitions, store }, } = getState();
                    if (!isYumEcomm) {
                        if (!reduxCart) {
                            return { error: 'No cart found' };
                        }
                        const cart = Object.assign(Object.assign({}, reduxCart), { tip: payload });
                        return { data: cart };
                    }
                    const tipAmount = { amount: payload, currencyCode: CurrencyCode.USD };
                    const { data, error } = yield fetchWithBQ({
                        data: tipAmount,
                        headers: {},
                        isYum: true,
                        method: 'put',
                        url: '/carts/tip',
                    });
                    if (error) {
                        return { error };
                    }
                    const menuResponse = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate((_7 = store === null || store === void 0 ? void 0 : store.storeId) !== null && _7 !== void 0 ? _7 : ''));
                    if (menuResponse.error || !menuResponse.data) {
                        return { error };
                    }
                    const menu = menuResponse.data;
                    const cart = transformYumCartToCart(data, menu, promotionDefinitions);
                    return { data: cart };
                }),
            }),
        };
    },
});
export const { useAddCartItemMutation, useAddFavoriteOrderMutation, useAddHistoricalOrderMutation, useApplyLoyaltyOfferMutation, useApplyPromoCodeMutation, useCreateCustomerCartMutation, useDeleteCartItemMutation, useGetCartQuery, useGetPromotionQuery, usePriceCartMutation, useReassignCustomerCartMutation, useRemovePromoCodeMutation, useResetCustomerCartMutation, useUpdateCartMutation, useUpdateCartItemMutation, useUpdateCartRecipientMutation, useUpdateTipMutation, } = orderApiCart;
