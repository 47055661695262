import { decodeHTML } from 'entities';
const decodeStringValues = (obj) => {
    return Object.assign(Object.assign(Object.assign(Object.assign({}, obj), (typeof (obj === null || obj === void 0 ? void 0 : obj.name) === 'string' && { name: decodeHTML(obj.name) })), (typeof (obj === null || obj === void 0 ? void 0 : obj.description) === 'string' && { description: decodeHTML(obj.description) })), (typeof (obj === null || obj === void 0 ? void 0 : obj.displayName) === 'string' && { displayName: decodeHTML(obj.displayName) }));
};
const parseArray = (array, htmlToStringCallback) => array.map((val) => {
    if (Array.isArray(val)) {
        return parseArray(val, htmlToStringCallback);
    }
    else if (typeof val === 'object' && val !== null) {
        return htmlToStringCallback(val);
    }
    return val;
});
const parseObject = (object, htmlToStringCallback) => {
    const keyArray = Object.keys(object);
    return keyArray.reduce((acc, currentKey) => {
        const currentVal = object[currentKey];
        if (Array.isArray(currentVal)) {
            acc[currentKey] = parseArray(currentVal, htmlToStringCallback);
        }
        else if (typeof currentVal === 'object' && currentVal !== null) {
            acc[currentKey] = htmlToStringCallback(currentVal);
        }
        else {
            acc[currentKey] = currentVal;
        }
        return acc;
    }, {});
};
const htmlToString = (obj) => {
    const newObj = decodeStringValues(obj);
    const convertedResult = parseObject(newObj, htmlToString);
    return convertedResult;
};
export default htmlToString;
