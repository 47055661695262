import { parseGqlId } from './stringUtilities';
export const applyWingBuilderIncludeRules = (builder, rules) => {
    const { dips, wings } = builder;
    const ruleIds = rules.filter((rule) => rule.action === 'INCLUDE').map((rule) => rule.actionItem.id);
    const wingsWithRules = wings.reduce((acc, wing) => {
        const options = wing.options.reduce((groups, group) => {
            var _a;
            const modifiers = (_a = group.modifiers) === null || _a === void 0 ? void 0 : _a.filter((modifier) => ruleIds.includes(parseGqlId(modifier.id)));
            const copy = JSON.parse(JSON.stringify(group));
            copy.modifiers = modifiers;
            groups.push(copy);
            return groups;
        }, []);
        const copy = JSON.parse(JSON.stringify(wing));
        copy.options = options;
        acc.push(copy);
        return acc;
    }, []);
    return { dips, wings: wingsWithRules };
};
export default applyWingBuilderIncludeRules;
