var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { customerApi } from './CustomerApi';
import { camelToSnake, removeNulls, snakeToCamel } from '../../utils';
/**
 * Defines the endpoints related to customer addresses. We use the
 * [injectEndpoints](https://redux-toolkit.js.org/rtk-query/usage/code-splitting) feature of RTK Query's `createApi`.
 */
export const customerApiAddress = customerApi.injectEndpoints({
    endpoints: (build) => {
        return {
            addCustomerAddress: build.mutation({
                invalidatesTags: ['Customer'],
                queryFn: (address, { getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    const { coreConfig: { isYumEcomm }, } = getState();
                    const requestBody = camelToSnake(address);
                    const { data: response, error } = yield fetchWithBQ({
                        data: requestBody,
                        isYum: isYumEcomm,
                        method: 'post',
                        url: '/customer/addresses',
                    });
                    if (error) {
                        return { error };
                    }
                    const data = removeNulls(snakeToCamel(response));
                    return { data };
                }),
            }),
            deleteCustomerAddress: build.mutation({
                invalidatesTags: ['Customer'],
                queryFn: (addressId, { getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    const { coreConfig: { isYumEcomm }, } = getState();
                    const { error } = yield fetchWithBQ({
                        isYum: isYumEcomm,
                        method: 'delete',
                        url: `/customer/addresses/${addressId}`,
                    });
                    if (error) {
                        return { error };
                    }
                    return { data: null };
                }),
            }),
            editCustomerAddress: build.mutation({
                invalidatesTags: ['Customer'],
                queryFn: (address, { getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    const { coreConfig: { isYumEcomm }, } = getState();
                    const requestBody = camelToSnake(address);
                    const id = isYumEcomm ? address.addressId : address.customerAddressId;
                    const { data: response, error } = yield fetchWithBQ({
                        data: requestBody,
                        isYum: isYumEcomm,
                        method: 'put',
                        url: `/customer/addresses/${id}`,
                    });
                    if (error) {
                        return { error };
                    }
                    const data = removeNulls(snakeToCamel(response));
                    return { data };
                }),
            }),
        };
    },
});
export const { useAddCustomerAddressMutation, useDeleteCustomerAddressMutation, useEditCustomerAddressMutation } = customerApiAddress;
