var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import { menuApi } from './MenuApi';
import { menuApiContentGatewayHub } from './MenuApiContentGatewayHub';
import GET_DEAL_BUILDER from './queries/getDealBuilder';
import { parseGqlId, removeNulls, sortByOutOfStock, transformYumBundleToDealBuilder } from '../../utils';
export const menuApiDealBuilder = menuApi.injectEndpoints({
    endpoints: (build) => ({
        getDealBuilder: build.query({
            queryFn: (options, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                const { coreConfig: { isYumEcomm, isPreviewMode }, } = getState();
                const { itemId, promotionDefinition, storeNumber, removeBundleVariants = false } = options;
                let response;
                if (isYumEcomm) {
                    response = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate(storeNumber));
                    if (response.data) {
                        const menu = response.data;
                        const dealBuilder = transformYumBundleToDealBuilder(itemId, menu, removeBundleVariants, promotionDefinition, isPreviewMode);
                        response = { data: dealBuilder };
                    }
                }
                else {
                    response = yield fetchWithBQ({
                        data: {
                            operationName: 'getDealBuilder',
                            query: GET_DEAL_BUILDER,
                            variables: {
                                itemID: options.itemId,
                                storeID: `stores/${options.storeNumber}`,
                            },
                        },
                        method: 'post',
                    });
                    if (response.data) {
                        const data = removeNulls(response.data.data.menu.deal.item);
                        const steps = data.steps.map((step) => {
                            // sort recipes by out of stock
                            step.recipes = sortByOutOfStock(step.recipes).map((recipe) => {
                                return Object.assign(Object.assign({}, recipe), { options: [], qoId: parseGqlId(recipe.id), selectedOptions: [] });
                            });
                            return step;
                        });
                        // check if every recipe is out of stock
                        // if so, outOfStock returns true, otherwise, it defaults to false
                        // we spread this variable into the sortedData object to set the deal's out of stock flag
                        const outOfStock = data.steps.reduce((acc, step) => {
                            if (step.recipes.length > 0) {
                                const allOutOfStock = step.recipes.every((recipe) => recipe.outOfStock);
                                if (allOutOfStock)
                                    acc = true;
                            }
                            return acc;
                        }, false);
                        const sortedData = Object.assign(Object.assign({}, data), { outOfStock, steps });
                        response = { data: sortedData };
                    }
                }
                return (response === null || response === void 0 ? void 0 : response.data) ? { data: response.data } : { error: response.error };
            }),
        }),
    }),
});
export const { useGetDealBuilderQuery } = menuApiDealBuilder;
