import transformDipToMultipleProducts from './transformDipToMultipleProducts';
export const transformYumUpsellDipToSingleDip = (product, yumMenuUpsells, yumMenu) => {
    const yumMenuUpsell = yumMenuUpsells.find((upsell) => upsell.yumProductCode === product.productCode);
    const { yumModifierCode, yumVariantCode, yumProductCode, yumSlotCode } = yumMenuUpsell !== null && yumMenuUpsell !== void 0 ? yumMenuUpsell : {};
    if (!yumModifierCode || !yumVariantCode || !yumProductCode || !yumSlotCode) {
        return product;
    }
    const productVariants = product.variants;
    const variantIndex = productVariants.findIndex((variant) => variant.variantCode === yumVariantCode);
    if (variantIndex === -1) {
        return product;
    }
    const variantToEdit = productVariants[variantIndex];
    const variantSlots = variantToEdit.slots;
    const slotIndex = variantSlots === null || variantSlots === void 0 ? void 0 : variantSlots.findIndex((slot) => slot.slotCode === yumSlotCode);
    if (slotIndex === -1) {
        return product;
    }
    const slotToEdit = variantSlots[slotIndex];
    const newModifier = slotToEdit === null || slotToEdit === void 0 ? void 0 : slotToEdit.modifiers.find((modifier) => modifier.modifierCode === yumModifierCode);
    if (!newModifier) {
        return product;
    }
    const newSlot = Object.assign(Object.assign({}, slotToEdit), { modifiers: [newModifier] });
    const insertAt = (array, element, index) => {
        const newArray = [...array];
        newArray.splice(index, 1, element);
        return newArray;
    };
    const newVariantSlots = insertAt(variantSlots, newSlot, slotIndex);
    const newVariant = Object.assign(Object.assign({}, variantToEdit), { defaultModifiers: [
            {
                modifierCode: newModifier.modifierCode,
                modifierWeightCode: newModifier.weights[0].modifierWeightCode,
                slotCode: newSlot.slotCode,
            },
        ], slots: newVariantSlots });
    const newProductVariants = insertAt(productVariants, newVariant, variantIndex);
    return transformDipToMultipleProducts(Object.assign(Object.assign({}, product), { variants: newProductVariants }), yumMenu.modifiers)[0];
};
