var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { logError, parseGqlId, removeNulls } from '../../utils';
import transformCategories from '../../utils/transformCategories';
import transformYumCategoriesToCategoryData from '../../utils/transformYumCategoriesToCategoryData';
import transformProducts from '../../utils/transformProducts';
import { menuApi } from './MenuApi';
import { menuApiContentGatewayHub } from './MenuApiContentGatewayHub';
import GET_CATEGORIES from './queries/getCategories';
import GET_CATEGORY from './queries/getCategory';
export const menuApiCategory = menuApi.injectEndpoints({
    endpoints: (build) => ({
        getCategories: build.query({
            queryFn: (args, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b, _c;
                const { coreConfig: { consolidatedGqlEnvironment, isYumEcomm }, order: { store }, } = getState();
                const storeId = args.storeNumber || (store === null || store === void 0 ? void 0 : store.storeId);
                const occasion = (_a = args.occasion) !== null && _a !== void 0 ? _a : (_c = (_b = store === null || store === void 0 ? void 0 : store.hours) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.occasionId;
                const env = args.environmentId || consolidatedGqlEnvironment;
                if (isYumEcomm && (args === null || args === void 0 ? void 0 : args.storeNumber)) {
                    const response = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate(args.storeNumber));
                    return !response.data
                        ? { error: response.error }
                        : { data: transformYumCategoriesToCategoryData(response.data.categories) };
                }
                const { data, error } = yield fetchWithBQ({
                    data: {
                        operationName: 'getCategories',
                        query: GET_CATEGORIES,
                        variables: {
                            envID: env,
                            occasions: occasion,
                            storeID: storeId,
                        },
                    },
                    method: 'post',
                });
                if (!data) {
                    logError(error);
                    return { error: error };
                }
                const categoryResponse = data;
                return { data: transformCategories(categoryResponse.data.menu.categories) };
            }),
        }),
        getProductsByCategory: build.query({
            queryFn: (args, { getState, dispatch }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                var _d, _e, _f;
                const { coreConfig: { consolidatedGqlEnvironment, isYumEcomm }, order: { store }, } = getState();
                const categoryId = args.categoryId;
                const storeId = args.storeNumber || (store === null || store === void 0 ? void 0 : store.storeId);
                const occasion = (_d = args.occasion) !== null && _d !== void 0 ? _d : (_f = (_e = store === null || store === void 0 ? void 0 : store.hours) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.occasionId;
                const env = args.environmentId || consolidatedGqlEnvironment;
                const includeNutrition = !!args.includeNutrition;
                const removeBundleVariants = args.removeBundleVariants || true;
                if (isYumEcomm && (args === null || args === void 0 ? void 0 : args.storeNumber)) {
                    const response = yield dispatch(menuApiContentGatewayHub.endpoints.getContentGatewayMenuHub.initiate({
                        removeBundleVariants,
                        storeNumber: args.storeNumber,
                    }));
                    if (!response.data)
                        return { error: response.error };
                    const category = response.data.categories.find((cat) => cat.id.toLowerCase() === categoryId.toLowerCase());
                    if (!category)
                        return { error: `No category found for categoryId: ${categoryId}` };
                    const { displayOrder, featuredProducts, heading, id, legalDisclaimer, name, products, subHeading } = category;
                    // retaining same structure as phdAPI for the time being, but may not need these optional fields
                    return {
                        data: removeNulls({
                            displayOrder,
                            featuredProducts: featuredProducts || [],
                            heading: heading || null,
                            id,
                            legalDisclaimer: legalDisclaimer || null,
                            name,
                            products,
                            subHeading: subHeading || null,
                        }),
                    };
                }
                const { data, error } = yield fetchWithBQ({
                    data: {
                        operationName: 'getProducts',
                        query: GET_CATEGORY,
                        variables: {
                            categoryID: categoryId,
                            envID: env,
                            includeNutrition,
                            occasions: occasion,
                            storeID: storeId,
                        },
                    },
                    method: 'post',
                });
                if (!data) {
                    logError(error);
                    return { error: error };
                }
                const categoryResponse = data;
                const { displayOrder, heading, id, legalDisclaimer, name, products, subHeading } = categoryResponse.data.menu.category;
                return {
                    data: removeNulls({
                        displayOrder,
                        featuredProducts: [],
                        heading,
                        id: parseGqlId(id),
                        legalDisclaimer,
                        name,
                        products: transformProducts(products),
                        subHeading,
                    }),
                };
            }),
        }),
    }),
});
export const { useGetCategoriesQuery, useGetProductsByCategoryQuery } = menuApiCategory;
