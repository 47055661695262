import { gql } from 'graphql-request';
import AvailabilityFragment from '../fragments/availability';
import CategoryItemWithNutritionFragment from '../fragments/categoryItemWithNutrition';
const GET_CATEGORY = gql `
    query getProducts(
        $envID: String!
        $categoryID: String!
        $storeID: String!
        $occasions: [OccasionV3!]
        $includeNutrition: Boolean!
    ) {
        menu: Homepage(envID: $envID, storeID: $storeID, occasions: $occasions) {
            category(categoryID: $categoryID) {
                displayOrder: index
                id: name
                heading
                legalDisclaimer
                name: displayName
                products {
                    displayOrder
                    name: displayName
                    qoId
                    item {
                        availability {
                            ...AvailabilityFragment
                        }
                        ...CategoryItemWithNutritionFragment
                        subcategories {
                            subcategoryID
                            subcategoryName
                        }
                    }
                }
                subHeading
            }
        }
    }
    ${AvailabilityFragment}
    ${CategoryItemWithNutritionFragment}
`;
export default GET_CATEGORY;
