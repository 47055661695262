var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { storeApi } from './StoreApi';
import { snakeToCamel } from '../../utils';
import { menuApiDealBuilder } from '../menu/MenuApiDealBuilder';
/**
 * Defines the endpoints related to store information. We use the
 * [injectEndpoints](https://redux-toolkit.js.org/rtk-query/usage/code-splitting) feature of RTK Query's `createApi`.
 */
export const storeApiInfo = storeApi.injectEndpoints({
    endpoints: (build) => ({
        getDiscountCode: build.query({
            query: (discountCodeOptions) => {
                const { storeNumber, marketingCode } = discountCodeOptions;
                return {
                    method: 'get',
                    url: `/stores/${storeNumber}/discounts/marketing-code/${marketingCode}`,
                };
            },
            transformResponse: (response) => {
                const codeResponse = snakeToCamel(response);
                const id = codeResponse.discountId || codeResponse.masterDiscountId || '';
                return id;
            },
        }),
        getDiscountFromMarketingCode: build.query({
            queryFn: (discountCodeOptions, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                const { coreConfig: { isYumEcomm }, } = getState();
                const { storeNumber, marketingCode } = discountCodeOptions;
                let itemId = marketingCode;
                if (!isYumEcomm) {
                    const response = yield fetchWithBQ({
                        method: 'get',
                        url: `/stores/${storeNumber}/discounts/marketing-code/${marketingCode}`,
                    });
                    if (response.error) {
                        return { error: response.error };
                    }
                    const { discount: discountCodes } = response.data;
                    if (!(discountCodes === null || discountCodes === void 0 ? void 0 : discountCodes.length)) {
                        return { error: 'no discount codes returned in response' };
                    }
                    // ts is complaining about discount being possibly undefined so here's a ternary
                    itemId = discountCodes[0].name;
                }
                const { data, error } = yield dispatch(menuApiDealBuilder.endpoints.getDealBuilder.initiate({
                    itemId,
                    storeNumber,
                }));
                if (error || !data) {
                    return { error };
                }
                return { data };
            }),
        }),
        storeInfo: build.query({
            query: (storeNumber) => ({
                method: 'get',
                url: `/stores/${storeNumber}`,
            }),
            transformResponse: (response) => {
                const data = snakeToCamel(response);
                return data;
            },
        }),
    }),
});
export const { useGetDiscountCodeQuery, useGetDiscountFromMarketingCodeQuery, useStoreInfoQuery } = storeApiInfo;
