import { gql } from 'graphql-request';
import AvailabilityFragment from './availability';
const DiscountFragment = gql `
    fragment DiscountFragment on Item {
        allergenDisclaimer
        availability {
            ...AvailabilityFragment
        }
        categoryPageImageURL
        combinableDiscount
        defaultSelectedOption
        description
        displayOrder: priority
        featuredProductImageURL
        hidden
        id
        imageURL
        internalName
        legalText
        menuHubImageURL
        name
        outOfStock
        price
        sodiumWarning
        type
        universalMobileImageURL
    }
    ${AvailabilityFragment}
`;
export default DiscountFragment;
