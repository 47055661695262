import computeProductPrice from '../computeProductPrice';
const computeWingPrice = (wingBuilder) => {
    const { selectedAdditionalOption, selectedSauce, selectedSize, selectedDips } = wingBuilder;
    const dipPrice = selectedDips.reduce((acc, dip) => {
        acc += computeProductPrice(dip.selectedOptions);
        return acc;
    }, 0);
    const wingOptions = [selectedAdditionalOption, selectedSauce, selectedSize];
    const selectedOptions = wingOptions.reduce((acc, item) => {
        if (item) {
            acc.push(item);
        }
        return acc;
    }, []);
    const wingPrice = computeProductPrice(selectedOptions);
    return dipPrice + wingPrice;
};
export default computeWingPrice;
