import { gql } from 'graphql-request';
import ItemNutritionFragment from '../fragments/itemNutrition';
import ModifierItemNutritionFragment from '../fragments/modifierItemNutrition';
import NutritionFragment from '../fragments/nutrition';
const GET_MENU_HUB_NUTRITION = gql `
    query getMenuHubNutrition($envID: String!, $storeID: String!, $occasions: [OccasionV3!]) {
        menu: Homepage(envID: $envID, storeID: $storeID, occasions: $occasions) {
            categories {
                id: name
                name: displayName
                products {
                    displayOrder
                    name: displayName
                    qoId
                    item {
                        ...ItemNutritionFragment
                        modifiers {
                            displayOrder: priority
                            id
                            name
                            modifiers {
                                ...ModifierItemNutritionFragment
                                nutrition {
                                    ...NutritionFragment
                                }
                                modifiers {
                                    ...ModifierItemNutritionFragment
                                    nutrition {
                                        ...NutritionFragment
                                    }
                                }
                            }
                            nutrition {
                                ...NutritionFragment
                            }
                        }
                        nutrition {
                            ...NutritionFragment
                        }
                    }
                }
            }
        }
    }
    ${ItemNutritionFragment}
    ${ModifierItemNutritionFragment}
    ${NutritionFragment}
`;
export default GET_MENU_HUB_NUTRITION;
