var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OrderActions } from '../slices/OrderSlice';
import camelToSnake from './camelToSnake';
import snakeToCamel from './snakeToCamel';
// the following are reusable functions that can be used within a queryFn function
// in the future, localizeToStore and createCart will be reused within the addHistoricalOrder hook
export const localizeToStore = (fetchWithBQ, storePayload) => __awaiter(void 0, void 0, void 0, function* () {
    const payloadData = camelToSnake(storePayload);
    const localizationData = yield fetchWithBQ({
        data: payloadData,
        method: 'post',
        url: `/stores/search`,
    });
    const localizationResponse = localizationData.data;
    const localizedStore = snakeToCamel(localizationResponse).find((store) => store.storeNumber);
    return localizedStore;
});
export const createCart = (fetchWithBQ, store, dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const cart = yield fetchWithBQ({
        data: { items: [] },
        headers: {
            Localization: (store === null || store === void 0 ? void 0 : store.token) ? store.token : '',
        },
        method: 'post',
        url: `/carts`,
    });
    const cartResponse = cart.data;
    const ccCart = camelToSnake(cartResponse);
    dispatch(OrderActions.setCartId(ccCart.cartId || ''));
    return snakeToCamel(ccCart);
});
export const addFavoriteToCart = (fetchWithBQ, data, cId) => __awaiter(void 0, void 0, void 0, function* () {
    const favoriteData = camelToSnake(data);
    const addToCartData = yield fetchWithBQ({
        data: favoriteData,
        method: 'post',
        url: `/carts/${cId}/favorites`,
    });
    return snakeToCamel(addToCartData);
});
export const addHistoricalOrderToCart = (fetchWithBQ, data, cartId) => __awaiter(void 0, void 0, void 0, function* () {
    const orderData = camelToSnake(data);
    const addToCartData = yield fetchWithBQ({
        data: orderData,
        method: 'post',
        url: `/carts/${cartId}/orders`,
    });
    return snakeToCamel(addToCartData);
});
