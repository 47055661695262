import { categoryFeaturedProducts, menuHubFeaturedProducts } from './filterUtilities';
import { sortByDisplayOrder } from './sortUtilities';
const applyMenuHubConfig = (categories, config) => {
    // Declare an empty MenuHub for the return value
    const hub = {
        categories: [],
        featuredProducts: [],
    };
    // Map over the incoming menu categories
    // 1. Set the displayOrder of each category according to the MenuHubConfig
    // 2. Filter out the featured products for each category according to the MenuHubConfig
    const configuredCategories = categories.map((category) => {
        // Get the ID of the category
        const { id } = category;
        // The index of the category ID in config.categorySortOrder is the displayOrder
        category.displayOrder = config.categorySortOrder.indexOf(id);
        // Filter the category featured products based on the MenuHubConfig
        category.featuredProducts = categoryFeaturedProducts(category, config);
        return category;
    });
    // 2. Sort the incoming categories by displayOrder and set them on hub
    hub.categories = configuredCategories.sort(sortByDisplayOrder);
    // 3. Filter the menu hub featured products according to the MenuHubConfig
    hub.featuredProducts = menuHubFeaturedProducts(configuredCategories, config);
    return hub;
};
export default applyMenuHubConfig;
