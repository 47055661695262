import { sortByDisplayOrder, sortByOutOfStock } from './sortUtilities';
import transformGqlDealItem from './transformGqlDealItem';
export const transformMenuDeals = (hub) => {
    const { deals, discounts } = hub.store.menu;
    const transformedDeals = transformGqlDealItem(deals);
    const transformedDiscounts = transformGqlDealItem(discounts);
    const products = sortByOutOfStock(transformedDeals.concat(transformedDiscounts).sort(sortByDisplayOrder));
    return {
        displayOrder: 0,
        featuredProducts: [],
        id: 'deals',
        name: 'Deals',
        products,
    };
};
export default transformMenuDeals;
