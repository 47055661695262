export const transformProductToCartLineItemInput = (product) => {
    var _a;
    let variantCode = '';
    const quantity = product.quantity || 1;
    const specialInstructions = product.specialInstructions || '';
    const raiseOnConflict = true;
    const selectedModifiers = (_a = product.selectedOptions) === null || _a === void 0 ? void 0 : _a.reduce((acc, option) => {
        if (option.variantCode) {
            variantCode = option.variantCode;
        }
        if (option.slotCode) {
            acc.push({
                modifierCode: option.id,
                modifierWeightCode: option.weightCode || 'WHOLE_REGULAR',
                slotCode: option.slotCode || '',
            });
        }
        return acc;
    }, []);
    return {
        products: [
            {
                configuredProduct: {
                    productCode: product.id,
                    selectedModifiers,
                    variantCode,
                },
                quantity,
                specialInstructions,
            },
        ],
        raiseOnConflict,
    };
};
export default transformProductToCartLineItemInput;
