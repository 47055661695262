import { DiningOccasion } from '../types';
import getLineupItemsVariantCodes from './getLineupItemsVariantCodes';
import transformYumCartLineItemToCartItem from './transformYumCartLineItemToCartItem';
const transformYumCartToCart = (cartResponse, menu, promotionDefinitions) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const { cart } = cartResponse;
    const { belowLineItems, orderabilityConflicts, lineItems, occasion, recipient, total } = cart;
    const variantCodes = getLineupItemsVariantCodes(menu.products);
    const { cartDeliveryAddress: deliveryAddress = {}, email, firstName, lastName, phoneNumber } = Object.assign({}, recipient);
    const fees = (_b = (_a = belowLineItems === null || belowLineItems === void 0 ? void 0 : belowLineItems.fees) === null || _a === void 0 ? void 0 : _a.map((yumFee) => {
        var _a, _b, _c, _d, _e, _f;
        return ({
            amount: (_b = (_a = yumFee.amount) === null || _a === void 0 ? void 0 : _a.amount) !== null && _b !== void 0 ? _b : 0,
            name: (_c = yumFee.name) !== null && _c !== void 0 ? _c : '',
            originalAmount: (_e = (_d = yumFee.originalAmount) === null || _d === void 0 ? void 0 : _d.amount) !== null && _e !== void 0 ? _e : 0,
            type: (_f = yumFee.type) !== null && _f !== void 0 ? _f : '',
        });
    })) !== null && _b !== void 0 ? _b : [];
    const items = (_c = lineItems === null || lineItems === void 0 ? void 0 : lineItems.map((lineItem) => transformYumCartLineItemToCartItem(lineItem, menu, variantCodes, orderabilityConflicts))) !== null && _c !== void 0 ? _c : [];
    const subtotal = (_e = (_d = belowLineItems === null || belowLineItems === void 0 ? void 0 : belowLineItems.subtotal) === null || _d === void 0 ? void 0 : _d.amount) !== null && _e !== void 0 ? _e : 0;
    const tax = (_g = (_f = belowLineItems === null || belowLineItems === void 0 ? void 0 : belowLineItems.taxes) === null || _f === void 0 ? void 0 : _f.map((yumTax) => {
        var _a, _b, _c;
        return ({
            amount: (_b = (_a = yumTax.amount) === null || _a === void 0 ? void 0 : _a.amount) !== null && _b !== void 0 ? _b : 0,
            name: (_c = yumTax.name) !== null && _c !== void 0 ? _c : '',
        });
    })) !== null && _g !== void 0 ? _g : [];
    const lineupCount = items.reduce((acc, lineItem) => {
        var _a, _b;
        const variantCode = (_a = lineItem.variantCode) !== null && _a !== void 0 ? _a : '';
        if (variantCodes.includes(variantCode)) {
            acc += (_b = lineItem.quantity) !== null && _b !== void 0 ? _b : 1;
        }
        return acc;
    }, 0);
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, cart), { deliveryAddress, discountedAmount: belowLineItems.originalSubtotal.amount - belowLineItems.subtotal.amount || 0 }), (orderabilityConflicts !== undefined && { failedItems: { data: orderabilityConflicts, type: 'Conflict' } })), { fees,
        items,
        lineupCount, lineupReady: lineupCount !== 1, occasionId: DiningOccasion[occasion] }), (promotionDefinitions !== undefined && promotionDefinitions.length > 0 && { promotionDefinitions })), (recipient !== undefined && { recipient: { email, firstName, lastName, phoneNumber } })), { subtotal,
        tax, tip: (_j = (_h = belowLineItems.tip) === null || _h === void 0 ? void 0 : _h.amount) !== null && _j !== void 0 ? _j : 0, total: (_k = total === null || total === void 0 ? void 0 : total.amount) !== null && _k !== void 0 ? _k : 0, type: 'YUM' });
};
export default transformYumCartToCart;
