var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { customerApi } from './CustomerApi';
import { camelToSnake, removeNulls, snakeToCamel } from '../../utils';
/**
 * Defines the endpoints related to customer account. We use the
 * [injectEndpoints](https://redux-toolkit.js.org/rtk-query/usage/code-splitting) feature of RTK Query's `createApi`.
 */
export const customerApiAccount = customerApi.injectEndpoints({
    endpoints: (build) => {
        return {
            createAccount: build.mutation({
                query: (payload) => {
                    const data = camelToSnake(payload);
                    return {
                        data,
                        method: 'post',
                        url: `/customer`,
                    };
                },
                transformResponse: (response) => {
                    const data = snakeToCamel(response);
                    return data;
                },
            }),
            customerInfo: build.query({
                providesTags: ['Customer'],
                queryFn: (_, { getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    const { coreConfig: { isYumEcomm }, } = getState();
                    const { data: response, error } = yield fetchWithBQ({
                        isYum: isYumEcomm,
                        method: 'get',
                        url: '/customer',
                    });
                    if (error) {
                        return { error };
                    }
                    const data = removeNulls(snakeToCamel(response));
                    return { data };
                }),
            }),
            customerLookup: build.mutation({
                queryFn: (lookup, { getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    const { coreConfig: { isYumEcomm }, } = getState();
                    const { data: response, error } = yield fetchWithBQ({
                        data: lookup,
                        isYum: isYumEcomm,
                        method: 'post',
                        url: '/customer/lookup',
                    });
                    if (error) {
                        return { error };
                    }
                    const data = snakeToCamel(response);
                    return { data };
                }),
            }),
            deleteCoupon: build.mutation({
                invalidatesTags: (_result, error) => (error ? [] : ['Loyalty']),
                query: (associationKey) => {
                    return {
                        method: 'delete',
                        url: `/customer/coupon/${associationKey}`,
                    };
                },
                transformResponse: (response) => {
                    const data = snakeToCamel(response);
                    return data;
                },
            }),
            deletePayment: build.mutation({
                query: (payment) => {
                    const data = camelToSnake(payment);
                    return {
                        data,
                        method: 'delete',
                        url: `/customer/payments/${payment.paymentId}/${payment.type}`,
                    };
                },
            }),
            loyaltyInfo: build.query({
                providesTags: ['Loyalty'],
                query: () => ({
                    method: 'get',
                    url: `/customer/loyalty`,
                }),
                transformResponse: (response) => {
                    var _a, _b, _c;
                    // transform the rewards
                    const rewards = response.rewards.map((reward) => {
                        const { thumbImage } = reward, rest = __rest(reward, ["thumbImage"]);
                        return Object.assign(Object.assign({}, rest), { image: thumbImage });
                    });
                    // transform the response
                    const data = snakeToCamel(response);
                    // if the coupons is null, make it an empty array
                    // set lockedPoint and pendingPoints to 0 if they are not present
                    // set the rewards
                    const loyalty = Object.assign(Object.assign({}, data), { coupons: (_a = data.coupons) !== null && _a !== void 0 ? _a : [], lockedPoints: (_b = data.lockedPoints) !== null && _b !== void 0 ? _b : 0, pendingPoints: (_c = data.pendingPoints) !== null && _c !== void 0 ? _c : 0, rewards });
                    return loyalty;
                },
            }),
            passwordReset: build.mutation({
                query: (data) => {
                    return {
                        data,
                        method: 'post',
                        url: `/password/reset`,
                    };
                },
            }),
            redeemCoupon: build.mutation({
                invalidatesTags: (_result, error) => (error ? [] : ['Loyalty']),
                query: (key) => {
                    return {
                        data: { key },
                        method: 'post',
                        url: '/customer/coupon',
                    };
                },
                transformResponse: (response) => {
                    const data = snakeToCamel(response);
                    return data;
                },
            }),
            updateCustomerInfo: build.mutation({
                invalidatesTags: ['Customer'],
                queryFn: (updatePayload, { getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    const { coreConfig: { isYumEcomm }, } = getState();
                    const updateData = camelToSnake(updatePayload);
                    const { data: response, error } = yield fetchWithBQ({
                        data: updateData,
                        isYum: isYumEcomm,
                        method: 'put',
                        url: '/customer',
                    });
                    if (error) {
                        return { error };
                    }
                    const data = snakeToCamel(response);
                    return { data };
                }),
            }),
        };
    },
});
export const { useCreateAccountMutation, useCustomerInfoQuery, useCustomerLookupMutation, useDeleteCouponMutation, useDeletePaymentMutation, useLoyaltyInfoQuery, usePasswordResetMutation, useRedeemCouponMutation, useUpdateCustomerInfoMutation, } = customerApiAccount;
