import { removeNulls } from './filterUtilities';
import { sortByOutOfStock } from './sortUtilities';
/**
 *
 * @param gqlProduct Array of products from gql endpoint
 * @returns Product array matching CC contract type
 */
const transformProducts = (gqlProduct) => {
    const newProducts = gqlProduct
        .filter((product) => { var _a; return (_a = product.item) === null || _a === void 0 ? void 0 : _a.exists; })
        .map((product) => {
        const item = removeNulls(product.item);
        const newProduct = Object.assign(Object.assign({}, item), { displayOrder: product.displayOrder, qoId: product.qoId, selectedOptions: [] });
        newProduct.options = item.modifiers;
        delete newProduct.modifiers;
        return newProduct;
    });
    return sortByOutOfStock(newProducts);
};
export default transformProducts;
