var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { customerApi } from './CustomerApi';
import snakeToCamel from '../../utils/snakeToCamel';
import { CustomerActions } from '../../slices/CustomerSlice';
const { setAuthToken, setIsGuest } = CustomerActions;
export const customerApiAuth = customerApi.injectEndpoints({
    endpoints: (build) => {
        return {
            guestSignIn: build.mutation({
                onQueryStarted: (_, { queryFulfilled, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setAuthToken({ authToken: data === null || data === void 0 ? void 0 : data.authToken, tokenType: 'pizzahut' }));
                        dispatch(setIsGuest(true));
                    }
                    catch (err) {
                        console.log(err);
                    }
                }),
                queryFn: (_args, { getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    const { coreConfig: { authChannel }, } = getState();
                    const { data, error } = yield fetchWithBQ({
                        data: {
                            channel_id: authChannel,
                        },
                        method: 'post',
                        url: `/guest`,
                    });
                    if (error) {
                        return { error };
                    }
                    const loginResponse = snakeToCamel(data);
                    return { data: loginResponse };
                }),
            }),
            // name changed from customerSignIn 9/21/22 - see axiosBaseQueryV1 for details
            signInCustomer: build.mutation({
                onQueryStarted: (_, { queryFulfilled, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setAuthToken({ authToken: data === null || data === void 0 ? void 0 : data.authToken, tokenType: 'pizzahut' }));
                        dispatch(setIsGuest(false));
                    }
                    catch (err) {
                        console.log(err);
                    }
                }),
                query: ({ password, username }) => {
                    return {
                        data: {
                            password,
                            username,
                        },
                        method: 'post',
                        url: `/customer/login`,
                    };
                },
                transformResponse: (response) => {
                    const data = snakeToCamel(response);
                    return data;
                },
            }),
        };
    },
});
export const { useGuestSignInMutation, useSignInCustomerMutation } = customerApiAuth;
