import { Placement, Portion } from '../types';
const Portions = {
    0: Portion.NONE,
    1: Portion.REGULAR,
    2: Portion.EXTRA,
};
// PHD returns placement and portion as an array of numbers
//
// 0 = NONE
// 1 = REGULAR
// 2 = EXTRA
//
// if the array has a single number, then placement is WHOLE
// if the array has two numbers, the first is the LEFT portion
// and the second is the RIGHT portion, one of them will be 0 and is not used
const transformQuantities = (quantities) => {
    if (quantities.length === 1) {
        const [key] = quantities;
        return {
            placement: Placement.WHOLE,
            portion: Portions[key],
        };
    }
    else if (quantities.length === 2) {
        const [left, right] = quantities;
        if (left > right) {
            return {
                placement: Placement.LEFT,
                portion: Portions[left],
            };
        }
        else if (right > left) {
            return {
                placement: Placement.RIGHT,
                portion: Portions[right],
            };
        }
        else {
            return {
                placement: Placement.WHOLE,
                portion: Portions[left],
            };
        }
    }
    return {
        placement: Placement.WHOLE,
        portion: Portion.NONE,
    };
};
export default transformQuantities;
