import { sortByDisplayOrder } from './sortUtilities';
import { parseGqlId } from './stringUtilities';
export const applyPizzaBuilderIncludeRules = (builder, rules) => {
    // Get the sizesAndCrusts that cam form the getPizzaBuilder query
    const { sizesAndCrusts } = builder;
    // Filter out the INCLUDE rules by sizes and crusts. Crusts are of type MODIFIER
    const sizeRules = rules.filter((rule) => rule.actionItem.type === 'SIZE').map((rule) => rule.actionItem.id);
    const crustRules = rules.filter((rule) => rule.actionItem.type === 'MODIFIER').map((rule) => rule.actionItem.id);
    // Reduce the sizes array and then filter out only sizes and crusts that are
    // in the INCLUDE rules
    const includedSizesAndCrusts = sizeRules.reduce((acc, sizeId) => {
        var _a;
        const size = sizesAndCrusts.find((s) => parseGqlId(s.id) === sizeId);
        if (size) {
            acc.push(Object.assign(Object.assign({}, size), { modifiers: (_a = size.modifiers) === null || _a === void 0 ? void 0 : _a.filter((crust) => crustRules.includes(parseGqlId(crust.id))).sort(sortByDisplayOrder) }));
        }
        return acc;
    }, []);
    return includedSizesAndCrusts;
};
export default applyPizzaBuilderIncludeRules;
