import { Placement, Portion } from '../types';
import transformPlacementPortionToWeightCode from './transformPlacementPortionToWeightCode';
export const transformPizzaBuilderToYumCartItem = (pizzaBuilder) => {
    const { builder, selectedCheese, selectedCrust, selectedSauce, selectedToppings, quantity, specialInstructions } = pizzaBuilder;
    const yumProduct = {
        configuredProduct: {
            productCode: builder.id,
            selectedModifiers: [selectedCheese, selectedSauce, selectedToppings]
                .filter((modifier) => !!modifier)
                .reduce((acc, modifier) => {
                var _a, _b, _c;
                if (Array.isArray(modifier)) {
                    modifier.forEach((topping) => {
                        var _a, _b, _c;
                        const modifierWeightCode = transformPlacementPortionToWeightCode((_a = topping.placement) !== null && _a !== void 0 ? _a : Placement.WHOLE, (_b = topping.portion) !== null && _b !== void 0 ? _b : Portion.REGULAR, (_c = topping.slotCode) !== null && _c !== void 0 ? _c : '');
                        acc = [
                            ...acc,
                            {
                                modifierCode: topping.id,
                                modifierWeightCode,
                                slotCode: topping.slotCode || '',
                            },
                        ];
                    });
                }
                else {
                    const modifierWeightCode = transformPlacementPortionToWeightCode((_a = modifier.placement) !== null && _a !== void 0 ? _a : Placement.WHOLE, (_b = modifier.portion) !== null && _b !== void 0 ? _b : Portion.REGULAR, (_c = modifier.slotCode) !== null && _c !== void 0 ? _c : '');
                    acc = [
                        ...acc,
                        {
                            modifierCode: modifier.slotCode === 'slot_pizza_cheese' ? 'modifier_pizza_cheese' : modifier.id,
                            modifierWeightCode,
                            slotCode: modifier.slotCode || '',
                        },
                    ];
                }
                return acc;
            }, []),
            variantCode: (selectedCrust === null || selectedCrust === void 0 ? void 0 : selectedCrust.variantCode) || '',
        },
        quantity: quantity || 1,
        raiseOnConflict: true,
        specialInstructions: specialInstructions || '',
    };
    return {
        items: [yumProduct],
    };
};
export default transformPizzaBuilderToYumCartItem;
