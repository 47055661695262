var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DiningOccasion } from '../../types';
import { htmlToString, transformYumMenu } from '../../utils';
import { menuApiContentGateway } from './MenuApiContentGateway';
export const menuApiContentGatewayHub = menuApiContentGateway.injectEndpoints({
    endpoints: (build) => ({
        getContentGatewayMenuHub: build.query({
            queryFn: (options, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
                var _a;
                const { promotionDefinition, removeBundleVariants = true, storeNumber } = options;
                const response = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate(storeNumber));
                if (response.error) {
                    return { error: response.error };
                }
                // get the dining occasion from the cart
                const { coreConfig: { isPreviewMode }, order: { cart }, } = getState();
                const occasion = (_a = cart === null || cart === void 0 ? void 0 : cart.occasionId) !== null && _a !== void 0 ? _a : DiningOccasion.CARRYOUT;
                const menu = response.data;
                const menuHub = transformYumMenu(menu, occasion, removeBundleVariants, isPreviewMode, promotionDefinition);
                return {
                    data: menuHub,
                };
            }),
            structuralSharing: false,
        }),
        getMenu: build.query({
            query: (storeNumber) => ({
                method: 'get',
                url: `/stores/${storeNumber}/menu`,
            }),
            structuralSharing: false,
            transformResponse: (response) => htmlToString(response),
        }),
    }),
});
export const { useGetContentGatewayMenuHubQuery, useGetMenuQuery } = menuApiContentGatewayHub;
