import snakeToCamel from './snakeToCamel';
import transformPhdCartItemToCartItem from './transformPhdCartItemToCartItem';
const transformPhdCartToCart = (cartResponse) => {
    var _a, _b;
    const cart = snakeToCamel(cartResponse);
    const { discountedAmount, failedItems, fees: cartFees, items: cartItems, tax: cartTax = 0, subtotal, total } = cart;
    const fees = (_a = cartFees === null || cartFees === void 0 ? void 0 : cartFees.map((phdFee) => {
        const { amount = 0, name } = phdFee;
        return {
            amount,
            name,
        };
    })) !== null && _a !== void 0 ? _a : [];
    const items = (_b = cartItems === null || cartItems === void 0 ? void 0 : cartItems.map((item) => transformPhdCartItemToCartItem(item))) !== null && _b !== void 0 ? _b : [];
    // PHD gives tax as an integer of 1/1000th of a dollar instead of 1/100th
    const convertedTax = Math.floor(cartTax / 10);
    const tax = [{ amount: convertedTax, name: 'Tax' }];
    return Object.assign(Object.assign({}, snakeToCamel(cartResponse)), { discountedAmount, failedItems: (failedItems === null || failedItems === void 0 ? void 0 : failedItems.length) ? { data: failedItems, type: 'Fail' } : undefined, fees,
        items, subtotal: subtotal !== null && subtotal !== void 0 ? subtotal : 0, tax, total: total !== null && total !== void 0 ? total : 0, type: 'PHD' });
};
export default transformPhdCartToCart;
