var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import computeProductPrice from '../computeProductPrice';
import computePizzaPrice from '../pizzaBuilder/computePizzaPrice';
import computeWingPrice from '../wingBuilder/computeWingPrice';
const computeDealPrice = (dealBuilder) => __awaiter(void 0, void 0, void 0, function* () {
    return Object.values(dealBuilder.stepSelections).reduce((acc, selectedProduct) => {
        if ('selectedCrust' in selectedProduct) {
            acc += computePizzaPrice(selectedProduct);
        }
        else if ('selectedWings' in selectedProduct) {
            acc += computeWingPrice(selectedProduct);
        }
        else {
            acc += computeProductPrice(selectedProduct.selectedOptions);
        }
        return acc;
    }, 0);
});
export default computeDealPrice;
