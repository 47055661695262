import { Categories } from '../types';
import { sortByOutOfStock } from './sortUtilities';
// if no dip ids are passed, default dipIds to list of known dip ids
const transformWingBuilder = (wings, dipIds = ['A^O^XS', 'A^O^XD', 'WS^O^XD']) => {
    const dipQoIdIdentifiers = dipIds;
    const wingBuilder = wings.products.reduce((acc, cur) => {
        const isDip = dipQoIdIdentifiers.some((dipQOId) => cur.qoId.includes(dipQOId));
        const categoryKey = isDip ? Categories.DIPS : Categories.WINGS;
        const wingOrDip = Object.assign(Object.assign({}, cur), { selectedOptions: [] });
        acc[categoryKey].push(wingOrDip);
        return acc;
    }, { [Categories.DIPS]: [], [Categories.WINGS]: [] });
    for (const key in wingBuilder) {
        wingBuilder[key] = sortByOutOfStock(wingBuilder[key]);
    }
    return wingBuilder;
};
export default transformWingBuilder;
