var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { axiosErrorHandler } from './axiosBase';
import { newAuthTokenAfterTokenRefresh } from './newAuthTokenAfterTokenRefresh';
/**
 * Custom Axios BaseQuery that handles auto-refreshing
 * expired auth tokens.
 */
export const axiosQuery = (args) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const { axiosConfig, api, clientId, refreshToken, tokenType, onSuccess } = args;
    try {
        const result = yield axios(axiosConfig);
        if (onSuccess) {
            onSuccess(result);
        }
        return { data: result.data };
    }
    catch (error) {
        const err = error;
        if (((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) === 401 && refreshToken && tokenType === 'yum') {
            const newAccessToken = yield newAuthTokenAfterTokenRefresh({
                api,
                clientId: clientId,
                refreshToken,
            });
            if (newAccessToken) {
                const newHeaders = Object.assign({}, args.axiosConfig.headers);
                if (newHeaders['auth-token']) {
                    newHeaders['auth-token'] = newAccessToken;
                }
                if (newHeaders['Authorization']) {
                    newHeaders['Authorization'] = `Bearer ${newAccessToken}`;
                }
                const newArgs = Object.assign(Object.assign({}, args), { axiosConfig: Object.assign(Object.assign({}, args.axiosConfig), { headers: newHeaders }) });
                return yield axiosQuery(newArgs);
            }
        }
        return axiosErrorHandler(err);
    }
});
