const mergeNutrition = (category, nutrition) => {
    const addNutrition = (arr) => {
        return arr.map((product) => {
            const productNutrition = nutrition.products.find((n) => n.id === product.id);
            const newOptions = product.options.map((option, optionIndex) => {
                var _a, _b;
                const newOption = (_a = option.modifiers) === null || _a === void 0 ? void 0 : _a.map((modifier, modifierIndex) => {
                    var _a, _b, _c, _d;
                    const newModifiers = (_a = modifier.modifiers) === null || _a === void 0 ? void 0 : _a.map((m, mIndex) => {
                        var _a, _b, _c, _d, _e;
                        return Object.assign(Object.assign({}, m), (_e = (_d = (_c = (_b = (_a = productNutrition === null || productNutrition === void 0 ? void 0 : productNutrition.options) === null || _a === void 0 ? void 0 : _a[optionIndex]) === null || _b === void 0 ? void 0 : _b.modifiers) === null || _c === void 0 ? void 0 : _c[modifierIndex]) === null || _d === void 0 ? void 0 : _d.modifiers) === null || _e === void 0 ? void 0 : _e[mIndex]);
                    });
                    return Object.assign(Object.assign(Object.assign({}, modifier), (_d = (_c = (_b = productNutrition === null || productNutrition === void 0 ? void 0 : productNutrition.options) === null || _b === void 0 ? void 0 : _b[optionIndex]) === null || _c === void 0 ? void 0 : _c.modifiers) === null || _d === void 0 ? void 0 : _d[modifierIndex]), { modifiers: newModifiers });
                });
                return Object.assign(Object.assign(Object.assign({}, option), (_b = productNutrition === null || productNutrition === void 0 ? void 0 : productNutrition.options) === null || _b === void 0 ? void 0 : _b[optionIndex]), { modifiers: newOption });
            });
            return Object.assign(Object.assign({}, product), { options: newOptions });
        });
    };
    const products = addNutrition(category.products);
    const featuredProducts = addNutrition(category.featuredProducts);
    return Object.assign(Object.assign({}, category), { featuredProducts,
        products });
};
export default mergeNutrition;
