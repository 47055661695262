import { NonSizeKeys, SizeKeys } from '../types';
const reduceProductSelectedOptions = (options, isYumEcomm = false) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const [selectedSize] = options.filter((o) => {
        var _a;
        if (isYumEcomm) {
            return SizeKeys.has((_a = o.optionTypeCode) !== null && _a !== void 0 ? _a : '');
        }
        else {
            return o.type === 'SIZE';
        }
    });
    const [selectedOption] = options.filter((o) => {
        var _a;
        if (isYumEcomm) {
            return NonSizeKeys.has((_a = o.optionTypeCode) !== null && _a !== void 0 ? _a : '');
        }
        else {
            return o.id !== (selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.id) && o.id.includes(selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.id);
        }
    });
    const selectedModifiers = options.filter((o) => {
        var _a, _b;
        if (isYumEcomm) {
            return !SizeKeys.has((_a = o.optionTypeCode) !== null && _a !== void 0 ? _a : '') && !NonSizeKeys.has((_b = o.optionTypeCode) !== null && _b !== void 0 ? _b : '');
        }
        else {
            return o.type === 'MODIFIER' && o.id !== (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.id);
        }
    });
    const selectedOptionInput = {
        id: (_a = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.id) !== null && _a !== void 0 ? _a : '',
        modifiers: [],
        name: (_b = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.name) !== null && _b !== void 0 ? _b : '',
        type: (_c = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.type) !== null && _c !== void 0 ? _c : '',
    };
    let sizeModifiers = [];
    if (!isYumEcomm) {
        sizeModifiers = !!selectedOption ? [selectedOptionInput] : [];
    }
    const selectedSizeInput = {
        id: (_d = selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.id) !== null && _d !== void 0 ? _d : '',
        modifiers: sizeModifiers,
        name: (_e = selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.name) !== null && _e !== void 0 ? _e : '',
        type: (_f = selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.type) !== null && _f !== void 0 ? _f : '',
    };
    let modifiers = selectedModifiers.reduce((acc, modifier) => {
        const { id: mId, name: mName, quantity: mQuantity = 1, slotCode = '', type: mType, weightCode = '' } = modifier;
        if (mId !== (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.id)) {
            acc.push({
                id: mId,
                modifiers: [],
                name: mName,
                quantity: mQuantity,
                slotCode,
                type: mType,
                weightCode,
            });
        }
        return acc;
    }, []);
    if (!isYumEcomm) {
        modifiers = [selectedSizeInput, ...modifiers];
    }
    const variantCode = !!selectedOption ? (_g = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.variantCode) !== null && _g !== void 0 ? _g : '' : (_h = selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.variantCode) !== null && _h !== void 0 ? _h : '';
    return { modifiers, variantCode };
};
export default reduceProductSelectedOptions;
