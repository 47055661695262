// This file declares all interfaces for communicating with the PHDAPI backend
// using the Client Core API slices.
export const EmptyCartModifierInput = {
    id: '',
    modifiers: [],
    quantity: 1,
    type: 'PRODUCT',
    variantCode: '',
};
export var StepStatus;
(function (StepStatus) {
    StepStatus["STEP_COMPLETE"] = "STEP_COMPLETE";
    StepStatus["STEP_IN_PROGRESS"] = "STEP_IN_PROGRESS";
    StepStatus["STEP_PENDING"] = "STEP_PENDING";
})(StepStatus || (StepStatus = {}));
