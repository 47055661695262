import { gql } from 'graphql-request';
import AvailabilityFragment from '../fragments/availability';
import ModifierItemFragment from '../fragments/modifierItem';
import NutritionFragment from '../fragments/nutrition';
import RulesFragment from '../fragments/rules';
const GET_DEAL_BUILDER = gql `
    query getDealBuilder($storeID: String!, $itemID: ID!) {
        menu: store(storeID: $storeID) {
            deal: menu {
                item(itemID: $itemID) {
                    ...ItemFragment
                    combinableDiscount
                    legalText
                    outOfStock
                    sodiumWarning
                    type
                    availability {
                        ...AvailabilityFragment
                    }
                    steps: modifiers {
                        allergenDisclaimer
                        ...ItemFragment
                        ...RulesFragment
                        customizationsIncluded
                        recipes: modifiers {
                            ...ModifierItemFragment
                            customizationsIncluded
                            isCYO
                        }
                    }
                }
            }
        }
    }
    fragment ItemFragment on Item {
        allergenDisclaimer
        categoryPageImageURL
        defaultSelectedOption
        description
        displayOrder: priority
        featuredProductImageURL
        glutenFree
        hidden
        id
        imageURL
        internalName
        isCYO
        isPanCrust
        maxAllowed
        menuHubImageURL
        name
        outOfStock
        portion
        price
        required
        selected
        sodiumWarning
        splittable
        type
        universalMobileImageURL
        nutrition {
            ...NutritionFragment
        }
    }
    ${AvailabilityFragment}
    ${ModifierItemFragment}
    ${RulesFragment}
    ${NutritionFragment}
`;
export default GET_DEAL_BUILDER;
