import { gql } from 'graphql-request';
const ImageFragment = gql `
    fragment ImageFragment on ImageV3 {
        desktop
        mobile
        altText
        title
    }
`;
export default ImageFragment;
