import { ItemTypes } from '../types';
import { isDip, productType } from './menuUtilities';
import { transformYumUpsellDipToSingleDip } from './transformYumUpsellDipToSingleDip';
import transformYumOptionTypesToModifierArray from './transformYumOptionTypesToModifierArray';
import transformYumProduct from './transformYumProduct';
import transformYumProductPizzasAndMelts from './transformYumProductPizzasAndMelts';
import transformYumProductWings from './transformYumProductWings';
import transformYumSlotsToModifierArray from './transformYumSlotsToModifierArray';
import { filterDealLoversVariants, filterVariantsSlotsModifiersByAccessPointWeb, removeBundleOnlyVariants, } from './filterUtilities';
const transformYumUpsellProducts = (upsellProducts, yumMenu, isPreviewMode) => {
    const { modifiers, optionTypes, optionValues, slots, upsells: yumMenuUpsells } = yumMenu;
    const { products: filteredUpsellProducts, slots: filteredSlots, modifiers: filteredModifiers, } = filterVariantsSlotsModifiersByAccessPointWeb(upsellProducts, slots, modifiers, isPreviewMode);
    return filteredUpsellProducts.reduce((acc, product) => {
        const productNoBundleVariants = removeBundleOnlyVariants(product.variants);
        const filteredDealLoverVariants = filterDealLoversVariants(productNoBundleVariants, false);
        const modifiedProduct = Object.assign(Object.assign({}, product), { variants: filteredDealLoverVariants });
        const allOptions = transformYumOptionTypesToModifierArray(optionTypes, optionValues);
        const allSlots = transformYumSlotsToModifierArray(filteredSlots, filteredModifiers);
        const type = productType(modifiedProduct.options);
        if (filteredDealLoverVariants.length) {
            switch (type) {
                case ItemTypes.PIZZA:
                    acc = [...acc, transformYumProductPizzasAndMelts(modifiedProduct, allOptions, allSlots, true)];
                    break;
                case ItemTypes.MELT:
                    acc = [...acc, transformYumProductPizzasAndMelts(modifiedProduct, allOptions, allSlots, true)];
                    break;
                case ItemTypes.WINGS:
                    acc = [...acc, transformYumProductWings(modifiedProduct, allOptions, allSlots, true)];
                    break;
                default:
                    const productToTransform = isDip(modifiedProduct.productCode)
                        ? transformYumUpsellDipToSingleDip(modifiedProduct, yumMenuUpsells, yumMenu)
                        : modifiedProduct;
                    acc = [...acc, transformYumProduct(productToTransform, allOptions, allSlots, true)];
                    break;
            }
        }
        return acc;
    }, []);
};
export default transformYumUpsellProducts;
