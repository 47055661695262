export const transformYumOptionValues = (optionValues) => {
    const transformedValues = optionValues.map((value, index) => {
        const { blackout: outOfStock = false, description, images, metafields, name, optionValueCode, privateMetafields, } = value;
        return {
            description,
            displayOrder: index,
            id: optionValueCode,
            images,
            metafields,
            name,
            outOfStock,
            privateMetafields,
            type: 'MODIFIER',
        };
    });
    return transformedValues;
};
export default transformYumOptionValues;
