import { gql } from 'graphql-request';
import NutritionFragment from '../fragments/nutrition';
import ProductFragment from '../fragments/product';
const GET_PRODUCT = gql `
    query getProduct($storeID: String!, $itemID: ID!) {
        store(storeID: $storeID) {
            menu {
                item(itemID: $itemID) {
                    ...ProductFragment
                    isCYO
                    modifiers {
                        displayOrder: priority
                        id
                        name
                        nutrition {
                            ...NutritionFragment
                        }
                        type
                        modifiers {
                            ...ProductFragment
                            modifiers {
                                displayOrder: priority
                                id
                                name
                                type
                                ...ProductFragment
                                nutrition {
                                    ...NutritionFragment
                                }
                            }
                            nutrition {
                                ...NutritionFragment
                            }
                        }
                    }
                    nutrition {
                        ...NutritionFragment
                    }
                    subcategories {
                        subcategoryID
                        subcategoryName
                    }
                }
            }
        }
    }
    ${NutritionFragment}
    ${ProductFragment}
`;
export default GET_PRODUCT;
