export const transformWingBuilderToCartLineItemUpdate = (lineItemId, state) => {
    var _a;
    const { quantity, selectedDips, selectedSauce } = state;
    const dips = selectedDips.map((dip) => ({
        modifierCode: dip.id,
        modifierWeightCode: 'NONE',
        slotCode: dip.slotCode || '',
    }));
    const selectedModifiers = [...dips];
    const update = {
        products: [
            {
                lineItemId,
                quantity,
                selectedModifiers,
                variantCode: (_a = selectedSauce === null || selectedSauce === void 0 ? void 0 : selectedSauce.variantCode) !== null && _a !== void 0 ? _a : '',
            },
        ],
        raiseOnConflict: true,
    };
    return update;
};
export default transformWingBuilderToCartLineItemUpdate;
