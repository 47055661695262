var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { axiosErrorHandler, baseHeaders } from './axiosBase';
import { Environments } from '../config';
const axiosGraphQLQuery = () => ({ data, headers = {}, method, params }, ReduxStore) => __awaiter(void 0, void 0, void 0, function* () {
    const state = ReduxStore.getState();
    const { coreConfig: { phdApiEnvironment, debugMode, authChannel }, } = state;
    const menuUrl = Environments[phdApiEnvironment].menuUrl;
    const menuKey = Environments[phdApiEnvironment].menuKey;
    const defaultHeaders = Object.assign({}, baseHeaders);
    try {
        if (debugMode) {
            console.log({
                data,
                headers: Object.assign(Object.assign({}, defaultHeaders), headers),
                method,
                params,
                url: `${menuUrl}?apikey=${menuKey}`,
            });
        }
        const result = yield axios({
            data,
            headers: Object.assign(Object.assign({}, defaultHeaders), headers),
            method,
            params,
            url: `${menuUrl}?apikey=${menuKey}`,
            withCredentials: authChannel === 'WEB2',
        });
        if (result.data.errors) {
            return { error: { data: result.data.errors, message: 'GraphQL error' } };
        }
        return { data: result.data };
    }
    catch (axiosError) {
        const error = axiosError;
        return axiosErrorHandler(error);
    }
});
export default axiosGraphQLQuery;
