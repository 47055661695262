import transformYumModifier from './transformYumModifier';
export const transformYumSlotsToModifierArray = (slots, yumModifiers) => {
    const transformedSlots = slots.map((slot, index) => {
        // get the metafields and private metafields
        const { metafields, privateMetafields } = slot;
        // get all of the modifierCodes for the modifiers on this slot
        const modifierCodes = slot.modifiers.map((modifier) => modifier.modifierCode);
        // get all of the weights for the modifiers on this slot
        const modifierWeights = new Map();
        slot.modifiers.forEach((modifier) => {
            modifierWeights.set(modifier.modifierCode, modifier.weights);
        });
        // filter out the full modifiers from the modifierCodes on this slot
        // and sort them in the same order as defined on the slot
        const slotModifiers = yumModifiers
            .filter((modifier) => modifierCodes.includes(modifier.modifierCode))
            .sort((a, b) => modifierCodes.indexOf(a.modifierCode) - modifierCodes.indexOf(b.modifierCode));
        // transform the Yum modifiers to CC modifiers and add in the slotCode and weights
        const modifiers = slotModifiers.map((yumModifier, displayOrder) => {
            var _a, _b;
            const modifier = transformYumModifier(yumModifier);
            return Object.assign(Object.assign({}, modifier), { displayOrder, nutritionInformation: (_a = yumModifier.nutritionInformation) !== null && _a !== void 0 ? _a : [], slotCode: slot.slotCode, weights: (_b = modifierWeights.get(modifier.id)) !== null && _b !== void 0 ? _b : [] });
        });
        const modifier = {
            displayOrder: index,
            id: slot.slotCode,
            metafields,
            modifiers,
            name: slot.name,
            privateMetafields,
            slotCode: slot.slotCode,
            type: 'GROUP',
        };
        // return the slot as a CC modifier group
        return modifier;
    });
    return transformedSlots;
};
export default transformYumSlotsToModifierArray;
