import { createSlice } from '@reduxjs/toolkit';
const initialState = {};
export const menuSlice = createSlice({
    initialState,
    name: 'menu',
    reducers: {
        setMenuHub: (state, action) => {
            state.menuHub = action.payload;
        },
    },
});
const { setMenuHub } = menuSlice.actions;
export const MenuActions = { setMenuHub };
export const MenuReducer = menuSlice.reducer;
