import { EmptyCartModifierInput, } from '../types';
import transformSelectedModifiers from './transformSelectedModifiers';
const transformCartItemUpdateToYumCartLineItemUpdate = (item) => {
    // get the cartItemId, modifiers, quantity, special instructions, and type from the incoming item
    const { cartItemId: lineItemId = '', modifiers = [], quantity = 1, specialInstructions, type } = item;
    // depending on the type, build CartLineItemUpdate that contains a bundle or a product
    switch (type) {
        case 'DISCOUNT':
            // if the item is a bundle, its modifiers contain all of the configured choices
            // map over them to create ConfiguredChoiceInput
            const configuredChoices = modifiers.map((modifier) => {
                var _a, _b;
                const { id: choiceID, modifiers: choiceModifiers, quantity: choiceQuantity = 1, specialInstructions: choiceSpecialInstructions, } = modifier;
                // the modifiers on the configured choice contains the configured product
                const productInput = (_a = choiceModifiers === null || choiceModifiers === void 0 ? void 0 : choiceModifiers[0]) !== null && _a !== void 0 ? _a : EmptyCartModifierInput;
                const selectedModifiers = transformSelectedModifiers(productInput.modifiers);
                return {
                    choiceID,
                    configuredProduct: {
                        productCode: productInput.id,
                        selectedModifiers,
                        variantCode: (_b = productInput.variantCode) !== null && _b !== void 0 ? _b : '',
                    },
                    quantity: choiceQuantity,
                    specialInstructions: choiceSpecialInstructions,
                };
            });
            // spread in all of the properties of the BundleLineItemUpdate
            const bundleLineItemUpdate = {
                configuredChoices,
                lineItemId,
                quantity,
                specialInstructions,
            };
            return {
                bundles: [bundleLineItemUpdate],
                raiseOnConflict: true,
            };
        default:
            // build a ProductLineItemUpdate
            // get the
            const { variantCode = '' } = item;
            const selectedModifiers = transformSelectedModifiers(modifiers);
            const productLineUpdate = {
                lineItemId,
                quantity,
                selectedModifiers,
                specialInstructions,
                variantCode,
            };
            return {
                products: [productLineUpdate],
                raiseOnConflict: true,
            };
    }
};
export default transformCartItemUpdateToYumCartLineItemUpdate;
