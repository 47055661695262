import { gql } from 'graphql-request';
import ItemFragment from './item';
import ModifierItemFragment from './modifierItem';
import NutritionFragment from './nutrition';
const CategoryItemWithNutritionFragment = gql `
    fragment CategoryItemWithNutritionFragment on Item {
        nutrition @include(if: $includeNutrition) {
            ...NutritionFragment
        }
        ...ItemFragment
        modifiers {
            displayOrder: priority
            id
            name
            type
            modifiers {
                nutrition @include(if: $includeNutrition) {
                    ...NutritionFragment
                }
                ...ModifierItemFragment
                modifiers {
                    nutrition @include(if: $includeNutrition) {
                        ...NutritionFragment
                    }
                    ...ModifierItemFragment
                }
            }
        }
    }
    ${ItemFragment}
    ${ModifierItemFragment}
    ${NutritionFragment}
`;
export default CategoryItemWithNutritionFragment;
