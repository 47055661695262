import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    authToken: '',
    favorites: null,
    isGuest: false,
    searchAddress: null,
    tokenType: 'pizzahut',
};
export const customerSlice = createSlice({
    initialState,
    name: 'customer',
    reducers: {
        resetState: () => {
            return initialState;
        },
        setAuthToken: (state, action) => {
            state.authToken = action.payload.authToken;
            state.refreshToken = action.payload.refreshToken;
            state.tokenType = action.payload.tokenType;
        },
        setFavorites: (state, action) => {
            state.favorites = action.payload;
        },
        setIsGuest: (state, action) => {
            state.isGuest = action.payload;
        },
        setSearchAddress: (state, action) => {
            state.searchAddress = action.payload;
        },
    },
});
const { resetState, setAuthToken, setFavorites, setIsGuest, setSearchAddress } = customerSlice.actions;
export const CustomerActions = {
    resetState,
    setAuthToken,
    setFavorites,
    setIsGuest,
    setSearchAddress,
};
export const CustomerReducer = customerSlice.reducer;
