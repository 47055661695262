import { gql } from 'graphql-request';
import LinkFragment from '../fragments/link';
import ImageFragment from '../fragments/image';
import PriceRequirementFragment from '../fragments/priceRequirementFragment';
const GET_FEATURED_PRODUCTS = gql `
    query getFeaturedProducts($environmentId: String!, $storeID: String!, $occasions: [OccasionV3!], $time: String!) {
        Homepage(envID: $environmentId, storeID: $storeID, occasions: $occasions, time: $time) {
            storeID
            heroes {
                id: name
                ctaLinkText
                title
                subtitle
                displayPrice
                badgeText
                image {
                    ...ImageFragment
                }
                link {
                    ...LinkFragment
                }
                price: requiresPrice {
                    ...PriceRequirementFragment
                }
            }
            sidekicks {
                id: name
                ctaLinkText
                title
                subtitle
                displayPrice
                badgeText
                imageSmall {
                    ...ImageFragment
                }
                imageLarge {
                    ...ImageFragment
                }
                link {
                    ...LinkFragment
                }
                price: requiresPrice {
                    ...PriceRequirementFragment
                }
            }
            dealsHeading {
                heading
                ctaLink: callToActionLink {
                    ...LinkFragment
                }
            }
        }
    }
    ${ImageFragment}
    ${LinkFragment}
    ${PriceRequirementFragment}
`;
export default GET_FEATURED_PRODUCTS;
