import computeNutrition from './computeNutrition';
import extractModifierIds from './extractModifierIds';
const transformYumCartItemToPizzaBuilder = (cartItem, pizzaBuilderData) => {
    var _a;
    const { modifiers = [] } = cartItem;
    // Get an object for all of the pizza builder modifiers indexed by id
    const modifiersById = extractModifierIds(pizzaBuilderData);
    const modifierIds = {};
    const pizzaBuilderCheeses = pizzaBuilderData.cheeses.flatMap((cheese) => cheese.modifiers);
    const pizzaBuilderSauces = pizzaBuilderData.sauces.flatMap((sauce) => sauce.modifiers);
    modifiers.forEach((modifier) => {
        var _a;
        modifierIds[modifier.id] = [];
        const { id: modifierId } = modifier;
        // if modifier is a cheese, because cheese doesn't have unique ids
        // we find the selected cheese by the modifier weight
        if (modifierId === 'slot_pizza_cheese') {
            const selectedCheese = pizzaBuilderCheeses.find((cheese) => {
                var _a;
                return (cheese === null || cheese === void 0 ? void 0 : cheese.weightCode) === ((_a = modifier.modifiers) === null || _a === void 0 ? void 0 : _a[0].weightCode);
            }) || modifiersById[modifierId];
            modifierIds[modifierId].push(selectedCheese);
        }
        else if (modifierId === 'slot_pizza_sauce') {
            const selectedSauce = pizzaBuilderSauces.find((sauce) => {
                var _a, _b;
                return ((sauce === null || sauce === void 0 ? void 0 : sauce.id) === ((_a = modifier.modifiers) === null || _a === void 0 ? void 0 : _a[0].id) &&
                    (sauce === null || sauce === void 0 ? void 0 : sauce.weightCode) === ((_b = modifier.modifiers) === null || _b === void 0 ? void 0 : _b[0].weightCode));
            }) || modifiersById[modifierId];
            modifierIds[modifierId].push(selectedSauce);
        }
        else if (modifiersById[modifierId]) {
            modifierIds[modifierId].push(modifiersById[modifierId]);
        }
        (_a = modifier.modifiers) === null || _a === void 0 ? void 0 : _a.forEach((modifierTwo) => {
            var _a;
            const id = modifierTwo.id;
            const matchIds = Object.keys(modifiersById).filter((storedId) => {
                return storedId === id;
            });
            if (!matchIds.length) {
                (_a = modifierTwo.modifiers) === null || _a === void 0 ? void 0 : _a.forEach((modifierThree) => {
                    const subModifierId = modifierThree.id;
                    modifierIds[modifierId].push(modifiersById[subModifierId]);
                });
            }
            matchIds.forEach((matchedId) => {
                modifierIds[modifierId].push(modifiersById[matchedId]);
            });
        });
    });
    const { ['slot_pizza_cheese']: selectedCheeses, ['slot_pizza_sauce']: selectedSauces, ['slot_pizza_toppings']: selectedToppings = [], } = modifierIds;
    const [selectedCheese] = selectedCheeses.filter((cheese) => !cheese.outOfStock);
    const [selectedSauce] = selectedSauces.filter((sauce) => !sauce.outOfStock);
    // get the selected size and crust
    const selectedSizeAndCrust = pizzaBuilderData.sizesAndCrusts.reduce((acc, size) => {
        var _a;
        const selectedCrust = (_a = size === null || size === void 0 ? void 0 : size.modifiers) === null || _a === void 0 ? void 0 : _a.find((modifier) => modifier.variantCode === cartItem.variantCode);
        if (selectedCrust) {
            acc.selectedSize = size;
            acc.selectedCrust = selectedCrust;
            return acc;
        }
        else {
            return acc;
        }
    }, { selectedSize: {}, selectedCrust: {} });
    const { cheeses, glutenFree, sizesAndCrusts, sauces, toppings } = pizzaBuilderData;
    const { selectedSize, selectedCrust } = selectedSizeAndCrust;
    const { price, quantity = 1, specialInstructions } = cartItem;
    return {
        builder: pizzaBuilderData,
        cheeses,
        glutenFree,
        isCartLoading: false,
        isPriceLoading: false,
        maxToppingsAllowed: (_a = selectedCrust === null || selectedCrust === void 0 ? void 0 : selectedCrust.maxAllowed) !== null && _a !== void 0 ? _a : null,
        nutrition: computeNutrition(selectedSize, selectedCrust, selectedCheese, selectedSauce, selectedToppings),
        price,
        quantity,
        sauces,
        selectedCheese: selectedCheese || null,
        selectedCrust,
        selectedSauce: selectedSauce || null,
        selectedSize,
        selectedToppings,
        sizesAndCrusts,
        specialInstructions: specialInstructions || '',
        toppings,
        type: 'PIZZA',
    };
};
export default transformYumCartItemToPizzaBuilder;
