import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQueryLoyaltyGateway from '../../utils/axiosBaseQueryLoyaltyGateway';
/**
 * Creates the base `loyaltyApi` slice.
 *
 * See the [RTK Query docs](https://redux-toolkit.js.org/rtk-query/api/createApi) for information on `createApi`.
 */
export const loyaltyApi = createApi({
    baseQuery: axiosBaseQueryLoyaltyGateway(),
    endpoints: () => ({}),
    reducerPath: 'rtkq/loyalty',
});
