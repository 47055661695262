const toCamel = (string) => {
    // if string includes an underscore, lowercase the whole string, otherwise, leave alone
    // handles edge cases like KEy_oNe, KEY_TWO, keY_tHrEe while preventing keyOne from becoming keyone
    const stringType = string.includes('_') ? string.toLowerCase() : string;
    return stringType.replace(/([-_][0-9a-z])/gi, ($1) => {
        return $1.toUpperCase().replace('-', '').replace('_', '');
    });
};
const isArray = (array) => {
    return Array.isArray(array);
};
const isObject = (object) => {
    return object === Object(object) && !isArray(object) && typeof object !== 'function';
};
const snakeToCamel = (object) => {
    if (isObject(object)) {
        const newObj = {};
        Object.keys(object).forEach((key) => {
            newObj[toCamel(key)] = snakeToCamel(object[key]);
        });
        return newObj;
    }
    else if (isArray(object)) {
        return object.map((item) => {
            return snakeToCamel(item);
        });
    }
    return object;
};
export default snakeToCamel;
