export var ImageSizeV3;
(function (ImageSizeV3) {
    ImageSizeV3["Large"] = "LARGE";
    ImageSizeV3["Medium"] = "MEDIUM";
    ImageSizeV3["Small"] = "SMALL";
    ImageSizeV3["UnknownSize"] = "UNKNOWN_SIZE";
})(ImageSizeV3 || (ImageSizeV3 = {}));
export var OccasionV3;
(function (OccasionV3) {
    OccasionV3["Carryout"] = "CARRYOUT";
    OccasionV3["Delivery"] = "DELIVERY";
    OccasionV3["UnknownOccasion"] = "UNKNOWN_OCCASION";
})(OccasionV3 || (OccasionV3 = {}));
export var OrderMethod;
(function (OrderMethod) {
    OrderMethod["Carryout"] = "CARRYOUT";
    OrderMethod["Delivery"] = "DELIVERY";
    OrderMethod["Unknown"] = "UNKNOWN";
})(OrderMethod || (OrderMethod = {}));
export var Portion;
(function (Portion) {
    /** extra portion */
    Portion["Extra"] = "EXTRA";
    /** light portion */
    Portion["Light"] = "LIGHT";
    /** no portion */
    Portion["None"] = "NONE";
    /** regular portion */
    Portion["Regular"] = "REGULAR";
})(Portion || (Portion = {}));
export var RuleAction;
(function (RuleAction) {
    /** Add - upon selection of the parent modifier, the actionItem must also be selected */
    RuleAction["Add"] = "ADD";
    /** Delete - upon selection of the parent modifier, the actionItem must also be deselected */
    RuleAction["Delete"] = "DELETE";
    /**
     * Include - upon selection of the parent modifier,
     * one of each actionItem with an INCLUDE action within the rules array
     * must be selected, per item type.
     * EX: the rules array contains 3 INCLUDE rules, 2 possessing an
     * actionItem of type SIZE and 1 possessing an actionItem of type MODIFIER.
     * In this case the user must select 1 of the 2 SIZE actionItems and
     * the 1 MODIFIER actionItem to satisfy the rules.
     */
    RuleAction["Include"] = "INCLUDE";
    /** Pricing - upon selection of the parent modifier, the unit price of actionItem will be applied */
    RuleAction["Pricing"] = "PRICING";
    /** Restrict - upon selection of the parent modifier, the actionItem must not be able to be selected */
    RuleAction["Restrict"] = "RESTRICT";
    /** default unknown value */
    RuleAction["Unknown"] = "UNKNOWN";
})(RuleAction || (RuleAction = {}));
