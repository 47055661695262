import { gql } from 'graphql-request';
const ProductFragment = gql `
    fragment ProductFragment on Item {
        allergenDisclaimer
        categoryPageImageURL
        defaultSelectedOption
        description
        displayOrder: priority
        featuredProductImageURL
        glutenFree
        hidden
        id
        imageURL
        internalName
        isPanCrust
        maxAllowed
        menuHubImageURL
        name
        outOfStock
        portion
        price
        required
        selected
        sodiumWarning
        splittable
        type
        universalMobileImageURL
    }
`;
export default ProductFragment;
