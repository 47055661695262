const transformFeaturedProducts = (response) => {
    var _a;
    const homepageData = response.data.Homepage;
    const heroData = homepageData.heroes[0];
    const dealsHeading = homepageData.dealsHeading;
    const hero = Object.assign(Object.assign({}, heroData), { displayPrice: Boolean(heroData.displayPrice), link: Object.assign(Object.assign({}, heroData.link), { external: Boolean((_a = heroData.link) === null || _a === void 0 ? void 0 : _a.external) }) });
    const sidekicks = homepageData.sidekicks.map((sidekick) => ({
        badgeText: sidekick.badgeText,
        ctaLinkText: sidekick.ctaLinkText,
        displayPrice: Boolean(sidekick.displayPrice),
        id: sidekick.id,
        imageLarge: sidekick.imageLarge,
        imageSmall: sidekick.imageSmall,
        link: Object.assign(Object.assign({}, sidekick.link), { external: Boolean(sidekick.link.external) }),
        price: sidekick.price,
        subtitle: sidekick.subtitle,
        title: sidekick.title,
    }));
    return {
        dealsHeading,
        hero,
        sidekicks,
    };
};
export default transformFeaturedProducts;
