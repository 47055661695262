import { Placement, Portion } from '../types';
import transformPlacementPortionToWeightCode from './transformPlacementPortionToWeightCode';
export const transformPizzaBuilderToCartLineItemUpdate = (lineItemId, state) => {
    var _a;
    const { quantity, selectedCheese, selectedCrust, selectedSauce, selectedToppings, specialInstructions } = state;
    const cheese = {
        modifierCode: 'modifier_pizza_cheese' || '',
        modifierWeightCode: (selectedCheese === null || selectedCheese === void 0 ? void 0 : selectedCheese.weightCode) || '',
        slotCode: (selectedCheese === null || selectedCheese === void 0 ? void 0 : selectedCheese.slotCode) || '',
    };
    const sauce = {
        modifierCode: (selectedSauce === null || selectedSauce === void 0 ? void 0 : selectedSauce.id) || '',
        modifierWeightCode: (selectedSauce === null || selectedSauce === void 0 ? void 0 : selectedSauce.weightCode) || '',
        slotCode: (selectedSauce === null || selectedSauce === void 0 ? void 0 : selectedSauce.slotCode) || '',
    };
    const toppings = selectedToppings.map((topping) => {
        var _a, _b, _c;
        return ({
            modifierCode: topping.id,
            modifierWeightCode: transformPlacementPortionToWeightCode((_a = topping.placement) !== null && _a !== void 0 ? _a : Placement.WHOLE, (_b = topping.portion) !== null && _b !== void 0 ? _b : Portion.REGULAR, (_c = topping.slotCode) !== null && _c !== void 0 ? _c : ''),
            slotCode: topping.slotCode || '',
        });
    });
    // if selectedSauce is null, don't include the SelectedSlotInput in selectedModifiers
    const selectedModifiers = selectedSauce ? [cheese, sauce, ...toppings] : [cheese, ...toppings];
    const update = {
        products: [
            {
                lineItemId,
                quantity,
                selectedModifiers,
                specialInstructions,
                variantCode: (_a = selectedCrust === null || selectedCrust === void 0 ? void 0 : selectedCrust.variantCode) !== null && _a !== void 0 ? _a : '',
            },
        ],
        raiseOnConflict: true,
    };
    return update;
};
export default transformPizzaBuilderToCartLineItemUpdate;
