var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AkamaiHeader, Environments } from '../config';
import { baseHeaders } from './axiosBase';
import { axiosQuery } from './axiosQuery';
const axiosBaseQueryPay = () => ({ data, headers = {}, method, params, url, isYum = false }, ReduxStore) => __awaiter(void 0, void 0, void 0, function* () {
    const state = ReduxStore.getState();
    const { coreConfig: { authChannel, axiosTimeout, debugMode, phdApiEnvironment, salesChannel, xServerEnv, clientId }, customer: { authToken, refreshToken, tokenType }, } = state;
    const urlRoute = isYum ? 'baseUrlV3' : 'payUrl';
    const keyRoute = isYum ? 'keyV3' : 'keyV1';
    const payUrl = Environments[phdApiEnvironment][urlRoute];
    const key = Environments[phdApiEnvironment][authChannel][keyRoute];
    const defaultHeaders = Object.assign(Object.assign({}, baseHeaders), { ['x-server-env']: xServerEnv });
    if (authChannel === 'PizzaHut') {
        defaultHeaders['akaatornr'] = AkamaiHeader[phdApiEnvironment];
    }
    if (authToken && tokenType === 'pizzahut') {
        defaultHeaders['auth-token'] = authToken;
    }
    if (authToken && tokenType === 'yum') {
        defaultHeaders['Authorization'] = `Bearer ${authToken}`;
    }
    if (isYum) {
        params = Object.assign(Object.assign({}, params), { channel: salesChannel });
    }
    params = Object.assign(Object.assign({}, params), { key });
    const axiosConfig = {
        data,
        headers: Object.assign(Object.assign({}, defaultHeaders), headers),
        method,
        params,
        timeout: axiosTimeout,
        url: payUrl + url,
        withCredentials: authChannel === 'WEB2',
    };
    if (debugMode) {
        console.log({
            data,
            headers: Object.assign(Object.assign({}, defaultHeaders), headers),
            method,
            params,
            url: payUrl + url,
        });
    }
    const result = yield axiosQuery({
        axiosConfig,
        debugMode,
        api: ReduxStore,
        clientId,
        refreshToken,
        tokenType,
    });
    return result;
});
export default axiosBaseQueryPay;
