import { RuleType } from '../types';
import { parseGqlId } from './stringUtilities';
export const applyPizzaBuilderRules = (crustId, sizeId, builder) => {
    var _a;
    // Parse the global ID from the incoming crust and size IDs
    const crust = parseGqlId(crustId);
    const size = parseGqlId(sizeId);
    // ADD, DELETE and PRICING rules are not used
    // Get the RESTRICT rules for the given crust and size
    const restrictRules = ((_a = builder.rules[RuleType.RESTRICT]) === null || _a === void 0 ? void 0 : _a.filter((rule) => rule.crustId === crust && rule.sizeId === size)) || [];
    // Get all of the modifier IDs that are affected by a RESTRICT rule
    // Create a Set that will be used for filtering out modifiers from the builder
    const restrictedIds = new Set(restrictRules.map((rule) => rule.actionItem.id));
    // This common reducer can be used to filter restrictions for all modifier groups
    const reducer = (modifierAcc, modifier) => {
        var _a;
        // we do not want to overwrite the modifiers in the pizza builder
        const copy = Object.assign({}, modifier);
        copy.modifiers = ((_a = modifier.modifiers) === null || _a === void 0 ? void 0 : _a.filter((m) => !restrictedIds.has(m.id))) || [];
        if (copy.modifiers.length > 0) {
            modifierAcc.push(copy);
        }
        return modifierAcc;
    };
    // Filter all of the modifier groups, removing any that are restricted
    const cheeses = builder.cheeses.reduce(reducer, []);
    const sauces = builder.sauces.reduce(reducer, []);
    const toppings = builder.toppings.reduce(reducer, []);
    return {
        cheeses,
        sauces,
        toppings,
    };
};
export default applyPizzaBuilderRules;
