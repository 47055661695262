var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { menuApi } from './MenuApi';
import { Categories, defaultMenuHubConfig, } from '../../types';
import GET_DEALS from './queries/getDeals';
import GET_MENU_HUB from './queries/getMenuHub';
import { applyMenuHubConfig, transformMenuCategories, transformMenuDeals } from '../../utils';
import { storeApiInfo } from '../store/StoreApiInfo';
import { menuApiHub } from './MenuApiHub';
const requestCodes = (marketingCodes, options, dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    // 1. Map over the incoming marketing codes
    // 2. Create a promise that dispatches the getDiscountCode endpoint for each code
    // 3. return the resulting CEC codes
    const requests = marketingCodes.map((marketingCode) => dispatch(storeApiInfo.endpoints.getDiscountCode.initiate({
        marketingCode,
        storeNumber: options.storeNumber,
    })));
    const results = yield Promise.all(requests);
    const codes = results.map(({ data }) => data);
    return codes;
});
export const menuApiConsolidatedGqlHub = menuApi.injectEndpoints({
    endpoints: (build) => ({
        getConsolidatedGqlMenu: build.query({
            queryFn: (options, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                // Get the MenuHubConfig from redux
                let { coreConfig: { enableCoreConfig, menuConfig: reduxMenuConfig }, } = getState();
                // request deals and menu categories from the Consoliated GQL or Content Gateway
                // let response: any;
                // Marketing codes are used to identify Featuired Deals and Featured Products
                // These marketing codes are used to request the corresponding CEC code
                // The CEC codes can vary from store to store
                // Use the getDiscountCode endpoint to request the CEC codes for the marketing codes
                let menuConfig = defaultMenuHubConfig;
                if (enableCoreConfig) {
                    const [dealCodes, featuredCodes] = yield Promise.all([
                        requestCodes(reduxMenuConfig.featuredCategoryProducts[Categories.DEALS].products, options, dispatch),
                        requestCodes(reduxMenuConfig.featuredProducts, options, dispatch),
                    ]);
                    // We need to rebuild the MenuHubConfig. It will be used to configure the menu categories
                    // We cannot just replace what is in the Redux state
                    // Doing so would break Redux rules, and the object is read only, we would get TypeError
                    // Replace the marketing codes with the newly gotten dealCodes and featuredCodes
                    menuConfig = Object.assign(Object.assign({}, reduxMenuConfig), { featuredCategoryProducts: Object.assign(Object.assign({}, reduxMenuConfig.featuredCategoryProducts), { [Categories.DEALS]: Object.assign(Object.assign({}, reduxMenuConfig.featuredCategoryProducts[Categories.DEALS]), { products: dealCodes }) }), featuredProducts: featuredCodes });
                }
                const response = yield fetchWithBQ({
                    data: [
                        {
                            operationName: 'getDeals',
                            query: GET_DEALS,
                            variables: {
                                occasion: options.occasion,
                                storeID: `stores/${options.storeNumber}`,
                            },
                        },
                        {
                            operationName: 'getMenuHub',
                            query: GET_MENU_HUB,
                            variables: {
                                envID: options.environmentId || 'prod',
                                occasions: [options.occasion],
                                storeID: options.storeNumber,
                            },
                        },
                    ],
                    method: 'post',
                });
                const [menuHubDealQueryResponse, menuHubCategoryQueryResponse] = response.data;
                // Extract the deals and menu categories from the network response
                const dealsGql = menuHubDealQueryResponse.data;
                const menuHub = menuHubCategoryQueryResponse.data;
                // Transform the raw GQL responses to Client Core types/interfaces
                const deals = transformMenuDeals(dealsGql);
                const categories = transformMenuCategories(menuHub);
                // Add deals to the categories
                categories.push(deals);
                // Apply the MenuHubConfig to the menu categories
                const hub = applyMenuHubConfig(categories, menuConfig);
                dispatch(menuApiHub.endpoints.getMenuHubNutrition.initiate(options));
                return response.data
                    ? {
                        data: {
                            categories: hub.categories,
                            featuredProducts: hub.featuredProducts,
                        },
                    }
                    : { error: response.error };
            }),
        }),
    }),
});
export const { useGetConsolidatedGqlMenuQuery } = menuApiConsolidatedGqlHub;
