import { gql } from 'graphql-request';
import AvailabilityFragment from '../fragments/availability';
import CategoryItemFragment from '../fragments/categoryItem';
const GET_MENU_HUB = gql `
    query getMenuHub($envID: String!, $storeID: String!, $occasions: [OccasionV3!]) {
        menu: Homepage(envID: $envID, storeID: $storeID, occasions: $occasions) {
            categories {
                id: name
                name: displayName
                products {
                    displayOrder
                    name: displayName
                    qoId
                    item {
                        availability {
                            ...AvailabilityFragment
                        }
                        ...CategoryItemFragment
                        isCYO
                        subcategories {
                            subcategoryID
                            subcategoryName
                        }
                    }
                }
            }
        }
    }
    ${AvailabilityFragment}
    ${CategoryItemFragment}
`;
export default GET_MENU_HUB;
