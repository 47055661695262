import transformPlacementPortionToQuantities from './transformPlacementPortionToQuantities';
export const transformProductModifiersToPhdCartModifier = (productModifiers, type) => {
    var _a;
    const transformedModifiers = (_a = productModifiers === null || productModifiers === void 0 ? void 0 : productModifiers.reduce((modifierAcc, modifier) => {
        var _a;
        const { id: modifierId, modifiers: sizeModifiers, name: modifierName = '', placement, portion, type: modifierType, } = modifier;
        const hasModifiers = (sizeModifiers === null || sizeModifiers === void 0 ? void 0 : sizeModifiers.length) > 0;
        // compute the quantities for the modifiers
        // default to [1], if we have placement and portion, then compute based on their values
        let quantities = [1];
        if (!!placement && !!portion) {
            quantities = transformPlacementPortionToQuantities(placement, portion);
        }
        // if we are a melt or a pizza, we need to process the crust off the size modifier
        switch (type) {
            case 'MELT':
            case 'PIZZA': {
                const crust = (_a = sizeModifiers === null || sizeModifiers === void 0 ? void 0 : sizeModifiers.map((size) => {
                    var _a;
                    return {
                        display_name: (_a = size.name) !== null && _a !== void 0 ? _a : '',
                        modifiers: [],
                        name: size.id,
                        quantities,
                        type: size.type,
                    };
                })) !== null && _a !== void 0 ? _a : [];
                modifierAcc.push({
                    display_name: modifierName,
                    modifiers: crust,
                    name: modifierId,
                    quantities,
                    type: modifierType,
                });
                break;
            }
            default:
                modifierAcc.push({
                    display_name: modifierName,
                    modifiers: hasModifiers
                        ? transformProductModifiersToPhdCartModifier(sizeModifiers, modifierType)
                        : [],
                    name: modifierId,
                    quantities,
                    type: modifierType,
                });
                break;
        }
        return modifierAcc;
    }, [])) !== null && _a !== void 0 ? _a : [];
    return transformedModifiers;
};
export const transformCartItemInputToPhdCartItem = (item) => {
    // get the modifiers, name quantity, special instructions, and type from the incoming item
    const { id, modifiers: stepModifiers = [], name = '', quantity, specialInstructions, type } = item;
    switch (type) {
        case 'DISCOUNT': {
            // process the modifiers on the item
            const steps = stepModifiers.reduce((stepAcc, step) => {
                var _a;
                const { id: stepId, modifiers: recipes, name: stepName = '', type: stepType } = step;
                const recipe = (_a = recipes === null || recipes === void 0 ? void 0 : recipes.reduce((recipeAcc, stepRecipe) => {
                    const { id: recipeId, modifiers: recipeModifiers, name: recipeName = '', quantity: recipeQuantity, specialInstructions: recipeSpecialInstructions, type: recipeType, } = stepRecipe;
                    const productModifier = transformProductModifiersToPhdCartModifier(recipeModifiers, recipeType);
                    const recipeModifier = Object.assign(Object.assign({ display_name: recipeName, modifiers: productModifier, name: recipeId, quantity: recipeQuantity }, (recipeSpecialInstructions !== undefined && {
                        special_instructions: recipeSpecialInstructions,
                    })), { type: recipeType, verified_age_gate: true });
                    recipeAcc.push(recipeModifier);
                    return recipeAcc;
                }, [])) !== null && _a !== void 0 ? _a : [];
                const stepModifier = {
                    display_name: stepName,
                    modifiers: recipe,
                    name: stepId,
                    type: stepType,
                };
                stepAcc.push(stepModifier);
                return stepAcc;
            }, []);
            return Object.assign(Object.assign({ display_name: name, modifiers: steps, name: id, quantity }, (specialInstructions !== undefined && { special_instructions: specialInstructions })), { type, verified_age_gate: true });
        }
        default:
            const modifiers = transformProductModifiersToPhdCartModifier(stepModifiers, type);
            return Object.assign(Object.assign({ display_name: name, modifiers, name: id, quantity }, (specialInstructions !== undefined && { special_instructions: specialInstructions })), { type, verified_age_gate: true });
    }
};
export default transformCartItemInputToPhdCartItem;
