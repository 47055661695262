import { gql } from 'graphql-request';
const RulesFragment = gql `
    fragment RulesFragment on Item {
        rules {
            action
            actionItem {
                type
                name
                id
                price
            }
        }
    }
`;
export default RulesFragment;
