import { parseGqlId } from './stringUtilities';
import transformGqlItem from './transformGqlItem';
const transformProductRules = (data, rules) => {
    var _a;
    // deep clone to preserve nested values
    const product = JSON.parse(JSON.stringify(data));
    const ruleIds = rules.filter((rule) => rule.action === 'INCLUDE').map((rule) => rule.actionItem.id);
    const modifiers = (_a = product.modifiers) === null || _a === void 0 ? void 0 : _a.map((modifier) => {
        var _a;
        if (modifier.name === 'Sizes' && ruleIds.length) {
            const ruleModifiers = (_a = modifier.modifiers) === null || _a === void 0 ? void 0 : _a.filter((mod) => ruleIds.includes(parseGqlId(mod.id)));
            const subModifier = ruleModifiers === null || ruleModifiers === void 0 ? void 0 : ruleModifiers.map((ruleModifier) => {
                var _a;
                ruleModifier.modifiers = (_a = ruleModifier.modifiers) === null || _a === void 0 ? void 0 : _a.filter((m) => ruleIds.includes(parseGqlId(m.id)));
                return ruleModifier;
            });
            return Object.assign(Object.assign({}, modifier), { modifiers: subModifier });
        }
        else {
            return modifier;
        }
    });
    product.modifiers = modifiers;
    const item = transformGqlItem(product);
    return item;
};
export const applyProductRules = (data, rules) => {
    return transformProductRules(data.data.store.menu.item, rules);
};
export const applyProductRulesById = (data, rules) => {
    return transformProductRules(data, rules);
};
export default applyProductRules;
