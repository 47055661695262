var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { menuApi } from './MenuApi';
import { menuApiCategory } from './MenuApiCategory';
export const menuApiLineup = menuApi.injectEndpoints({
    endpoints: (build) => ({
        getLineup: build.query({
            queryFn: (args, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
                const lineup = yield dispatch(menuApiCategory.endpoints.getProductsByCategory.initiate(args));
                const response = lineup.data ? { data: lineup.data } : { error: lineup.error };
                return response;
            }),
        }),
    }),
});
export const { useGetLineupQuery } = menuApiLineup;
