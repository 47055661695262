import { createApi, retry } from '@reduxjs/toolkit/query/react';
import axiosBaseQueryYumAuth from '../../utils/axiosBaseQueryYumAuth';
/**
 * Creates the base `yumApi` slice.
 *
 * See the [RTK Query docs](https://redux-toolkit.js.org/rtk-query/api/createApi) for information on `createApi`.
 */
// to do: Investigate the type issue around this retry function and updating to axios 1.7.7
// updating to axios v1.7.7 causes a type issue here and it's unclear why - may need to update redux toolkit
export const yumApi = createApi({
    baseQuery: retry(axiosBaseQueryYumAuth(), { maxRetries: 2 }),
    endpoints: () => ({}),
    reducerPath: 'rtkq/customer/yum',
    tagTypes: ['Customer'],
});
