import { parseGqlId } from './stringUtilities';
import { sortByDisplayOrder } from './sortUtilities';
import transformProducts from './transformProducts';
export const transformMenuCategories = (hub) => {
    var _a, _b;
    const initalCategories = [];
    const categories = (_b = (_a = hub === null || hub === void 0 ? void 0 : hub.menu) === null || _a === void 0 ? void 0 : _a.categories) === null || _b === void 0 ? void 0 : _b.reduce((acc, gqlCategory) => {
        let { displayOrder, id: gqlId, name } = gqlCategory;
        const id = parseGqlId(gqlId);
        const transformedProducts = transformProducts(gqlCategory.products);
        const sortedProducts = transformedProducts.sort(sortByDisplayOrder);
        acc.push({
            displayOrder,
            featuredProducts: [],
            id,
            name,
            products: sortedProducts,
        });
        return acc;
    }, initalCategories);
    return categories;
};
export default transformMenuCategories;
