import { LineupNameOverridesMap, SlotKeys, } from '../types';
import productDescriptionYum from './productDescriptionYum';
import transformModifierWeights from './transformModifierWeights';
const getProductType = (product) => {
    if (!!!product) {
        return 'PRODUCT';
    }
    const { productCode: id, selectedModifiers = [] } = product;
    const slots = selectedModifiers.map((modifier) => modifier.slotCode);
    const checkType = (string) => slots.some((slot) => slot.includes(string));
    if (checkType('slot_pizza_melt')) {
        return 'MELT';
    }
    else if (checkType('slot_pizza') && !checkType('slot_pizza_melt')) {
        return 'PIZZA';
    }
    else if (id === 'CLSS010WS|BASE831TD' || id === 'CLSS010WS|BASE515BO') {
        return 'WINGS';
    }
    else {
        return 'PRODUCT';
    }
};
const transformConfiguredProduct = (configuredProduct, menu) => {
    var _a, _b;
    if (!!!configuredProduct) {
        return {
            id: '',
            modifiers: [],
            name: '',
            price: 0,
            type: 'PRODUCT',
        };
    }
    const { name, productCode, selectedModifiers = [], variantCode } = configuredProduct;
    const { products } = menu;
    const productName = (_b = (_a = products.find((p) => p.productCode === productCode)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : name;
    // determine the product type based on the selectedModifiers slot codes
    const slotCodes = new Set(selectedModifiers.map((modifier) => modifier.slotCode));
    const isMelt = slotCodes.has(SlotKeys.meltCheese);
    const isPizza = slotCodes.has(SlotKeys.pizzaCheese);
    const type = isMelt ? 'MELT' : isPizza ? 'PIZZA' : 'PRODUCT';
    const productModifiers = selectedModifiers.map((slot) => {
        const { modifiers, name: slotName, slotCode } = slot;
        const slotModifiers = modifiers.reduce((acc, modifier) => {
            var _a;
            const { modifierCode, name: modifierName, weight: { modifierWeightCode: weightCode, name: weightName, price, value }, } = modifier;
            const { placement, portion } = transformModifierWeights(weightCode);
            acc.push(Object.assign(Object.assign({ id: modifierCode, modifiers: [], name: modifierName, placement,
                portion, price: (_a = price === null || price === void 0 ? void 0 : price.amount) !== null && _a !== void 0 ? _a : 0, type: 'MODIFIER' }, (value !== undefined && { value })), { weightCode,
                weightName }));
            return acc;
        }, []);
        return {
            id: slotCode,
            modifiers: slotModifiers,
            name: slotName,
            price: 0,
            slotCode,
            type: 'GROUP',
        };
    });
    const product = {
        id: productCode,
        modifiers: productModifiers,
        name: productName,
        price: 0,
        quantity: 1,
        type,
        variantCode,
    };
    return product;
};
const transformYumCartLineItemToCartItem = (lineItem, menu, lineupVariants, orderabilityConflicts = []) => {
    var _a, _b, _c, _d;
    const { configuredBundle, configuredProduct, lineItemId, name, price, quantity: lienItemQuantity, specialInstructions, } = lineItem;
    // if the line item is a bundle, we need to transform the configured choices into modifiers
    if (!!configuredBundle) {
        const { bundleCode, configuredChoices } = configuredBundle;
        const { bundles } = menu;
        const bundleName = (_b = (_a = bundles.find((b) => b.bundleCode === bundleCode)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : name;
        const modifiers = configuredChoices.map((choice) => {
            var _a;
            const { choiceCode, configuredProduct: choiceConfiguredProduct, quantity: choiceQuantity, specialInstructions: choiceSpecialInstructions, } = choice;
            const product = transformConfiguredProduct(choiceConfiguredProduct, menu);
            const type = getProductType(choiceConfiguredProduct);
            const description = orderabilityConflicts.findIndex((conflict) => conflict.lineItemId === lineItemId) < 0
                ? productDescriptionYum(choiceConfiguredProduct, type)
                : '';
            const isLineup = lineupVariants.includes((_a = product.variantCode) !== null && _a !== void 0 ? _a : '') ? true : false;
            return Object.assign(Object.assign({ id: choiceCode, isLineup, modifiers: [Object.assign(Object.assign({}, product), { description })], name: choiceCode, price: 0, quantity: choiceQuantity }, (choiceSpecialInstructions !== undefined && { specialInstructions: choiceSpecialInstructions })), { type: 'STEP' });
        });
        const isLineup = modifiers.some((modifier) => modifier.isLineup) ? true : false;
        return Object.assign(Object.assign({ cartItemId: lineItemId, id: bundleCode, isLineup,
            modifiers, name: bundleName, price: (_c = price.amount) !== null && _c !== void 0 ? _c : 0, quantity: lienItemQuantity }, (specialInstructions !== undefined && { specialInstructions })), { type: 'DISCOUNT' });
    }
    // otherwise convert the line item to a cart item with a single product
    const product = transformConfiguredProduct(configuredProduct, menu);
    const { modifiers: menuModifiers } = menu;
    const { id, modifiers = [], name: productName, variantCode } = product;
    const type = getProductType(configuredProduct);
    const description = orderabilityConflicts.findIndex((conflict) => conflict.lineItemId === lineItemId) < 0
        ? productDescriptionYum(configuredProduct, type, menuModifiers)
        : '';
    const isLineup = lineupVariants.includes(variantCode !== null && variantCode !== void 0 ? variantCode : '') ? true : false;
    const transformName = isLineup && LineupNameOverridesMap[id] ? LineupNameOverridesMap[id] : productName;
    return Object.assign(Object.assign({ cartItemId: lineItemId, description,
        id,
        isLineup,
        modifiers, name: transformName, price: (_d = price.amount) !== null && _d !== void 0 ? _d : 0, quantity: lienItemQuantity }, (specialInstructions !== undefined && { specialInstructions })), { type,
        variantCode });
};
export default transformYumCartLineItemToCartItem;
