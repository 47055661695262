import { SizeKeys, } from '../types';
export const categoryFeaturedProducts = (category, config) => {
    // Get the featured product IDs for the category
    const featuredIds = config.featuredCategoryProducts[category.id].products;
    // Get the number of featured products to return in the output array
    const count = config.featuredCategoryProducts[category.id].count;
    // Get the in stock products for the category
    const inStockProducts = category.products.filter((product) => !product.outOfStock);
    // If the featured product ID array for the category is empty,
    // we just want to return the number of products in the config
    // This may be less that what is defined in `count` based on what is in stock at the store
    if (featuredIds.length === 0) {
        return inStockProducts.slice(0, count);
    }
    // Get the global featuredProduct IDs, we do not want duplicates showing up
    const { featuredProducts } = config;
    // Filter the featured category products by ID, that are in stock
    // Then filter out any that may dupilcate the MenuHub featured products
    // Then sort based on the order of the featured product IDs
    const featured = inStockProducts
        .filter((product) => featuredIds.includes(product.qoId))
        .filter((product) => !featuredProducts.includes(product.qoId))
        .sort((a, b) => featuredIds.indexOf(a.qoId) - featuredIds.indexOf(b.qoId));
    // At this point, the `featured` array:
    // 1. contains the featured products for the category (if available at the store), that are in stock
    // 2. does not duplicate the global featured products
    // 3. is sorted based on how featured products are defined in the MenuHubConfig
    // Also, at this point, the number of featured products may be less than what is defined in `count`
    // If we want to display the number of products defined in the MenuHubConfig, we need to pull in
    // additional products from the category. We use the `inStockProducts` as defined by the store menu
    // If there are not enough in stock products, then we display what is available
    const products = featured.length < count ? featured.concat(inStockProducts).slice(0, count) : featured.slice(0, count);
    return products;
};
export const menuHubFeaturedProducts = (categories, config) => {
    // Get the global featuredProduct IDs, we do not want duplicates showing up
    const { featuredProducts } = config;
    // Reduce all of the menu categories
    // filtering out products that are featured and in stock
    const initial = [];
    const featured = categories.reduce((acc, category) => {
        const filtered = category.products.filter((product) => featuredProducts.includes(product.qoId) && !product.outOfStock);
        return [...acc, ...filtered];
    }, initial);
    // Filter out any potential duplicates
    return [...new Set(featured)].sort((a, b) => featuredProducts.indexOf(a.qoId) - featuredProducts.indexOf(b.qoId));
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeNulls = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map((v) => (v && typeof v === 'object' ? removeNulls(v) : v)).filter((v) => !(v == null));
    }
    else {
        return Object.entries(obj)
            .map(([k, v]) => [k, v && typeof v === 'object' ? removeNulls(v) : v])
            .reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {});
    }
};
// returns true
export const filterItemByPrivateMetafield = (item, key = 'access-point-Web', hideValue = 'N', showValue = 'Y') => {
    const { privateMetafields } = item;
    let shouldShow = true;
    // find the private metafield with the given key in the item's private metafields
    const metafield = privateMetafields.find((m) => m.key === key);
    // if a metafield was found, check if the value is the hide value
    if (metafield && (metafield.value === hideValue || metafield.value !== showValue)) {
        shouldShow = false;
    }
    return shouldShow;
};
export const filterVariantsSlotsModifiersByAccessPointWeb = (allProducts, allSlots, allModifiers, skipFilter = false) => {
    if (skipFilter) {
        return { modifiers: allModifiers, products: allProducts, slots: allSlots };
    }
    // filter the variants on each product by private metafield,
    // then remove products that have no variants
    const products = allProducts.reduce((acc, product) => {
        const { variants: allVariants } = product;
        const variants = allVariants.filter((v) => filterItemByPrivateMetafield(v));
        // only include the product if after filtering the variants, its length is greater than 0
        if (variants.length > 0) {
            const newProduct = Object.assign(Object.assign({}, product), { variants });
            acc.push(newProduct);
        }
        return acc;
    }, []);
    // filter the slots by private metafield
    const slots = allSlots.filter((slot) => filterItemByPrivateMetafield(slot));
    // filter the modifier by private metafield
    const modifiers = allModifiers.filter((modifier) => {
        var _a;
        if (modifier.privateMetafields.length === 0)
            return true;
        return !(((_a = modifier.privateMetafields.find((metaField) => metaField.key === 'access-point-Web')) === null || _a === void 0 ? void 0 : _a.value) === 'N');
    });
    return {
        modifiers,
        products,
        slots,
    };
};
export const removeBundleOnlyVariants = (variants) => {
    const filteredVariants = variants.filter((v) => filterItemByPrivateMetafield(v, 'bundlesOnly', 'Y', 'N'));
    return filteredVariants;
};
// this filters out any variants that do not include the key we are looking for
export const checkKeyIsPresent = (item, key) => {
    const { privateMetafields } = item;
    const keyValues = privateMetafields.map((metafield) => metafield.key);
    return keyValues.includes(key);
};
export const filterDealLoversVariants = (variants, isLineup) => {
    // if isLineup is true, we only want variants that have the `dealLovers` private metafield
    if (isLineup) {
        const filteredVariants = variants
            .filter((v) => checkKeyIsPresent(v, 'dealLovers'))
            .filter((v) => filterItemByPrivateMetafield(v, 'dealLovers'))
            .filter((v) => v.privateMetafields.length !== 0);
        return filteredVariants;
    }
    else {
        // if isLineup is false, we only want variants that DO NOT have the 'dealLovers' private metafield
        const filteredVariants = variants.filter((v) => {
            const { privateMetafields = [] } = v;
            const hasDealLovers = privateMetafields.some((m) => m.key === 'dealLovers');
            return !hasDealLovers;
        });
        return filteredVariants;
    }
};
export const reduceModifiersForVariant = (variantCode, modifiers) => {
    // reduce the incoming modifiers array, separating out modifiers that don't have any exclusions and those that do
    // The modifiers that have exclusions, look at which weights are excluded and remove those from
    // the weights array on the modifier. If all weights are excluded, the remove the modifier all together
    const reducedModifiers = modifiers.reduce((acc, modifier) => {
        const { excludedVariants = {}, weights = [] } = modifier;
        // get the weights that are excluded for the variantCode coming in
        const excludedWeights = Object.keys(excludedVariants).filter((key) => excludedVariants[key].includes(variantCode));
        // remove the weights that are excluded from the variant
        const weightsToInclude = weights.filter((weight) => !excludedWeights.includes(weight));
        // if we have any weights to include, update the weights array on the modifier and add it to the accumulator
        if (weightsToInclude.length > 0) {
            acc.push(Object.assign(Object.assign({}, modifier), { weights: weightsToInclude }));
        }
        return acc;
    }, []);
    return reducedModifiers;
};
export const removeCheeseWeightsExcludedByVariant = (variantCode, builder) => {
    if (variantCode.length === 0) {
        return builder.cheeses;
    }
    // filter out toppings exluded by weight for the crust variant
    const clonedCheeses = JSON.parse(JSON.stringify(builder.cheeses));
    const cheeses = clonedCheeses.reduce((acc, cheese) => {
        var _a;
        const reducedCheeses = reduceModifiersForVariant(variantCode, (_a = cheese === null || cheese === void 0 ? void 0 : cheese.modifiers) !== null && _a !== void 0 ? _a : []);
        if (reducedCheeses.length > 0) {
            acc.push(Object.assign(Object.assign({}, cheese), { modifiers: reducedCheeses }));
        }
        return acc;
    }, []);
    return cheeses;
};
export const WingStreetMap = {
    'CLSS010WS|TOPP937BC': 'CLSS070A|TOPP954BC',
    'CLSS010WS|TOPP938RN': 'CLSS070A|TOPP952RN',
    'CLSS010WS|TOPP939M': 'CLSS070A|TOPP953M',
};
export const removeDipsExcludedByVariant = (variantCode, allDips, modifiers) => {
    const clonedDips = JSON.parse(JSON.stringify(allDips));
    const dipsToInclude = new Set();
    modifiers.forEach((modifier) => {
        var _a, _b;
        const variantIsExcluded = (_b = (_a = modifier.excludedVariants) === null || _a === void 0 ? void 0 : _a['NONE']) === null || _b === void 0 ? void 0 : _b.includes(variantCode);
        if (!variantIsExcluded) {
            dipsToInclude.add(WingStreetMap[modifier.id]);
        }
    });
    const dips = clonedDips.filter((dip) => {
        const [flavor] = dip.selectedOptions.filter((option) => { var _a; return !SizeKeys.has((_a = option.optionTypeCode) !== null && _a !== void 0 ? _a : ''); });
        const flavorId = flavor === null || flavor === void 0 ? void 0 : flavor.id;
        return dipsToInclude.has(flavorId);
    });
    return dips;
};
export const removeSauceWeightsExcludedByVariant = (variantCode, builder) => {
    if (variantCode.length === 0) {
        return builder.sauces;
    }
    // filter out sauces exluded by weight for the crust variant
    const clonedSauces = JSON.parse(JSON.stringify(builder.sauces));
    const sauces = clonedSauces.reduce((acc, sauce) => {
        var _a;
        const reducedSauces = reduceModifiersForVariant(variantCode, (_a = sauce === null || sauce === void 0 ? void 0 : sauce.modifiers) !== null && _a !== void 0 ? _a : []);
        if (reducedSauces.length > 0) {
            acc.push(Object.assign(Object.assign({}, sauce), { modifiers: reducedSauces }));
        }
        return acc;
    }, []);
    return sauces;
};
export const removeToppingWeightsExcludedByVariant = (variantCode, builder) => {
    var _a, _b;
    if (variantCode.length === 0) {
        return builder.toppings;
    }
    // filter out toppings exluded by weight for the crust variant
    const clonedToppings = JSON.parse(JSON.stringify(builder.toppings));
    const [clonedMeats, clonedVeggies] = clonedToppings;
    const reducedMeats = reduceModifiersForVariant(variantCode, (_a = clonedMeats === null || clonedMeats === void 0 ? void 0 : clonedMeats.modifiers) !== null && _a !== void 0 ? _a : []);
    const reducedVeggies = reduceModifiersForVariant(variantCode, (_b = clonedVeggies === null || clonedVeggies === void 0 ? void 0 : clonedVeggies.modifiers) !== null && _b !== void 0 ? _b : []);
    const meats = Object.assign(Object.assign({}, clonedMeats), { modifiers: reducedMeats });
    const veggies = Object.assign(Object.assign({}, clonedVeggies), { modifiers: reducedVeggies });
    return [meats, veggies];
};
