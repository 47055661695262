const keyTypes = {
    PHDCustomerAddress: [
        { key: 'address1', type: 'string' },
        { key: 'city', type: 'string' },
        { key: 'country', type: 'string' },
        { key: 'name', type: 'string' },
        { key: 'phone', type: 'string' },
        { key: 'postal_code', type: 'string' },
        { key: 'state', type: 'string' },
        { key: 'type', type: 'string' },
    ],
};
const keyTypesOptional = {
    PHDCustomerAddress: [
        { key: 'address2', type: 'string' },
        { key: 'customer_address_id', type: 'string' },
        { key: 'delivery_instructions', type: 'string' },
        { key: 'is_default', type: 'boolean' },
        { key: 'phone_extension', type: 'string' },
    ],
};
export const isType = (object, typeName) => {
    const requiredKeys = keyTypes[typeName];
    const optionalKeys = keyTypesOptional[typeName];
    const requiredKeysHaveExpectedTypes = requiredKeys.reduce((previous, current) => previous && typeof object[current.key] === current.type, true);
    if (!requiredKeysHaveExpectedTypes) {
        return false;
    }
    const optionalKeysHaveExpectedTypes = optionalKeys.reduce((previous, current) => {
        if (current.key in object) {
            return previous && typeof object[current.key] === current.type;
        }
        return true;
    }, true);
    return optionalKeysHaveExpectedTypes;
};
