const toSnake = (string) => string.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
const isArray = (array) => {
    return Array.isArray(array);
};
const isObject = (object) => {
    return object === Object(object) && !isArray(object) && typeof object !== 'function';
};
const camelToSnake = (object) => {
    if (isObject(object)) {
        const newObj = {};
        Object.keys(object).forEach((key) => {
            newObj[toSnake(key)] = camelToSnake(object[key]);
        });
        return newObj;
    }
    else if (isArray(object)) {
        return object.map((item) => {
            return camelToSnake(item);
        });
    }
    return object;
};
export default camelToSnake;
