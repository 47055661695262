import { Portion, SlotKeys } from '../types';
const CheeseMap = {
    [Portion.EXTRA]: 'CLSS001P|TOPP200XC',
    [Portion.LIGHT]: 'CLSS001P|TOPP547ZC',
    [Portion.NONE]: 'CLSS001P|TOPP206NC',
    [Portion.REGULAR]: 'WHOLE_REGULAR',
};
const SauceMap = {
    [Portion.EXTRA]: 'CLSS001P|TOPP702XS',
    [Portion.LIGHT]: 'CLSS001P|TOPP701EZ',
    [Portion.NONE]: 'NONE',
    [Portion.REGULAR]: 'WHOLE_REGULAR',
};
const transformPlacementPortionToWeightCode = (placement, portion, slot) => {
    let weight = 'NONE';
    if (slot === SlotKeys.pizzaCheese || slot === SlotKeys.meltCheese) {
        weight = CheeseMap[portion];
    }
    else if (slot === SlotKeys.pizzaToppings) {
        weight = `${placement}_${portion}`;
    }
    else if (slot === SlotKeys.pizzaSauce || slot === SlotKeys.meltSauce) {
        weight = SauceMap[portion];
    }
    return weight;
};
export default transformPlacementPortionToWeightCode;
