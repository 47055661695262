import snakeToCamel from '../../utils/snakeToCamel';
import { loyaltyApi } from './LoyaltyApi';
export const loyaltyApiAccount = loyaltyApi.injectEndpoints({
    endpoints: (build) => {
        return {
            getPoints: build.query({
                query: () => ({
                    method: 'get',
                    url: '/account/points',
                }),
                transformResponse: (response) => {
                    const data = snakeToCamel(response);
                    return data;
                },
            }),
            getRewards: build.query({
                query: () => ({
                    method: 'get',
                    url: '/account/rewards',
                }),
                transformResponse: (response) => {
                    const data = snakeToCamel(response);
                    return data;
                },
            }),
        };
    },
});
export const { useGetPointsQuery, useGetRewardsQuery } = loyaltyApiAccount;
