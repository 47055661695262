import { gql } from 'graphql-request';
const GET_CATEGORIES = gql `
    query getCategories($envID: String!, $storeID: String!, $occasions: [OccasionV3!]) {
        menu: Homepage(envID: $envID, storeID: $storeID, occasions: $occasions) {
            categories {
                displayOrder: index
                heading
                legalDisclaimer
                name
                link {
                    type
                    displayText
                    title
                    external
                    path
                }
                mainImage {
                    altText
                    desktop
                    mobile
                    title
                }
                displayName
                isNational
                products {
                    item {
                        id
                        exists
                        name
                    }
                }
                subHeading
            }
        }
    }
`;
export default GET_CATEGORIES;
