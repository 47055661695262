import { Placement, Portion } from '../types';
import transformPlacementPortionToQuantities from './transformPlacementPortionToQuantities';
export const transformPizzaBuilderStateToCartItem = (state) => {
    const { builder, quantity, selectedCheese, selectedCrust, selectedSauce, selectedSize, selectedToppings, specialInstructions, } = state;
    const sauce = selectedSauce
        ? {
            displayName: selectedSauce.name,
            name: selectedSauce.id,
            quantities: [1],
            type: 'MODIFIER',
        }
        : {};
    const cheese = selectedCheese
        ? {
            displayName: selectedCheese.name,
            name: selectedCheese.id,
            quantities: [1],
            type: 'MODIFIER',
        }
        : {};
    const size = selectedSize && selectedCrust
        ? {
            displayName: selectedSize.name,
            modifiers: [
                {
                    displayName: selectedCrust.name,
                    name: selectedCrust.id,
                    quantities: [1],
                    type: 'MODIFIER',
                },
            ],
            name: selectedSize.id,
            quantities: [1],
            type: 'SIZE',
        }
        : {};
    const toppings = selectedToppings
        ? selectedToppings.map((topping) => {
            // check splittable flags on the selected crust and the topping itself
            // if both are true, we can look at the topping placement to decide placement
            // otherwise, just assume the placement is WHOLE
            const { placement = Placement.WHOLE, portion = Portion.REGULAR } = topping;
            const quantities = (selectedCrust === null || selectedCrust === void 0 ? void 0 : selectedCrust.splittable) && topping.splittable
                ? transformPlacementPortionToQuantities(placement, portion)
                : [1, 1];
            return {
                displayName: topping.name,
                name: topping.id,
                quantities,
                type: 'MODIFIER',
            };
        })
        : {};
    const payload = {
        items: [
            {
                description: builder.description,
                displayName: builder.name,
                isLoyalty: false,
                modifiers: [sauce, cheese, size, toppings].flat(),
                name: builder.id,
                price: builder.price,
                quantity: quantity,
                specialInstructions,
                type: builder.type,
                verifiedAgeGate: true,
            },
        ],
    };
    return payload;
};
export const transformPizzaBuilderToCartItem = (state) => {
    const { order: { cartId = '' }, pizzaBuilder, } = state;
    const items = transformPizzaBuilderStateToCartItem(pizzaBuilder);
    const cartPayload = Object.assign(Object.assign({}, items), { cartId });
    return cartPayload;
};
export default transformPizzaBuilderToCartItem;
