var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Environments } from '../config';
import { baseHeaders } from './axiosBase';
import { axiosQuery } from './axiosQuery';
const axiosBaseQueryContentGateway = () => ({ url = '', method, data, params, headers = {} }, ReduxStore) => __awaiter(void 0, void 0, void 0, function* () {
    const state = ReduxStore.getState();
    const { coreConfig: { authChannel, axiosTimeout, debugMode, phdApiEnvironment, xServerEnv, clientId }, customer: { authToken, refreshToken, tokenType }, } = state;
    const baseUrl = Environments[phdApiEnvironment].baseUrlContentGateway;
    const apikey = Environments[phdApiEnvironment].contentGatewayKey;
    const defaultHeaders = Object.assign(Object.assign({}, baseHeaders), { ['x-server-env']: xServerEnv });
    if (authToken) {
        defaultHeaders['auth-token'] = authToken;
    }
    params = Object.assign(Object.assign({}, params), { apikey });
    const axiosConfig = {
        data,
        headers: Object.assign(Object.assign({}, defaultHeaders), headers),
        method,
        params,
        timeout: axiosTimeout,
        url: baseUrl + url,
        withCredentials: authChannel === 'WEB2',
    };
    if (debugMode) {
        console.log({
            data,
            headers: Object.assign(Object.assign({}, defaultHeaders), headers),
            method,
            params,
            url: baseUrl + url,
        });
    }
    const result = yield axiosQuery({
        axiosConfig,
        debugMode,
        api: ReduxStore,
        clientId,
        refreshToken,
        tokenType,
    });
    return result;
});
export default axiosBaseQueryContentGateway;
