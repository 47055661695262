var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AkamaiHeader, Environments } from '../config';
import { baseHeaders } from './axiosBase';
import { axiosQuery } from './axiosQuery';
const axiosBaseQueryV1 = () => ({ data, headers = {}, method, params, url, isYum = false }, ReduxStore) => __awaiter(void 0, void 0, void 0, function* () {
    const state = ReduxStore.getState();
    // get the auth and core config we need from Redux
    const { coreConfig: { authChannel, axiosTimeout, debugMode, phdApiEnvironment, xServerEnv, clientId }, customer: { authToken, refreshToken, tokenType }, } = state;
    // setup the base URLs and API keys
    const env = Environments[phdApiEnvironment];
    const baseUrl = isYum ? env.baseUrlCustomerGateway : env.baseUrlV1;
    const apiKey = isYum ? env.customerGatewayKey : env[authChannel].keyV1;
    const defaultHeaders = Object.assign(Object.assign({}, baseHeaders), { ['x-server-env']: xServerEnv });
    if (authChannel === 'PizzaHut') {
        defaultHeaders['akaatornr'] = AkamaiHeader[phdApiEnvironment];
    }
    if (authToken && tokenType === 'pizzahut') {
        defaultHeaders['auth-token'] = authToken;
    }
    if (authToken && tokenType === 'yum') {
        defaultHeaders['Authorization'] = `Bearer ${authToken}`;
    }
    if (isYum) {
        defaultHeaders['Api-Key'] = `${apiKey}`;
    }
    if (!isYum) {
        params = Object.assign(Object.assign({}, params), { key: apiKey });
    }
    const axiosConfig = Object.assign(Object.assign({}, (data !== undefined && data !== null && { data })), { headers: Object.assign(Object.assign({}, defaultHeaders), headers), method,
        params, timeout: axiosTimeout, url: baseUrl + url, withCredentials: authChannel === 'WEB2' });
    // TODO: solve the error thrown issue that occurs within the follow try/catch block.
    // some hooks (i.e. useCustomerSignInMutation) throw an unhandled promise rejection when feeding the api bad data
    // which stops workflow on the QA and mobile app. the temp fix was to simply change the name of the hook
    // which somehow fixed the issue through a mixture of magic, fairy dust, and blind luck, but this is probably not stable
    // and will likely be an issue in the future. i believe the issue to be coming either from axios rejections or rtk query itself
    // but have not been able to pinpoint the exact problem. validateStatus: () => true, can be passed into the axios call to ignore errors
    // and force it to pass, but this forces all failures to pass (not good), you can also use validateStatus: (status) => status === 400
    // but again, this is hacky. i left off investigating axios.interceptors.response which may lead somewhere,
    // but none of this explains why simply changing the name fixed the issue.
    if (debugMode) {
        console.log({
            data,
            headers: Object.assign(Object.assign({}, defaultHeaders), headers),
            method,
            params,
            url: baseUrl + url,
        });
    }
    const result = yield axiosQuery({
        axiosConfig,
        debugMode,
        api: ReduxStore,
        clientId,
        refreshToken,
        tokenType,
    });
    return result;
});
export default axiosBaseQueryV1;
