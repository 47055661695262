import { RuleType, } from './menuTypes';
export const EmptyDealBuilder = {
    availability: [],
    displayOrder: 0,
    id: '',
    name: '',
    options: [],
    outOfStock: false,
    qoId: '',
    selectedOptions: [],
    steps: [],
    type: 'DISCOUNT',
};
export const EmptyRules = {
    [RuleType.ADD]: [],
    [RuleType.DELETE]: [],
    [RuleType.INCLUDE]: [],
    [RuleType.PRICING]: [],
    [RuleType.RESTRICT]: [],
};
export const EmptyPizzaBuilder = {
    availability: [],
    cheeses: [],
    description: '',
    finishers: [],
    glutenFree: false,
    id: '',
    imageURL: '',
    internalName: '',
    isPanCrust: false,
    name: '',
    // nutrition: [],
    outOfStock: false,
    preferences: [],
    price: 0,
    rules: EmptyRules,
    sauces: [],
    sizesAndCrusts: [],
    toppings: [],
    type: 'PIZZA',
};
