import { Placement, Portion } from '../types';
import transformQuantities from './transformQuantities';
const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase());
};
const getModifierString = (modifierGroup) => {
    var _a, _b, _c;
    if (!((_a = modifierGroup.modifiers) === null || _a === void 0 ? void 0 : _a.length)) {
        return toTitleCase((_b = modifierGroup.displayName) !== null && _b !== void 0 ? _b : '');
    }
    return (_c = modifierGroup.modifiers) === null || _c === void 0 ? void 0 : _c.map(getModifierString).join(', ');
};
const productDescriptionPhd = (product) => {
    const { modifiers: modifierGroups = [] } = product;
    const descriptors = [];
    const wholeToppings = [];
    const leftToppings = [];
    const rightToppings = [];
    modifierGroups.forEach((group) => {
        var _a, _b;
        if (group.displayName === 'Meats' || group.displayName === 'Veggies') {
            (_a = group.modifiers) === null || _a === void 0 ? void 0 : _a.forEach((modifier) => {
                var _a;
                const { placement, portion } = transformQuantities((_a = modifier.quantities) !== null && _a !== void 0 ? _a : [1]);
                const toppingDescription = `${modifier.displayName}${portion === Portion.EXTRA ? ' (extra)' : ''}`;
                switch (placement) {
                    case Placement.LEFT:
                        leftToppings.push(toppingDescription);
                        break;
                    case Placement.RIGHT:
                        rightToppings.push(toppingDescription);
                        break;
                    default:
                        wholeToppings.push(toppingDescription);
                        break;
                }
            });
        }
        else {
            const baseDescription = group.displayName;
            const additionalDescription = ((_b = group.modifiers) === null || _b === void 0 ? void 0 : _b.length) === 1 ? getModifierString(group.modifiers[0]) : getModifierString(group);
            descriptors.push(group.type === 'SIZE' && baseDescription !== additionalDescription
                ? `${baseDescription}${additionalDescription.length ? ', ' : ''}${additionalDescription}`
                : additionalDescription !== null && additionalDescription !== void 0 ? additionalDescription : baseDescription);
        }
    });
    const description = `${descriptors.join(', ')}${leftToppings.length || rightToppings.length ? '\nWhole: ' : wholeToppings.length ? '\n' : ''}${wholeToppings.join(', ')}${leftToppings.length ? `\nLeft: ${leftToppings.join(', ')}` : ''}${rightToppings.length ? `\nRight: ${rightToppings.join(', ')}` : ''}`;
    return description;
};
export default productDescriptionPhd;
