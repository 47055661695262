import { gql } from 'graphql-request';
const ItemNutritionFragment = gql `
    fragment ItemNutritionFragment on Item {
        displayOrder: priority
        exists
        id
        name
        outOfStock
    }
`;
export default ItemNutritionFragment;
