import { orderSessionApi } from './OrderApi';
export const orderApiSession = orderSessionApi.injectEndpoints({
    endpoints: (build) => {
        return {
            clearSession: build.mutation({
                query: () => {
                    return {
                        method: 'delete',
                        url: '/session/clear',
                    };
                },
            }),
        };
    },
});
export const { useClearSessionMutation } = orderApiSession;
