import { Categories } from '../menu/menuTypes';
const defaultMenuHubConfig = {
    categorySortOrder: [
        Categories.DEALS,
        Categories.PIZZA,
        Categories.WINGS,
        Categories.DIPS,
        Categories.SIDES,
        Categories.PASTA,
        Categories.SANDWICHES,
        Categories.DESSERTS,
        Categories.SALADS,
        Categories.SOUPS,
        Categories.DRINKS,
        Categories.PZONE,
        Categories.LINEUP,
        Categories.MELTS,
        Categories.PARTYOFONE,
    ],
    featuredCategoryProducts: {
        deals: { count: 2, products: ['SCL1T', 'BIGDINNERBOX'] },
        desserts: { count: 2, products: [] },
        dips: { count: 2, products: [] },
        drinks: { count: 6, products: [] },
        lineup: { count: 0, products: [] },
        melts: { count: 0, products: [] },
        partyofone: { count: 0, products: [] },
        pasta: { count: 2, products: [] },
        pizza: { count: 5, products: ['10495~P^B', '11131~P^B', '11130~P^B', '10073~P^ML', '11133~P^S'] },
        pzone: { count: 2, products: [] },
        salads: { count: 2, products: [] },
        sandwiches: { count: 2, products: [] },
        sides: { count: 3, products: [] },
        soups: { count: 2, products: [] },
        wings: { count: 0, products: [] },
    },
    featuredProducts: ['MELTSALL'],
    rewardsTeasers: [
        {
            id: 'breadsticks',
            imageUrl: 'https://www.pizzahut.com/assets/w/tile/thor/sides_Breadsticks_Marinara.png',
            name: 'Breadsticks',
            pointValue: 75,
        },
        {
            id: 'eightWings',
            imageUrl: 'https://www.pizzahut.com/assets/w/tile/thor/m_dealStarburst3.png',
            name: '8 Boneless Wings',
            pointValue: 125,
        },
        {
            id: 'cinnabon',
            imageUrl: 'https://www.pizzahut.com/assets/w/tile/thor/Desserts_NEWCinnabonMiniRolls.png',
            name: 'Cinnabon',
            pointValue: 150,
        },
        {
            id: 'mediumPizza',
            imageUrl: 'https://www.pizzahut.com/assets/w/tile/thor/m_dealStarburst3.png',
            name: 'Medium 2-Topping Pizza',
            pointValue: 250,
        },
        {
            id: 'anyPizza',
            imageUrl: 'https://www.pizzahut.com/assets/w/tile/thor/STUFFCRUSTFORFREE_DealTile_Web_175x203.jpg',
            name: 'Large Any Pizza',
            pointValue: 300,
        },
    ],
    version: '1.0.0',
};
export default defaultMenuHubConfig;
