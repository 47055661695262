import { gql } from 'graphql-request';
import DiscountFragment from '../fragments/discount';
const GET_DEALS = gql `
    query getDeals($storeID: String!, $occasion: OrderMethod!) {
        store(storeID: $storeID) {
            menu {
                discounts: items(itemType: "DISCOUNT", occasion: $occasion) {
                    ...DiscountFragment
                }
                deals: items(itemType: "MASTER_DISCOUNT") {
                    ...DiscountFragment
                }
            }
        }
    }
    ${DiscountFragment}
`;
export default GET_DEALS;
