var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { customerApi } from './CustomerApi';
import { camelToSnake, snakeToCamel } from '../../utils';
import { CustomerActions } from '../../slices/CustomerSlice';
const { setFavorites } = CustomerActions;
/**
 * Defines the endpoints related to customer favorites. We use the
 * [injectEndpoints](https://redux-toolkit.js.org/rtk-query/usage/code-splitting) feature of RTK Query's `createApi`.
 */
export const customerApiFavorite = customerApi.injectEndpoints({
    endpoints: (build) => {
        return {
            addFavorite: build.mutation({
                invalidatesTags: ['Favorites'],
                query: (favorite) => {
                    const data = camelToSnake(favorite);
                    return {
                        data,
                        method: 'post',
                        url: `/favorites`,
                    };
                },
                transformResponse: (response) => {
                    const data = snakeToCamel(response);
                    return data;
                },
            }),
            deleteFavorite: build.mutation({
                invalidatesTags: ['Favorites'],
                query: (favoriteId) => ({
                    method: 'delete',
                    url: `/favorites/${favoriteId}`,
                }),
            }),
            editFavorite: build.mutation({
                invalidatesTags: ['Favorites'],
                query: (favorite) => {
                    const data = camelToSnake(favorite);
                    const { name } = data;
                    const { favoriteId } = favorite;
                    return {
                        data: { name },
                        method: 'put',
                        url: `/favorites/name/${favoriteId}`,
                    };
                },
            }),
            favorites: build.query({
                onQueryStarted: (_, { queryFulfilled, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setFavorites(data));
                    }
                    catch (err) {
                        console.log(err);
                    }
                }),
                providesTags: ['Favorites'],
                query: () => ({
                    method: 'get',
                    url: `/favorites`,
                }),
                transformResponse: (response) => {
                    const data = snakeToCamel(response);
                    return data;
                },
            }),
            makeFavoritePrimary: build.mutation({
                invalidatesTags: ['Favorites'],
                queryFn: (favoriteId, { getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    var _a, _b, _c;
                    const state = getState();
                    const favorites = (_b = (_a = state.customer.favorites) === null || _a === void 0 ? void 0 : _a.favorites) !== null && _b !== void 0 ? _b : [];
                    const primary = { favorite_id: favoriteId };
                    const others = ((_c = favorites === null || favorites === void 0 ? void 0 : favorites.filter((favorite) => favorite.favoriteId !== favoriteId)) === null || _c === void 0 ? void 0 : _c.map((favorite) => ({ favorite_id: favorite.favoriteId }))) || [];
                    const data = { rank: [primary, ...others] };
                    const response = yield fetchWithBQ({
                        data,
                        method: 'put',
                        url: '/favorites/rank',
                    });
                    return !response.error ? { data: undefined } : { error: response.error };
                }),
            }),
        };
    },
});
export const { useAddFavoriteMutation, useDeleteFavoriteMutation, useEditFavoriteMutation, useFavoritesQuery, useMakeFavoritePrimaryMutation, } = customerApiFavorite;
