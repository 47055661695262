import { Portion, PortionMap } from '../types';
const transformYumNutrition = (modifier, variantOptionValues) => {
    const { nutritionInformation = [] } = modifier;
    // get all of the particip
    const allVariantCodes = Array.from(variantOptionValues.keys());
    const variantNutritionInfo = new Map();
    const allNutrition = allVariantCodes.reduce((acc, variantCode) => {
        const nutritionInfo = nutritionInformation.filter((info) => { var _a; return (_a = info.variantCodes) === null || _a === void 0 ? void 0 : _a.includes(variantCode); });
        variantNutritionInfo.set(variantCode, nutritionInfo);
        const nutrition = nutritionInfo.reduce((nAcc, info) => {
            var _a, _b;
            const { name, units, weightCalories } = info;
            for (const [yumPortion, calories] of Object.entries(weightCalories !== null && weightCalories !== void 0 ? weightCalories : {})) {
                const optionCodes = (_a = variantOptionValues.get(variantCode)) !== null && _a !== void 0 ? _a : [];
                const qualifiers = [...optionCodes, variantCode];
                const portion = (_b = PortionMap[yumPortion]) !== null && _b !== void 0 ? _b : Portion.REGULAR;
                const nut = Object.assign(Object.assign({ calories }, (name !== undefined && { name })), { portion,
                    qualifiers, unit: units, units });
                nAcc.push(nut);
            }
            return nAcc;
        }, []);
        acc.push(nutrition);
        return acc;
    }, []);
    const nutrition = allNutrition.flat();
    return nutrition;
};
export default transformYumNutrition;
