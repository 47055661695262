var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { menuApi } from './MenuApi';
import GET_UPSELL from './queries/getUpsell';
import transformUpsell from '../../utils/transformUpsell';
import { menuApiContentGatewayHub } from './MenuApiContentGatewayHub';
import transformYumUpsellProducts from '../../utils/transformYumUpsellProducts';
export const menuApiUpsell = menuApi.injectEndpoints({
    endpoints: (build) => ({
        getUpsell: build.query({
            queryFn: (upsellOptions, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                const { coreConfig: { isYumEcomm: useContentGateway, isPreviewMode }, } = getState();
                if (useContentGateway) {
                    const { data, error } = yield dispatch(menuApiContentGatewayHub.endpoints.getMenu.initiate(upsellOptions.storeNumber));
                    if (error) {
                        return { error: error };
                    }
                    const upsells = data === null || data === void 0 ? void 0 : data.upsells;
                    const menuProducts = data === null || data === void 0 ? void 0 : data.products;
                    const upsellProducts = upsells === null || upsells === void 0 ? void 0 : upsells.reduce((acc, upsell) => {
                        const findProduct = menuProducts === null || menuProducts === void 0 ? void 0 : menuProducts.find((product) => product.productCode === upsell.yumProductCode);
                        if (findProduct) {
                            acc = [...acc, findProduct];
                        }
                        return acc;
                    }, []);
                    const transformedYumUpsellProducts = transformYumUpsellProducts(upsellProducts !== null && upsellProducts !== void 0 ? upsellProducts : [], data, isPreviewMode);
                    return { data: transformedYumUpsellProducts };
                }
                const { data: upsellResponse, error } = yield fetchWithBQ({
                    data: {
                        operationName: 'getUpsell',
                        query: GET_UPSELL,
                        variables: {
                            environmentId: upsellOptions.environmentId || 'prod',
                            storeID: upsellOptions.storeNumber,
                        },
                    },
                    method: 'post',
                });
                const transformedData = transformUpsell(upsellResponse);
                return upsellResponse ? { data: transformedData } : { error };
            }),
        }),
    }),
});
export const { useGetUpsellQuery } = menuApiUpsell;
