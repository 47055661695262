import { CheeseDescriptions, Placement, Portion, SlotKeys } from '../types';
import { isDip } from './menuUtilities';
import transformModifierWeights from './transformModifierWeights';
const isCheese = (slot) => {
    return slot === SlotKeys.meltCheese || slot === SlotKeys.pizzaCheese;
};
const isSauce = (slot) => {
    return slot === SlotKeys.meltSauce || slot === SlotKeys.pizzaSauce;
};
const isToppings = (slot) => {
    return slot === SlotKeys.pizzaToppings;
};
const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase());
};
const productDescriptionYum = (product, type, menuModifiers) => {
    if (!!!product) {
        return '';
    }
    const productIsADip = isDip(product.productCode);
    const { selectedModifiers = [], variant: { selectedOptionValues }, } = product;
    const descriptors = [];
    const wholeToppings = [];
    const leftToppings = [];
    const rightToppings = [];
    selectedOptionValues
        .sort((a) => {
        if (a.optionType.name.toLowerCase().includes('size')) {
            return -1;
        }
        return 1;
    })
        .forEach((selected) => descriptors.push(selected.optionValue.name));
    switch (type) {
        case 'MELT':
        case 'PIZZA': {
            const [cheeseSlot] = selectedModifiers.filter((modifier) => isCheese(modifier.slotCode));
            const [sauceSlot] = selectedModifiers.filter((modifier) => isSauce(modifier.slotCode));
            const [toppingsSlot] = selectedModifiers.filter((modifier) => isToppings(modifier.slotCode));
            const otherSlots = selectedModifiers.filter((modifier) => !isCheese(modifier.slotCode) && !isSauce(modifier.slotCode) && !isToppings(modifier.slotCode));
            cheeseSlot === null || cheeseSlot === void 0 ? void 0 : cheeseSlot.modifiers.forEach((cheese) => descriptors.push(CheeseDescriptions[cheese.weight.modifierWeightCode]));
            if (!sauceSlot) {
                descriptors.push('No Sauce');
            }
            else {
                sauceSlot.modifiers.forEach((sauce) => {
                    const { name, weight: { modifierWeightCode }, } = sauce;
                    const { portion } = transformModifierWeights(modifierWeightCode);
                    const sauceDescription = `${portion === Portion.REGULAR ? '' : `${toTitleCase(portion)} `}${name}`;
                    descriptors.push(sauceDescription);
                });
            }
            toppingsSlot === null || toppingsSlot === void 0 ? void 0 : toppingsSlot.modifiers.forEach((topping) => {
                const { name, weight: { modifierWeightCode }, } = topping;
                const { placement, portion } = transformModifierWeights(modifierWeightCode);
                const toppingDescription = `${name}${portion === Portion.EXTRA ? ' (extra)' : ''}`;
                switch (placement) {
                    case Placement.LEFT:
                        leftToppings.push(toppingDescription);
                        break;
                    case Placement.RIGHT:
                        rightToppings.push(toppingDescription);
                        break;
                    default:
                        wholeToppings.push(toppingDescription);
                        break;
                }
            });
            otherSlots.forEach((slot) => {
                slot.modifiers.forEach((modifier) => descriptors.push(modifier.name));
            });
            break;
        }
        default: {
            if (productIsADip) {
                selectedModifiers.forEach((slot) => {
                    slot.modifiers.forEach((modifier) => {
                        const getMenuModifier = menuModifiers === null || menuModifiers === void 0 ? void 0 : menuModifiers.find((mod) => mod.modifierCode === modifier.modifierCode);
                        if (getMenuModifier) {
                            descriptors.push(getMenuModifier.name);
                        }
                        else {
                            descriptors.push(modifier.name);
                        }
                    });
                });
            }
            else {
                selectedModifiers.forEach((slot) => {
                    slot.modifiers.forEach((modifier) => descriptors.push(modifier.name));
                });
            }
            break;
        }
    }
    const description = `${descriptors.join(', ')}${leftToppings.length || rightToppings.length ? '\nWhole: ' : wholeToppings.length ? '\n' : ''}${wholeToppings.join(', ')}${leftToppings.length ? `\nLeft: ${leftToppings.join(', ')}` : ''}${rightToppings.length ? `\nRight: ${rightToppings.join(', ')}` : ''}`;
    return description;
};
export default productDescriptionYum;
