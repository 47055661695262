import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    dealHistory: {},
    pizzaHistory: {},
    wingHistory: {},
};
export const orderSlice = createSlice({
    initialState,
    name: 'order',
    reducers: {
        clearCart: (state) => {
            const newState = Object.assign({}, state);
            delete newState.cart;
            delete newState.cartId;
            return newState;
        },
        removeDealFromDealHistory: (state, action) => {
            const prevState = Object.assign({}, state.dealHistory);
            const dealKeys = Object.keys(prevState);
            const newState = dealKeys.reduce((acc, key) => {
                if (key !== action.payload) {
                    acc[key] = prevState[key];
                }
                return acc;
            }, {});
            state.dealHistory = newState;
        },
        removePizzaFromPizzaHistory: (state, action) => {
            const prevState = Object.assign({}, state.pizzaHistory);
            const pizzaKeys = Object.keys(prevState);
            const newState = pizzaKeys.reduce((acc, key) => {
                if (key !== action.payload) {
                    acc[key] = prevState[key];
                }
                return acc;
            }, {});
            state.pizzaHistory = newState;
        },
        removeWingFromWingHistory: (state, action) => {
            const prevState = Object.assign({}, state.wingHistory);
            const wingKeys = Object.keys(prevState);
            const newState = wingKeys.reduce((acc, key) => {
                if (key !== action.payload) {
                    acc[key] = prevState[key];
                }
                return acc;
            }, {});
            state.wingHistory = newState;
        },
        setCart: (state, action) => {
            state.cart = action.payload;
        },
        setCartId: (state, action) => {
            state.cartId = action.payload;
        },
        setDealHistory: (state, action) => {
            state.dealHistory = Object.assign(Object.assign({}, state.dealHistory), { [action.payload.cartItemId]: action.payload.state });
        },
        setOrderId: (state, action) => {
            state.orderId = action.payload;
        },
        setPizzaHistory: (state, action) => {
            state.pizzaHistory = Object.assign(Object.assign({}, state.pizzaHistory), { [action.payload.cartItemId]: action.payload.state });
        },
        setPromotions: (state, action) => {
            state.promotionDefinitions = action.payload;
        },
        setStore: (state, action) => {
            state.store = action.payload;
        },
        setWingHistory: (state, action) => {
            state.wingHistory = Object.assign(Object.assign({}, state.wingHistory), { [action.payload.cartItemId]: action.payload.state });
        },
    },
});
const { clearCart, removeDealFromDealHistory, removePizzaFromPizzaHistory, removeWingFromWingHistory, setCart, setCartId, setDealHistory, setOrderId, setPizzaHistory, setPromotions, setStore, setWingHistory, } = orderSlice.actions;
export const OrderActions = {
    clearCart,
    removeDealFromDealHistory,
    removePizzaFromPizzaHistory,
    removeWingFromWingHistory,
    setCart,
    setCartId,
    setDealHistory,
    setOrderId,
    setPizzaHistory,
    setPromotions,
    setStore,
    setWingHistory,
};
export const OrderReducer = orderSlice.reducer;
