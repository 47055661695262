var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { menuApi } from './MenuApi';
import GET_PRODUCT from './queries/getProduct';
import { applyProductRules, applyProductRulesById } from '../../utils/applyProductRules';
import { menuApiContentGatewayHub } from './MenuApiContentGatewayHub';
import { generateProductsByIdListQuery } from './queries/generateProductsByIdQuery';
export const menuApiProduct = menuApi.injectEndpoints({
    endpoints: (build) => ({
        getProduct: build.query({
            queryFn: (options, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                var _a;
                const { coreConfig: { isYumEcomm: useContentGateway }, } = getState();
                const rules = (_a = options.rules) !== null && _a !== void 0 ? _a : [];
                if (useContentGateway) {
                    const response = yield dispatch(menuApiContentGatewayHub.endpoints.getContentGatewayMenuHub.initiate({
                        removeBundleVariants: true,
                        storeNumber: options.storeNumber,
                    }));
                    if (response.data) {
                        const { categories } = response.data;
                        let product;
                        categories.forEach(({ products }) => {
                            products.forEach((cgProduct) => {
                                if (cgProduct.id === options.itemId) {
                                    if (options.variantCode) {
                                        const variantCodes = cgProduct.options.flatMap((option) => {
                                            var _a;
                                            return (_a = option.modifiers) === null || _a === void 0 ? void 0 : _a.flatMap((modifier) => {
                                                var _a;
                                                if (modifier.variantCode) {
                                                    return modifier.variantCode;
                                                }
                                                return (_a = modifier.modifiers) === null || _a === void 0 ? void 0 : _a.flatMap((mod) => mod.variantCode);
                                            });
                                        });
                                        if (variantCodes.includes(options.variantCode)) {
                                            product = cgProduct;
                                        }
                                    }
                                    else {
                                        product = cgProduct;
                                    }
                                }
                            });
                        });
                        const error = options.variantCode
                            ? `Variant Code ${options.variantCode} not found for item ${options.itemId}`
                            : `Item ${options.itemId} not found.`;
                        return product ? { data: product } : { error };
                    }
                }
                const { data: productResponse, error } = yield fetchWithBQ({
                    data: {
                        operationName: 'getProduct',
                        query: GET_PRODUCT,
                        variables: {
                            itemID: `${options.itemId}`,
                            storeID: `stores/${options.storeNumber}`,
                        },
                    },
                    method: 'post',
                });
                const data = productResponse;
                const product = applyProductRules(data, rules);
                return data ? { data: product } : { error };
            }),
        }),
        getProductsById: build.query({
            queryFn: (options, { dispatch, getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                var _b;
                if (!options.ids.length) {
                    return { error: 'No item ids provided' };
                }
                const { coreConfig: { isYumEcomm: useContentGateway }, } = getState();
                const rules = (_b = options.rules) !== null && _b !== void 0 ? _b : [];
                const query = generateProductsByIdListQuery(options.ids);
                const storeNumber = options.storeNumber;
                if (useContentGateway) {
                    const response = yield dispatch(menuApiContentGatewayHub.endpoints.getContentGatewayMenuHub.initiate({
                        removeBundleVariants: true,
                        storeNumber: storeNumber,
                    }));
                    if (response.data) {
                        const { categories } = response.data;
                        const products = categories.reduce((acc, { products: prods }) => {
                            prods.forEach((cgProduct) => {
                                if (options.ids.includes(cgProduct.id)) {
                                    acc = [...acc, cgProduct];
                                }
                            });
                            return acc;
                        }, []);
                        return { data: products };
                    }
                }
                const { data: productResponse, error } = yield fetchWithBQ({
                    data: {
                        operationName: 'getProductsById',
                        query,
                        variables: {
                            storeID: `stores/${storeNumber}`,
                        },
                    },
                    method: 'post',
                });
                if (error)
                    return { error };
                const { data } = productResponse;
                const products = Object.values(data.store.menu);
                const product = products.map((prod) => applyProductRulesById(prod, rules));
                return data ? { data: product } : { error };
            }),
        }),
    }),
});
export const { useGetProductQuery, useGetProductsByIdQuery } = menuApiProduct;
