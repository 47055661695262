import { gql } from 'graphql-request';
import NutritionFragment from './nutrition';
const PizzaItemFragment = gql `
    fragment PizzaItemFragment on Item {
        allergenDisclaimer
        categoryPageImageURL
        description
        displayOrder: priority
        featuredProductImageURL
        glutenFree
        hidden
        id
        imageURL
        internalName
        isPanCrust
        maxAllowed
        menuHubImageURL
        name
        nutrition {
            ...NutritionFragment
        }
        outOfStock
        portion
        price
        required
        selected
        sodiumWarning
        splittable
        type
        universalMobileImageURL
    }
    ${NutritionFragment}
`;
export default PizzaItemFragment;
