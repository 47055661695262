import transformPlacementPortionToWeightCode from './transformPlacementPortionToWeightCode';
const transformSelectedModifiers = (modifiers) => {
    var _a;
    const modifierInputs = modifiers === null || modifiers === void 0 ? void 0 : modifiers.reduce((mAcc, modifier) => {
        switch (modifier.type) {
            case 'GROUP':
                const { modifiers: groupModifiers = [] } = modifier;
                groupModifiers.forEach((m) => mAcc.push(Object.assign(Object.assign({}, m), (modifier.slotCode && { slotCode: modifier.slotCode }))));
                break;
            default:
                mAcc.push(modifier);
                break;
        }
        return mAcc;
    }, []);
    return ((_a = modifierInputs === null || modifierInputs === void 0 ? void 0 : modifierInputs.filter((m) => m.type !== 'SIZE').map((selectedModifier) => {
        const { id: modifierCode, slotCode = '', placement, portion, weightCode } = selectedModifier;
        let modifierWeightCode = weightCode !== null && weightCode !== void 0 ? weightCode : '';
        if (!!placement && !!portion) {
            modifierWeightCode = transformPlacementPortionToWeightCode(placement, portion, slotCode);
        }
        return {
            modifierCode,
            modifierWeightCode,
            slotCode,
        };
    })) !== null && _a !== void 0 ? _a : []);
};
export default transformSelectedModifiers;
