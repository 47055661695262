var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/naming-convention */
import { storeApi } from './StoreApi';
import { camelToSnake, snakeToCamel } from '../../utils';
import { CustomerActions } from '../../slices/CustomerSlice';
const { setSearchAddress } = CustomerActions;
export const storeApiLocalization = storeApi.injectEndpoints({
    endpoints: (build) => {
        return {
            getJwks: build.query({
                query: () => ({
                    method: 'get',
                    url: `/localization/jwks`,
                }),
            }),
            refreshLocalizationToken: build.mutation({
                query: (token) => {
                    return {
                        data: token,
                        method: 'post',
                        url: `/localization/refresh`,
                    };
                },
            }),
            storeSearch: build.mutation({
                queryFn: (payload, { dispatch }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    const data = camelToSnake(payload);
                    const { skipSetSearchAddress } = payload;
                    if (!skipSetSearchAddress) {
                        dispatch(setSearchAddress(payload));
                    }
                    const response = yield fetchWithBQ({
                        data,
                        method: 'post',
                        url: `/stores/search`,
                    });
                    const phdResponse = response.data;
                    const stores = snakeToCamel(phdResponse);
                    return response.data
                        ? {
                            data: stores,
                        }
                        : {
                            error: response.error,
                        };
                }),
            }),
        };
    },
});
export const { useGetJwksQuery, useRefreshLocalizationTokenMutation, useStoreSearchMutation } = storeApiLocalization;
