export const computeDipsNutrition = (dips) => {
    return dips.map((dip) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        const dipOptions = (_a = dip.options) !== null && _a !== void 0 ? _a : [];
        const dipModifier = (_c = (_b = dipOptions[0]) === null || _b === void 0 ? void 0 : _b.modifiers) !== null && _c !== void 0 ? _c : [];
        const nutrition = (_e = (_d = dipModifier[0]) === null || _d === void 0 ? void 0 : _d.nutrition) !== null && _e !== void 0 ? _e : [];
        const price = (_g = (_f = dipModifier[0]) === null || _f === void 0 ? void 0 : _f.price) !== null && _g !== void 0 ? _g : 0;
        const calories = (_j = (_h = nutrition[0]) === null || _h === void 0 ? void 0 : _h.calories) !== null && _j !== void 0 ? _j : 0;
        const name = (_l = (_k = nutrition[0]) === null || _k === void 0 ? void 0 : _k.name) !== null && _l !== void 0 ? _l : '';
        const unit = (_o = (_m = nutrition[0]) === null || _m === void 0 ? void 0 : _m.unit) !== null && _o !== void 0 ? _o : '';
        return {
            id: dip.id,
            imageUrl: (_p = dip.imageURL) !== null && _p !== void 0 ? _p : '',
            name,
            nutrition: `${calories} cal/${unit}`,
            price,
        };
    });
};
export const computeWingNutrition = (sauce) => {
    var _a;
    if (!((_a = sauce.nutrition) === null || _a === void 0 ? void 0 : _a[0])) {
        return '';
    }
    else {
        const nutrition = sauce.nutrition[0];
        const calories = nutrition.calories || 0;
        const servings = nutrition.servings;
        const unit = nutrition.unit || 'Wing';
        const units = nutrition.units || 'Wings';
        return `${calories} cal/${unit}, ${servings} ${units}`;
    }
};
export const transformNutritionToString = (nutrition) => {
    const { calories, unit, servings, units } = nutrition;
    return `${calories} cal/${unit}, ${servings} ${units}`;
};
