var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { customerApi } from './CustomerApi';
import snakeToCamel from '../../utils/snakeToCamel';
import { removeNulls } from '../../utils';
/**
 * Defines the endpoints related to customer order history. We use the
 * [injectEndpoints](https://redux-toolkit.js.org/rtk-query/usage/code-splitting) feature of RTK Query's `createApi`.
 */
const customerApiOrder = customerApi.injectEndpoints({
    endpoints: (build) => {
        return {
            getLoyaltyOrder: build.query({
                query: (orderId) => ({
                    method: 'get',
                    url: `/customer/loyalty/order/${orderId}`,
                }),
                transformResponse: (response) => {
                    const data = snakeToCamel(response);
                    return data;
                },
            }),
            loyaltyOrders: build.query({
                query: () => ({
                    method: 'get',
                    url: `/customer/loyalty/order`,
                }),
                transformResponse: (response) => {
                    const data = snakeToCamel(response);
                    return data;
                },
            }),
            orders: build.query({
                queryFn: (limit, { getState }, _extraOptions, fetchWithBQ) => __awaiter(void 0, void 0, void 0, function* () {
                    const { coreConfig: { isYumEcomm }, } = getState();
                    const { data: response, error } = yield fetchWithBQ(Object.assign(Object.assign({ data: isYumEcomm ? {} : null, isYum: isYumEcomm, method: 'get' }, (!isYumEcomm && { params: { limit: limit || 10 } })), { url: '/orders' }));
                    if (error) {
                        return { error };
                    }
                    const data = removeNulls(snakeToCamel(response));
                    return { data };
                }),
            }),
        };
    },
});
export const { useGetLoyaltyOrderQuery, useLoyaltyOrdersQuery, useOrdersQuery } = customerApiOrder;
export { customerApiOrder };
