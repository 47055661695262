import reduceProductSelectedOptions from './reduceProductSelectedOptions';
export const transformProductToCartItemInput = (product, isYumEcomm = false) => {
    const { id, name, quantity = 1, selectedOptions, specialInstructions = '', type } = product;
    const { modifiers, variantCode } = reduceProductSelectedOptions(selectedOptions, isYumEcomm);
    return {
        id,
        modifiers,
        name,
        quantity,
        specialInstructions,
        type,
        variantCode,
    };
};
export default transformProductToCartItemInput;
