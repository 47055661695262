import { CategoryMap } from '../types';
import { sortByDisplayOrder } from './sortUtilities';
export const transformYumCategories = (categories) => {
    const transformedCategories = categories.map((category) => {
        var _a;
        const { categoryCode, displayName, index, metafields, name, privateMetafields } = category;
        return {
            displayOrder: index !== null && index !== void 0 ? index : Number.MAX_SAFE_INTEGER,
            featuredProducts: [],
            id: (_a = CategoryMap[categoryCode]) !== null && _a !== void 0 ? _a : categoryCode,
            metafields,
            name: displayName !== null && displayName !== void 0 ? displayName : name,
            privateMetafields,
            products: [],
        };
    });
    const sortedCategories = transformedCategories.sort(sortByDisplayOrder);
    return sortedCategories;
};
export default transformYumCategories;
