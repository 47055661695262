import { gql } from 'graphql-request';
const NutritionFragment = gql `
    fragment NutritionFragment on Nutrition {
        additionalNutrition {
            calories
            name
            portion
            servings
            unit
            units
        }
        calories
        name
        portion
        qualifiers
        servings
        unit
        units
    }
`;
export default NutritionFragment;
