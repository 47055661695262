import { SlotKeys } from '../types';
export const transformYumWingsProductToWingBuilderState = (dealRecipe, dipsCategory) => {
    const dealDips = dealRecipe.options.find((option) => option.id === SlotKeys.wingDip);
    // get the yum dip modifiers and match any recipe modifiers that match the yum modifier id
    // if no modifiers match, we don't include the dip as an option
    // in the future, the nested dip structure will be re-authored into a dip product for each dip option
    // so this will cease to work when that happens and will need to be refactored
    const dips = dipsCategory.reduce((dipAcc, yumDip, i) => {
        var _a;
        const dipOption = dipsCategory[i].options.filter((opt) => opt.id === SlotKeys.extraDip)[0];
        const dipModifiers = (_a = dipOption === null || dipOption === void 0 ? void 0 : dipOption.modifiers) === null || _a === void 0 ? void 0 : _a.reduce((acc, mod) => {
            var _a;
            (_a = dealDips === null || dealDips === void 0 ? void 0 : dealDips.modifiers) === null || _a === void 0 ? void 0 : _a.forEach((dDip) => {
                if (dDip.id === mod.id) {
                    acc.push(mod);
                }
            });
            return acc;
        }, []);
        if (dipModifiers)
            dipAcc.push(Object.assign(Object.assign({}, yumDip), { options: [Object.assign(Object.assign({}, dipOption), { modifiers: dipModifiers })] }));
        return dipAcc;
    }, []);
    const wingOptions = dealRecipe.options.filter((option) => option.id === 'wing_size') || [];
    const wings = [Object.assign(Object.assign({}, dealRecipe), { options: wingOptions })];
    return {
        dips,
        wings,
    };
};
