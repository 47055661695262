import { Placement, Portion, RuleType } from '../types';
import { removeNulls } from './filterUtilities';
import { sortByOutOfStock } from './sortUtilities';
import { parseGqlId } from './stringUtilities';
export const transformPizzaBuilder = (response) => {
    var _a;
    // remove any null values
    const responseWithoutNulls = removeNulls(response);
    // clone item from response
    const initialPizzaBuilder = Object.assign(Object.assign({}, responseWithoutNulls.data.menu.pizza.item), { cheeses: [], finishers: [], preferences: [], sauces: [], toppings: [] });
    const maxAllowed = responseWithoutNulls.data.menu.pizza.item.maxAllowed;
    // restructure response data to match Pizza Builder contract
    const pizzaBuilder = (_a = responseWithoutNulls.data.menu.pizza.item.modifiers) === null || _a === void 0 ? void 0 : _a.reduce((acc, modifier) => {
        var _a, _b, _c, _d, _e, _f;
        const modifierName = modifier.name.toLowerCase();
        const initialRules = {
            [RuleType.ADD]: [],
            [RuleType.DELETE]: [],
            [RuleType.INCLUDE]: [],
            [RuleType.PRICING]: [],
            [RuleType.RESTRICT]: [],
        };
        if (modifierName === 'sizes') {
            // digital magic to move rules into one object
            const rules = (_a = modifier.modifiers) === null || _a === void 0 ? void 0 : _a.reduce((rulesAcc, size) => {
                const crusts = size.modifiers || [];
                crusts.forEach((crust) => {
                    var _a;
                    (_a = crust.rules) === null || _a === void 0 ? void 0 : _a.forEach((rule) => {
                        const obj = {
                            action: rule.action,
                            actionItem: rule.actionItem,
                            crustId: parseGqlId(crust.id),
                            sizeId: parseGqlId(size.id),
                        };
                        rulesAcc[rule.action] = [...rulesAcc[rule.action], obj];
                    });
                });
                return rulesAcc;
            }, initialRules);
            // sort and set the subtype for sizes and crusts
            const sizesAndCrusts = (_b = modifier.modifiers) === null || _b === void 0 ? void 0 : _b.reduce((sizeAcc, size) => {
                size.subtype = 'sizes';
                size.modifiers = sortByOutOfStock(size.modifiers || []).map((c) => {
                    const { nutrition = [] } = c;
                    const [crustNutrition] = nutrition;
                    const { servings = '', unit = '' } = Object.assign({}, crustNutrition);
                    let sliceCount = '';
                    if (servings.length && unit.length) {
                        sliceCount = `${servings} ${unit}s`;
                        c.sliceCount = sliceCount;
                    }
                    c.subtype = 'crusts';
                    c.maxAllowed = maxAllowed;
                    return c;
                });
                sizeAcc.push(size);
                return sizeAcc;
            }, []);
            acc.rules = rules;
            acc.sizesAndCrusts = sizesAndCrusts;
        }
        else if (modifierName === 'meats' || modifierName === 'veggies') {
            const toppings = (_c = modifier.modifiers) === null || _c === void 0 ? void 0 : _c.reduce((toppingsAcc, topping) => {
                topping.placement = Placement.WHOLE;
                topping.portion = Portion.REGULAR;
                topping.subtype = modifierName;
                toppingsAcc.push(topping);
                return toppingsAcc;
            }, []);
            modifier.modifiers = sortByOutOfStock(toppings || []);
            (_d = acc.toppings) === null || _d === void 0 ? void 0 : _d.push(modifier);
        }
        else if (modifierName === 'cheeses') {
            const cheeses = (_e = modifier.modifiers) === null || _e === void 0 ? void 0 : _e.reduce((cheeseAcc, cheese) => {
                var _a;
                const cheesesWithSubtype = (_a = cheese.modifiers) === null || _a === void 0 ? void 0 : _a.map((cheeze) => {
                    cheeze.subtype = 'cheeses';
                    return cheeze;
                });
                cheese.modifiers = sortByOutOfStock(cheesesWithSubtype || []);
                cheeseAcc.push(cheese);
                return cheeseAcc;
            }, []);
            acc.cheeses = cheeses;
        }
        else if (modifierName === 'sauces') {
            const sauces = (_f = modifier.modifiers) === null || _f === void 0 ? void 0 : _f.reduce((sauceAcc, sauce) => {
                var _a;
                const saucesWithSubtype = (_a = sauce.modifiers) === null || _a === void 0 ? void 0 : _a.map((s) => {
                    s.subtype = 'sauces';
                    return s;
                });
                sauce.modifiers = sortByOutOfStock(saucesWithSubtype || []);
                sauceAcc.push(sauce);
                return sauceAcc;
            }, []);
            acc.sauces = sauces;
        }
        else {
            acc.finishers = sortByOutOfStock(modifier.modifiers || []);
        }
        return acc;
    }, initialPizzaBuilder);
    // remove modifiers from pizzaBuilder data
    pizzaBuilder === null || pizzaBuilder === void 0 ? true : delete pizzaBuilder.modifiers;
    if (pizzaBuilder)
        pizzaBuilder.glutenFree = response.data.menu.pizza.item.glutenFree;
    const typedPizza = pizzaBuilder;
    return typedPizza;
};
export default transformPizzaBuilder;
