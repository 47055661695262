var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CustomerActions } from '../../slices/CustomerSlice';
import snakeToCamel from '../../utils/snakeToCamel';
import { yumApi } from './YumApi';
const { setIsGuest, setAuthToken } = CustomerActions;
export const yumApiAuth = yumApi.injectEndpoints({
    endpoints: (build) => {
        return {
            yumGuestSignIn: build.mutation({
                onQueryStarted: (_, { queryFulfilled, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const { data } = yield queryFulfilled;
                        dispatch(setAuthToken({
                            authToken: data.accessToken,
                            expiresIn: data.expiresIn,
                            isGuest: data.isGuest,
                            refreshToken: data.refreshToken,
                            tokenType: 'yum',
                        }));
                        dispatch(setIsGuest(true));
                    }
                    catch (err) {
                        console.log(err);
                    }
                }),
                query: (client_id = 'ph_us_web') => ({
                    data: { client_id, grant_type: 'guest' },
                    method: 'post',
                    url: '',
                }),
                transformResponse: (response) => {
                    const data = snakeToCamel(response);
                    return data;
                },
            }),
            yumRefreshToken: build.mutation({
                onQueryStarted: (_, { queryFulfilled, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
                    const { data } = yield queryFulfilled;
                    dispatch(setAuthToken({
                        authToken: data.accessToken,
                        expiresIn: data.expiresIn,
                        isGuest: data.isGuest,
                        refreshToken: data.refreshToken,
                        tokenType: 'yum',
                    }));
                }),
                query: (args) => ({
                    data: { client_id: args.clientId, grant_type: 'refresh_token', refresh_token: args.refreshToken },
                    method: 'post',
                    url: '',
                }),
                transformResponse: (response) => {
                    const data = snakeToCamel(response);
                    return data;
                },
            }),
        };
    },
});
export const { useYumGuestSignInMutation, useYumRefreshTokenMutation } = yumApiAuth;
