import { gql } from 'graphql-request';
const ModifierItemFragment = gql `
    fragment ModifierItemFragment on Item {
        categoryPageImageURL
        description
        displayOrder: priority
        featuredProductImageURL
        id
        imageURL
        internalName
        maxAllowed
        menuHubImageURL
        name
        outOfStock
        price
        required
        selected
        sodiumWarning
        type
        universalMobileImageURL
    }
`;
export default ModifierItemFragment;
